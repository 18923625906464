import React, { useEffect } from "react";
import Page from "../../components/core/Page/Page";
import { MODAL_TYPES, useModals } from "../../components/context/modalContext";
import { useSearchParams } from "react-router-dom";
import { confirmSubscription } from "../../components/services/api.service";
import { toast } from "react-toastify";

export default function CheckoutSuccessPayment() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { showModal } = useModals();
  const [loading, setLoading] = React.useState(true);

  const paymentIntent = searchParams.get("payment_intent");
  const paymentIntentSecret = searchParams.get("client_secret");

  useEffect(() => {
    if (!paymentIntent) {
      return;
    }
    if (!loading) {
      return;
    }
    // if (paymentIntent && showModal) {
    //   setTimeout(() => {
    //     confirmSubscription({
    //       setupIntentId: paymentIntent,
    //     })
    //       .then((res) => {
    //

    //         setLoading(false);
    //       })
    //       .catch((err) => {
    //
    //         toast.error('Something went wrong');
    //         setLoading(false);
    //         setTimeout(() => {
    //           window.location.href = '/';
    //         }, 2000);
    //       });
    //   }, 5000);
    // }
  }, [paymentIntent, showModal, loading]);

  const continueClicked = () => {};
  return (
    <Page>
      {/* <div className="py-5 text-center">
        <div className="py-5">
          <svg
            width="60"
            height="60"
            viewBox="0 0 38 38"
            xmlns="http://www.w3.org/2000/svg"
            stroke="#333"
          >
            <g fill="none" fill-rule="evenodd">
              <g transform="translate(1 1)" stroke-width="2">
                <circle stroke-opacity=".5" cx="18" cy="18" r="18" />
                <path d="M36 18c0-9.94-8.06-18-18-18">
                  <animateTransform
                    attributeName="transform"
                    type="rotate"
                    from="0 18 18"
                    to="360 18 18"
                    dur="1s"
                    repeatCount="indefinite"
                  />
                </path>
              </g>
            </g>
          </svg>
        </div>
        <h3>Creating your subscription...</h3>
      </div> */}
      <div
        className="text-center"
        style={{ margin: "auto auto", width: "80%" }}
      >
        <h3>Your subscription update is complete</h3>
        <div className="py-5">
          <img src="/images/dogs-in-heart.png" alt="Dogs" />
          <img src="/images/thank-you-note.png" className="mt-4" alt="" />
          {/* <p className="fs-3 mt-3">
            Your subscription code is : {subscriptionId}{' '}
          </p> */}
        </div>
        <div className="px-4">
          <div className="border-top mb-5"></div>
        </div>
        {/* <button className='w-100 btn btn-primary py-3 fs-5' onClick={continueClicked}>
          Continue
        </button> */}
      </div>
    </Page>
  );
}
