import React from "react";
import { Logo } from "../../components/Assets";
import { IoMdPrint } from "react-icons/io";
import { MdOutlineFileDownload } from "react-icons/md";
import invoiceData from "./invoiceData";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
const Invoice = () => {
  const handlePrintClick = () => {
    window.print();
  };

  return (
    <div className="max-w-[85rem] px-4 sm:px-6 lg:px-8 mx-auto my-4 sm:my-10">
      <div className="sm:w-11/12 lg:w-3/4 mx-auto">
        <div className="flex flex-col p-4 sm:p-10 bg-white shadow-md rounded-xl dark:bg-neutral-800">
          <div className="flex justify-between">
            <div>
              <Logo />
            </div>

            <div className="text-end">
              <h2 className="text-2xl md:text-3xl font-semibold text-black ">
                {invoiceData.invoiceNumber}
              </h2>
              <span className="mt-1 block text-gray-900 ">
                {invoiceData.invoiceNumberValue}
              </span>

              <address className="mt-4 not-italic text-[#448EBE] text-sm ">
                {invoiceData.address.street}
                <br />
                {invoiceData.address.city}, {invoiceData.address.state}{" "}
                {invoiceData.address.zip}
                <br />
                Other Locations:
                <br />
                {invoiceData.address.otherLocations.map((location) => (
                  <span key={location}>
                    {location}
                    <br />
                  </span>
                ))}
              </address>
            </div>
          </div>

          <div className="mt-8 grid sm:grid-cols-2 gap-3">
            <div>
              <h3 className="text-md font-semibold text-gray-800 ">
                Bill to:{" "}
                <span className="text-md font-semibold text-gray-800 ">
                  {invoiceData.billTo.name}
                </span>
              </h3>

              <address className="mt-2 not-italic text-gray-900 text-xs ">
                {invoiceData.billTo.street},
                <br />
                {invoiceData.billTo.city}, {invoiceData.billTo.state}{" "}
                {invoiceData.billTo.zip},
                <br />
                {invoiceData.billTo.country}
              </address>
            </div>

            <div className="sm:text-end space-y-2">
              <div className="grid grid-cols-2 sm:grid-cols-1 gap-3 sm:gap-2">
                <dl className="grid sm:grid-cols-5 gap-x-3">
                  <dt className="col-span-3 font-semibold text-[#448EBE] ">
                    Invoice date:
                  </dt>
                  <dd className="col-span-2 text-gray-900 ">
                    {invoiceData.invoiceDate}
                  </dd>
                </dl>
                <dl className="grid sm:grid-cols-5 gap-x-3">
                  <dt className="col-span-3 font-semibold text-[#448EBE] ">
                    Due date:
                  </dt>
                  <dd className="col-span-2 text-gray-900 ">
                    {invoiceData.dueDate}
                  </dd>
                </dl>
              </div>
            </div>
          </div>

          <div className="mt-6">
            <div className="border border-gray-200 p-4 rounded-lg space-y-4 ">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-amber-600/20 ">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Description
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Quantity
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Price
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Total
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-20">
                  {invoiceData.items.map((item, index) => (
                    <tr key={index}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {item.description}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {item.quantity}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {item.price}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {item.total}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className="mt-8 sm:mt-12">
            <h4 className="text-lg font-semibold text-[#448EBE] ">
              {invoiceData.thankYouMessage}
            </h4>
            <p className="text-gray-900 ">{invoiceData.questionsMessage}</p>
            <div className="mt-2">
              <p className="block text-sm font-medium text-[#448EBE] ">
                {invoiceData.contactInfo.email}
              </p>
            </div>
          </div>

          <p className="mt-5 text-sm text-gray-900 ">{invoiceData.copyright}</p>
        </div>

        <div className="mt-6 flex justify-end gap-x-3 print:hidden">
          <button
            className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-amber-600 text-white hover:bg-amber-700 disabled:opacity-50 disabled:pointer-events-none"
            onClick={handlePrintClick}
          >
            <IoMdPrint className="text-2xl" />
            Print
          </button>
        </div>
      </div>
    </div>
  );
};

export default Invoice;
