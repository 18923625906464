import React from "react";
import twodogs from "../../utils/twodogs2.png";
import threedogs from "../../utils/3dogs.png";
// import "./twoplans.css";
const TwoplansSmallMed = () => {
  return (
    <div
      className="w-full p-1 justify-center items-center block md:block lg:hidden"
      style={{ userSelect: "none", pointerEvents: "none" }}
    >
      <img src={twodogs} alt="" className="mx-auto" />
      {/* <div className="flex flex-col w-full">
        <h2 className="text-[#ff9a26] text-3xl mb-2 md:text-4xl lg:text-5xl font-bold hidden lg:block md:block">
          Getting started is <br /> simple
        </h2>
        <div className="flex w-full flex-col md:flex-row lg:flex-row ">
          {" "}
          <h2 className="text-[#ff9a26] text-3xl md:text-4xl lg:text-5xl font-bold lg:hidden md:hidden">
            Getting started is simple
          </h2>
          <div className=" pt- relative z-10 mr-5  flex  responsive-width-div text-[1.2rem] md:text-[1.5rem] lg:text-[1.6rem] text-black flex-col mt-2">
            Select a plan from below, enter your pup's details, and you're all
            set.{" "}
            <p className="text-[1.2rem] md:text-[1.5rem] lg:text-[1.6rem] text-black mt-2">
              Take the first step toward helping your dog live a longer,
              healthier life.
            </p>
          </div>
          <img
            src={twodogs}
            alt="twodogs"
            className="responsive-div absolute max-w-full ml-5 w-96 top-5  right-10 md:right-10 lg:right-20 hidden md:block lg:block "
          />
        </div>
      </div>
      <div className="h-1 w-full bg-[#ffa33a]"></div>
      <div className="w-full  flex flex-col">
        <h2 className="text-[#448EBE] text-3xl md:text-4xl lg:text-5xl font-bold mt-3">
          Adding more dogs? <br /> Easy!
        </h2>

        <div className="relative z-10 mb-5 flex text-[1rem] md:text-[1.3rem] lg:text-[1.6rem] text-black flex-col mt-2">
          <img src={threedogs} alt="twodogs" className="w-full  -top-14" />
          <p className="text-[1rem] md:text-[1.3rem] lg:text-[1.6rem] text-black mt-2 top-52 ">
            No repeat tech costs means more savings for you - we guarantee we'll
            always fetch the lowest price possible for your pack.
          </p>
        </div>
      </div> */}
    </div>
  );
};

export default TwoplansSmallMed;
