const invoiceData = {
  invoiceNumber: "Invoice #",
  invoiceNumberValue: "3682303",
  address: {
    street: "1 Chisholm Trail Suite 450",
    city: "Round Rock",
    state: "Texas",
    zip: "78681",
    otherLocations: [
      "Office - Houston, TX",
      "Kitchen - College Station, TX",
      "Rescue Ranch - Caldwell, TX",
    ],
  },
  billTo: {
    name: "Sara Williams",
    street: "280 Suzanne Throughway",
    city: "Breannabury",
    state: "OR",
    zip: "45801",
    country: "United States",
  },
  invoiceDate: "03/10/2018",
  dueDate: "03/11/2018",
  contactInfo: {
    email: "customersupport@carsonandbear.com",
    phone: "+1 (062) 109-9222",
  },
  copyright: "©2024 Carson&Bear™ Dog Wellness Program",
  thankYouMessage: "Thank you!",
  questionsMessage:
    "If you have any questions concerning this invoice, use the following contact information:",
  items: [
    {
      description: "Item 1",
      quantity: 2,
      price: "$10.00",
      total: "$20.00",
    },
    {
      description: "Item 2",
      quantity: 1,
      price: "$15.00",
      total: "$15.00",
    },
  ],
};

export default invoiceData;
