import axios from "./axios";

export const loginApi = (body) => {
  return axios.post("/users/login", body).then((response) => {
    return response.data;
  });
};

export const signupApi = (body) => {
  return axios.post("/users/signup", body).then((response) => {
    return response.data;
  });
};
export const signupApiNew = async (body) => {
  return axios.post("/users/signup", body).then((response) => {
    return response.data;
  });
};

export const forgotPasswordApi = async (body) => {
  return axios.post("/users/forget-password", body).then((response) => {
    return response.data;
  });
};

export const resetPasswordApi = async (body) => {
  return axios.post("/users/reset-password", body).then((response) => {
    return response.data;
  });
};

export const getAccountApi = async () => {
  return axios.get("/users/account").then((response) => {
    return response.data;
  });
};

export const updateAccount = async (body) => {
  return axios.put("/users/account", body).then((response) => {
    return response.data;
  });
};

export const getSubscription = async () => {
  return axios.get("/users/subscription").then((response) => {
    return response.data;
  });
};

export const initSubscription = async () => {
  return axios.post("/users/subscription/init").then((response) => {
    return response.data;
  });
};

export const confirmSubscription = async (body) => {
  return axios.post("/users/subscription/confirm-v2", body).then((response) => {
    return response.data;
  });
};
export const updateSubscription = async (body) => {
  return axios
    .post("/users/subscription/update-subscription")
    .then((response) => {
      return response.data;
    });
};

export const cancelSubscription = async (pet) => {
  return axios.put("/users/subscription/cancel/" + pet).then((response) => {
    return response.data;
  });
};
export const verifyAddress = async (body) => {
  return axios
    .get("/users/addresses/validation/verification", body)
    .then(({ data: response }) => {
      return response.data;
    });
};
export const createAddress = async (body) => {
  return axios.post("/users/addresses", body).then(({ data: response }) => {
    return response.data;
  });
};

export const getAddresses = async (body) => {
  return axios.get("/users/addresses", body).then(({ data: response }) => {
    return response.data;
  });
};

export const getAddressesById = async (id) => {
  return axios.get("/users/addresses/" + id).then(({ data: response }) => {
    return response.data;
  });
};

export const updateAddress = async (id, body) => {
  return axios
    .put("/users/addresses/" + id, body)
    .then(({ data: response }) => {
      return response.data;
    });
};

export const deleteAddress = async (id) => {
  return axios.delete("/users/addresses/" + id).then(({ data: response }) => {
    return response.data;
  });
};

export const createPet = async (body) => {
  return axios.post("/pet-profiles", body).then(({ data: response }) => {
    return response.data;
  });
};

export const getPets = async (body) => {
  return axios
    .get("/pet-profiles/user-pets", body)
    .then(({ data: response }) => {
      return response.data;
    });
};

export const getPetById = async (id) => {
  return axios.get("/pet-profiles/" + id).then(({ data: response }) => {
    return response.data;
  });
};

export const updatePet = async (id, body) => {
  return axios.put("/pet-profiles/" + id, body).then(({ data: response }) => {
    return response.data;
  });
};

export const markAsDeceased = async (id, body) => {
  return axios
    .put("/pet-profiles/deceased/" + id, body)
    .then(({ data: response }) => {
      return response.data;
    });
};

export const deletePet = async (id) => {
  return axios.delete("/pet-profiles/" + id).then(({ data: response }) => {
    return response.data;
  });
};

export const getPetBreeds = async (body) => {
  return axios.get("/pets/breeds-list", body).then(({ data: response }) => {
    return response.data;
  });
};

export const getDeceasedReasons = async (body) => {
  return axios
    .get("/pets/deceased-reasons", body)
    .then(({ data: response }) => {
      return response.data;
    });
};
