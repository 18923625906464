import React, { useEffect } from "react";
import { PiDogFill } from "react-icons/pi";
import { MoreOptionsDropdown } from "./SinglePetNew";
import { Tag } from "antd";

const PrimaryDog = ({
  loading,
  firstPet,
  multiplePets,
  isPausedSubscription,
  activePetPresent,
}) => {
  useEffect(() => {}, [firstPet]);

  if (loading) {
    return (
      <div className="bg-gray-100 animate-pulse border rounded-xl shadow-sm sm:flex mx-10 mb-5 lg:h-72 max-w-[70rem]"></div>
    );
  }

  const petAgeText = firstPet.age
    ? `${Math.floor(firstPet.age / 12)} Years ${firstPet.age % 12} Months`
    : "Age not specified";

  return (
    <div className="bg-white border rounded-xl sm:flex mx-10 mb-5 lg:h-64 max-w-[30rem] ">
      {/* Image Section */}
      <div className="shrink-0 relative  rounded-t-xl overflow-hidden sm:rounded-s-xl  md:rounded-se-none ">
        {firstPet.profilePicURL ? (
          <img
            className="z-50 object-cover h-full w-full transition-transform duration-300 hover:scale-110"
            src={firstPet?.profilePicURL || "https://via.placeholder.com/500"}
            alt="Pet"
          />
        ) : (
          <PiDogFill className="lg:text-[13rem] md:text-[10rem] text-[20rem] transition-transform duration-300 hover:scale-110 text-gray-300" />
        )}
      </div>

      {/* Content Section */}
      <div className="flex flex-col justify-start items-start text-left w-3/3 py-4  px-2 md:px-0 lg:mx-0">
        {firstPet.petName && (
          <h3 className="text-2xl md:text-2xl font-bold text-gray-800 transition-colors duration-300">
            {firstPet.petName}
          </h3>
        )}
        <div className="ml-auto">
          <MoreOptionsDropdown
            petId={firstPet.id}
            petPlan={firstPet.planSize}
            activePetPresent={activePetPresent}
            petAddress={firstPet.Address}
            multiplePets={multiplePets}
            isPausedSubscription={firstPet.isPausedSubscription}
          />
        </div>

        {firstPet.breed && (
          <p className=" text-gray-500 text-lg">{firstPet.breed}</p>
        )}
        {(firstPet.gender || firstPet.weight || firstPet.age) && (
          <p className="text-gray-500 text-md ">
            {firstPet.gender} • {firstPet.weight} Lbs • {petAgeText}
          </p>
        )}

        {/* Status and Plan Indicators */}
        <div className="flex flex-col font-bold md:flex-col lg:flex-row gap-4  ">
          <div>
            <Tag
              color={
                firstPet.isSubscriptionActive
                  ? firstPet.isPausedSubscription
                    ? "Gray"
                    : "green"
                  : "red"
              }
              style={{ fontSize: "16px", width: "100%", height: "26px" }}
            >
              {firstPet.isSubscriptionActive
                ? firstPet.isPausedSubscription
                  ? "Paused"
                  : "Active"
                : "Inactive"}
            </Tag>
          </div>

          {firstPet.planSize && (
            <div>
              <span className="p-1 inline-flex items-center justify-center text-xs font-bold leading-none bg-amber-600 text-amber-700 rounded-lg bg-opacity-25 border-2 border-amber-500">
                {firstPet.planSize} plan
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PrimaryDog;
