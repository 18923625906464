import React, { useState, useEffect } from "react";
import "./PetDetails.scss";
import SinglePet from "../../components/ui/SinglePet/SinglePet";
import SinglePetCard from "./SinglePetCard";
import Page from "../../components/core/Page/Page";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { getPets } from "../../components/services/api.service";
import { useAuth } from "../../components/context/authContext";
import petsImg from "../../utils/petdogs.png";
import { AiOutlineArrowRight, AiOutlineClose } from "react-icons/ai";
import { HiExternalLink } from "react-icons/hi";
import axios from "axios";
import { FaDog } from "react-icons/fa6";
import { CiCirclePlus } from "react-icons/ci";
import moment from "moment";
import Panels from "./Panels";
import { Button } from "antd";
import SinglePetNew from "../../components/ui/SinglePet/SinglePetNew";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const AddPetButton = () => {
  const navigate = useNavigate();
  const { subscription } = useAuth();
  const [ref, inView] = useInView();

  const { data: pets = [] } = useQuery("pets", getPets);
  const [petData, setPetData] = useState(false);

  const [hasSubscription, setHasSubscription] = useState(true);
  const [multiplePets, setMultiplePets] = useState(false);
  const [activePetPresent, setActivePetPresent] = useState(false);
  const [hasPausedSubscription, setHasPausedSubscription] = useState(false);

  const buttonVariants = {
    hidden: { opacity: 0, y: 100 },
    visible: { opacity: 1, y: 0, transition: { duration: 1 } },
  };
  useEffect(() => {
    const fetchPetData = async () => {
      try {
        const petResponse = await axios.get("/pet-profiles/user-pets");
        const petDataResponse = petResponse.data;

        if (petDataResponse.success) {
          // Filter out pets with "isDeceased" as true
          const filteredPets = petDataResponse.data.filter(
            (pet) => !pet.isDeceased
          );
          const hasPausedSubscription = petDataResponse.data.some(
            (pet) => pet.isPausedSubscription
          );

          const hasActivePet = filteredPets.some(
            (pet) => pet.petStatus === "active"
          );

          // Set state for active pet present
          setActivePetPresent(hasActivePet);
          setPetData(filteredPets.length > 0 ? filteredPets : false);
          setMultiplePets(filteredPets.length > 1);
          setHasPausedSubscription(hasPausedSubscription);
        } else {
        }
      } catch (error) {}
    };

    const fetchSubscriptionData = async () => {
      try {
        const subscriptionResponse = await axios.get("/users/subscription");
        const subscriptionData = subscriptionResponse.data;

        if (
          subscriptionResponse.status === 400 &&
          subscriptionData.messageCode === "CUSTOMER_HAS_NO_SUBSCRIPTION"
        ) {
          setHasSubscription(false);
        } else {
          setHasSubscription(true);
        }
      } catch (error) {
        // Handle error and set hasSubscription to false
        setHasSubscription(false);
      }
    };
    fetchPetData();
    fetchSubscriptionData();
  }, []);
  return (
    <div ref={ref}>
      {petData && (
        <div className="overflow-hidden bg-white py-32 ">
          <div className="mx-auto max-w-7xl px-6 lg:flex lg:px-8">
            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-12 gap-y-16 lg:mx-0 lg:min-w-full lg:max-w-none lg:flex-none lg:gap-y-8">
              <div className="lg:col-end-1 lg:w-full lg:max-w-lg lg:pb-8">
                <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  Add New Pets
                </h2>

                {!hasSubscription ? (
                  <p className="mt-6 text-xl leading-8 text-gray-600">
                    You have pets added, but no subscription. To add more pets
                    and purchase a subscription, click the "Add Pet
                    Subscription" button.
                  </p>
                ) : (
                  <p className="mt-6 text-xl leading-8 text-gray-600">
                    You already have pets and a subscription. If you want to add
                    more pets, click the "Add Pet Subscription" button.
                  </p>
                )}
                <p className="mt-6 text-base leading-7 text-gray-600">
                  Whether you have a single beloved pet or an entire furry
                  family, we have you covered! Add your pets to our service one
                  at a time and enjoy exclusive discounts for multiple fur
                  babies.
                </p>
                <div className="mt-10 flex mx-auto">
                  <Link
                    className="rounded-md  flex justify-center items-center bg-amber-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-amber-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-amber-600"
                    to={"/myplans"}
                  >
                    {/* <CiCirclePlus className="text-4xl" /> */}
                    <span className="label mr-2">
                      Add a Pet Subscription
                    </span>{" "}
                    <span aria-hidden="true">&rarr;</span>
                  </Link>
                </div>
              </div>
              <div className="flex flex-wrap items-start justify-end gap-6 sm:gap-8 lg:contents hidden md:hidden lg:block">
                <motion.div
                  variants={buttonVariants}
                  initial="hidden"
                  animate={inView ? "visible" : "hidden"}
                  className="w-0 flex-auto lg:ml-auto lg:w-auto lg:flex-none lg:self-end"
                >
                  <img
                    src="https://images.unsplash.com/photo-1548199973-03cce0bbc87b?q=80&w=2069&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    alt=""
                    className="aspect-[7/4] w-[43rem] max-w-none rounded-2xl bg-gray-50 object-cover"
                  />
                </motion.div>
                <div className="contents lg:col-span-2 lg:col-end-2 lg:ml-auto lg:flex lg:w-[37rem] lg:items-start lg:justify-end lg:gap-x-8">
                  {/* <motion.div
                    variants={buttonVariants}
                    initial="hidden"
                    animate={inView ? "visible" : "hidden"}
                    className="order-first flex w-64 flex-none justify-end self-end lg:w-auto"
                  >
                    <img
                      src="https://images.unsplash.com/photo-1583511655826-05700d52f4d9?q=80&w=1888&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                      alt=""
                      className="aspect-[4/3] w-[24rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover"
                    />
                  </motion.div> */}
                  {/* <motion.div
                    variants={buttonVariants}
                    initial="hidden"
                    animate={inView ? "visible" : "hidden"}
                    className="flex w-96 flex-auto justify-end lg:w-auto lg:flex-none"
                  >
                    <img
                      src="https://images.unsplash.com/photo-1583337130417-3346a1be7dee?q=80&w=1964&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                      alt=""
                      className="aspect-[7/4] w-[47rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover"
                    />
                  </motion.div>
                  <motion.div
                    variants={buttonVariants}
                    initial="hidden"
                    animate={inView ? "visible" : "hidden"}
                    className="hidden sm:block sm:w-0 sm:flex-auto lg:w-auto lg:flex-none"
                  >
                    <img
                      src="https://images.unsplash.com/photo-1583511655826-05700d52f4d9?q=80&w=1888&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                      alt=""
                      className="aspect-[4/3] w-[24rem] max-w-none rounded-2xl bg-gray-50 object-cover"
                    />
                  </motion.div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddPetButton;
