// MyPlan.js
import React, { useState, useEffect } from "react";
import "./MyPlan.scss";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import plansImg from "../../utils/plans.svg";
import { RadioGroup } from "@headlessui/react";
import { initialPlans, onePetPlans, moreThanOnePetPlans } from "./plans";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const MyPlan = () => {
  const [plan, setPlan] = useState("small");
  const [group, setGroup] = useState("pack");
  const [petCount, setPetCount] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get("/pet-profiles/user-pets")
      .then((response) => {
        const numberOfPets = response.data.data.length;
        setPetCount(numberOfPets);
      })
      .catch((error) => {});
  }, []);
  useEffect(() => {
    axios
      .get("/users/account")
      .then((response) => {
        const userData = response.data.data;
        if (userData.invitedByVet) {
          localStorage.setItem("invitedByVet", userData.invitedByVet);
        }
      })
      .catch((error) => {});
  }, []);

  const handlePackSelect = (pack) => {
    setPlan(pack);
  };

  const getSelectedPlans = () => {
    if (petCount === 0) {
      return initialPlans;
    } else if (petCount === 1) {
      return onePetPlans;
    } else {
      return moreThanOnePetPlans;
    }
  };

  const continueHandler = () => {
    const selectedPlans = getSelectedPlans();
    const newPath = "/pets/add";
    const queryParam = `?plan=${plan}`;

    // Save the path to local storage
    localStorage.setItem("planpath", newPath + queryParam);

    // Navigate to the new path
    navigate({
      pathname: newPath,
      search: queryParam,
      state: { adjustedPlans: selectedPlans },
    });
  };

  const selectedPlans = getSelectedPlans();

  return (
    <div className="container mx-auto max-w-screen-xl py-5">
      <div className="row">
        <div className="col-md-12">
          <div className="myplan">
            <div className="plan-info-wrapper text-center">
              <h1>Choose your dog’s plan</h1>
              <p className="mb-10">
                Have a single pet, or an entire pack to add? Add your pets one
                at a time and enjoy a discount for multiple fur babies ($5/month
                for the second, $10/month for the rest of the pack).
              </p>
            </div>

            <div className="lg:flex lg:flex-row items-center justify-center lg:ml-auto">
              <div className="ml-auto mb-4 lg:mb-0 lg:mr-4 md:ml-40 sm:ml-40 items-center justify-center">
                <img
                  src={plansImg}
                  alt=""
                  className="lg:h-full lg:w-full mt-4"
                />
              </div>
              <div
                className={`plans ${
                  group === "pack" ? "" : "hidden"
                } lg:flex h-[510px]`}
              >
                <div className="plan-group">
                  <div
                    className="plan-group-title cursor-pointer"
                    onClick={() => setGroup("pack")}
                  ></div>
                  {group === "pack" && (
                    <div>
                      <div className="text-center">
                        <span className="font-bold text-3xl">
                          Join our pack
                        </span>
                      </div>

                      <div className="flex flex-col space-y-36 mt-5">
                        {selectedPlans.map((p) => (
                          <div
                            key={p.label}
                            className="single-plan cursor-pointer"
                            onClick={() => handlePackSelect(p.label)}
                          >
                            <span
                              className={`plan-radio mr-10 ${
                                p.label === plan ? "checked" : ""
                              }`}
                            ></span>
                            <div className="text-xl font-bold">
                              <div className="">{p.title}</div>
                              <div className="price mb-2">{p.price}</div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="">
                        <button
                          className="bg-amber-500 text-white font-semibold px-20 py-3 rounded-lg hover:scale-95 hover:bg-amber-600 transition-transform duration-300 ease-in-out w-full mt-10"
                          onClick={continueHandler}
                        >
                          Continue
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyPlan;
