import React, { useState, useEffect } from "react";
import "./FooterAlt.scss";
import phone from "../../../utils/app_phones-02.png";
import { FaFacebook, FaTwitter, FaTiktok, FaLinkedin } from "react-icons/fa";
import { IoLogoInstagram } from "react-icons/io";
import { FaXTwitter } from "react-icons/fa6";
import { PhoneIcon } from "../../Assets";
export default function FooterAlt() {
  const [visible, setVisible] = useState(true);
  const handleVisible = () => {
    let url = window?.location.pathname;

    if (
      url === "/stripe-redirect-checkout" ||
      url === "/checkoutPayment/success"
    ) {
      setVisible(false);
    }
  };
  useEffect(() => {
    handleVisible();
  }, []);
  const linksAndItems = [
    {
      link: "https://carsonandbearpets.com/subscription-info/",
      text: "Subscriptions",
    },
    {
      link: "https://carsonandbearpets.com/about-us/#testimonials",
      text: "Testimonials",
    },
    { link: "https://mypack.carsonandbearpets.com/", text: "Customer Login" },
    {
      link: "https://vsp.carsonandbearpets.com/login",
      text: "Veterinarian Login",
    },
    { link: "https://help.carsonandbearpets.com/", text: "Support" },
    // { link: "https://carsonandbearpets.com/faqs/", text: "FAQs" },
    {
      link: "https://carsonandbearpets.com/terms-and-conditions/",
      text: "Terms & Conditions",
    },
    {
      link: "https://carsonandbearpets.com/privacy-policy/",
      text: "Privacy Policy",
    },
    {
      link: "https://carsonandbearpets.com/our-program/",
      text: "Product Information",
    },
    {
      link: "https://carsonandbearpets.com/carsonbear-pets-blog/",
      text: "Carson&Bear Blog",
    },
    // {
    //   link: "https://carsonandbearpets.com/investor-relations/",
    //   text: "Careers",
    // },
    {
      link: "https://carsonandbearpets.com/investor-relations/",
      text: "Investor Relations",
    },
    {
      link: "https://carsonandbearpets.com/affiliates-partners/",
      text: "Affiliates & Partners",
    },
    { link: "https://givethemanotheryear.com/", text: "Veterinarians" },
    {
      link: "https://carsonandbearpets.com/feed-this-skip-that/",
      text: "Food Recalls",
    },
    { link: "https://carsonandbearpets.com/contact/", text: "Contact" },
  ];
  const middleIndex = Math.ceil(linksAndItems.length / 2);
  const column1 = linksAndItems.slice(0, middleIndex);
  const column2 = linksAndItems.slice(middleIndex);
  const linkStyle = {
    textDecoration: "none",
    color: "black",
    linkStyle: "none",
    lineHeight: "2.5",
  };

  return visible ? (
    <footer>
      <div className="footer-alt-wrap lg:px-32 md:px-10 px-10 py-10">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <h4>Carson&Bear™ Executive Office:</h4>
              <ul className="address-section list-unstyled">
                <li className="list-content">
                  <a
                    className="mb-5"
                    href="https://maps.app.goo.gl/eqNNxnvb4HMyeEHU8"
                  >
                    <p className="mb-0">1 Chisholm Trail Suite 450</p>
                    <span className="mt-0 mb-5">Round Rock ,Texas 78681</span>
                  </a>
                </li>
              </ul>

              <h4 className="mt-5">Other Locations:</h4>
              <ul className="address-section list-unstyled">
                <li className="list-content">
                  <a className="mb-5" href="/">
                    <p className="mb-0">Office - Houston, TX</p>
                    <span className="mt-0 mb-5">
                      Kitchen - College Station, TX
                    </span>
                    <p className="mt-0 mb-5">Rescue Ranch - Caldwell, TX</p>
                  </a>
                </li>
              </ul>
              <div className="flex mb-5 lg:mb-0 space-x-7">
                <a href="https://www.instagram.com/carsonandbear/">
                  <IoLogoInstagram className="text-3xl text-amber-600 hover:text-amber-500 transition-transform ease-in-out hover:scale-110 duration-500" />
                </a>
                <a href="https://www.facebook.com/carsonandbear">
                  <FaFacebook className="text-3xl text-amber-600 hover:text-amber-500 transition-transform ease-in-out hover:scale-110 duration-500" />
                </a>
                <a href="https://twitter.com/carsonandbear">
                  <FaXTwitter className="text-3xl text-amber-600 hover:text-amber-500 transition-transform ease-in-out hover:scale-110 duration-500" />
                </a>
                <a href="https://www.tiktok.com/@carsonandbear">
                  <FaTiktok className="text-3xl text-amber-600 hover:text-amber-500 transition-transform ease-in-out hover:scale-110 duration-500" />
                </a>
                <a href="https://www.linkedin.com/company/carsonandbear">
                  <FaLinkedin className="text-3xl text-amber-600 hover:text-amber-500 transition-transform ease-in-out hover:scale-110 duration-500" />
                </a>
              </div>
            </div>
            <div className="col-md-4 mx-auto">
              {" "}
              <img src={phone} className="w-72 h-64" />
              <div className="flex mt-4">
                <a
                  href="https://play.google.com/store/apps/details?id=com.tailscore"
                  target=" _blank"
                  rel="noreferrer"
                >
                  <img
                    src="/images/android-download.png"
                    className="w-44 h-14 mr-2 hover:scale-95"
                  />
                </a>
                <a
                  href="https://apps.apple.com/us/app/tailscore/id6450352918"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="/images/ios-download.png"
                    alt="Ios Download"
                    className="w-44 h-14  hover:scale-95"
                  />
                </a>
              </div>
            </div>

            <div className="col-md-4 mt-5 mt-md-0">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  maxWidth: "600px",
                  margin: "0 auto",
                }}
              >
                <div>
                  <ul>
                    {column1.map(({ link, text }, index) => (
                      <li key={index} style={{ listStyle: "none" }}>
                        <a
                          href={link}
                          style={linkStyle}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {text}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
                <div>
                  <ul>
                    {column2.map(({ link, text }, index) => (
                      <li key={index} style={{ listStyle: "none" }}>
                        <a
                          href={link}
                          style={linkStyle}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {text}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" flex h-14 text-center bg-amber-600 text-lg font-thin text-white justify-center items-center">
        <h6 className=" my-auto">©2024 Carson&Bear™ Dog Wellness Program</h6>
      </div>
    </footer>
  ) : (
    ""
  );
}
