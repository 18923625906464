import React, { useState, useEffect } from "react";
import { Disclosure } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import { initSubscription } from "../../components/services/api.service";
import { loadStripe } from "@stripe/stripe-js";
// import { CheckoutForm } from "./Checkout"/;
import { PiDogFill } from "react-icons/pi";
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import axios from "axios";

// Test Mode
// const stripePromise = loadStripe(
//   "pk_test_51MvOEbGcnkPKGaUh0bexrc8MU4uAzDOwk43ksV972EEH4r8SGJjStxlHouTJaHYio5MWifq24ivcaWM1J0YZowqd009ddkR8eV"
// );
// Live Mode
const stripePromise = loadStripe(process.env.REACT_APP_API_SERVER);
const NewCheckout = () => {
  const navigate = useNavigate();
  const [intent, setIntent] = useState();
  const [petData, setPetData] = useState([]);
  const [mergedData, setMergedData] = useState([]);
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [taxData, setTaxData] = useState([]);
  const [taxRate, setTaxRate] = useState("");

  // const [taxPercentage, setTaxPercentage] = useState(0);

  const [intentError, setIntenterror] = useState(null);

  useEffect(() => {
    axios
      .get("/pet-profiles/user-pets")
      .then((response) => {
        setPetData(response.data.data);
      })
      .catch((error) => {});
  }, []);

  const handleFetchTax = () => {
    axios
      .get("/users/subscription/tax-v1")
      .then((res) => {
        setTaxData(res.data.data);
        // setTaxRate(res.data.data[0].taxData.rate);            s
      })
      .catch((err) => {
        // navigate("/taxFail");
      });
  };

  const mergeData = () => {
    const merged = [];
    petData.forEach((pet) => {
      const taxInfo = taxData.find((tax) => tax.petId === pet.id);
      if (taxInfo) {
        merged.push({ ...pet, ...taxInfo });
      }
    });
    setMergedData(merged);
  };

  useEffect(() => {
    if (taxData.length > 0 && petData.length > 0) {
      mergeData();
    }
  }, [taxData, petData]);

  const handleUpdateSubscription = () => {
    axios.get("/users/subscription/estimate").then((res) => {
      setSubscriptionData(res.data.data);
    });
  };

  const handleUpdateIntent = () => {
    initSubscription()
      .then((res) => {
        setIntent(res.data);
      })
      .catch((err) => setIntenterror("error"));
  };

  useEffect(() => {
    handleUpdateIntent();
    handleUpdateSubscription();
    handleFetchTax();
  }, []);

  const totalAmount = subscriptionData?.total || 0;
  const taxAmount = taxData || 0;
  const taxPercentage = taxRate || 0;

  // const finalTotal = totalAmount + taxAmount;
  if (!intent) {
    return "Loading...";
  }

  return (
    <>
      <main className="lg:flex lg:min-h-full lg:flex-row-reverse lg:overflow-hidden ">
        <h1 className="sr-only">Checkout</h1>

        {/* Mobile order summary */}
        <section
          aria-labelledby="order-heading"
          className="bg-gradient-to-r from-amber-50 to-amber-200 px-4 py-6 sm:px-6 lg:hidden"
        >
          <Disclosure as="div" className="mx-auto max-w-lg">
            {({ open }) => (
              <>
                <div className="flex items-center justify-between">
                  <h2
                    id="order-heading"
                    className="text-lg font-medium text-gray-900"
                  >
                    Your Order
                  </h2>
                  <Disclosure.Button className="font-medium text-amber-900 hover:text-amber-900">
                    {open ? (
                      <span>Hide full summary</span>
                    ) : (
                      <span>Show full summary</span>
                    )}
                  </Disclosure.Button>
                </div>

                <Disclosure.Panel>
                  <ul
                    role="list"
                    className="divide-y divide-gray-900 border-b border-gray-200"
                  >
                    {mergedData.map((pet) => {
                      return (
                        <li key={pet.petId} className="flex space-x-6 py-6">
                          {pet.profilePicURL ? (
                            <img
                              src={pet.profilePicURL}
                              alt={pet.petName}
                              className="h-20 w-20 flex-none rounded-md bg-gray-200 object-cover object-center"
                            />
                          ) : (
                            <div className="h-20 w-20 flex-none rounded-md bg-gray-200 flex items-center justify-center">
                              <PiDogFill className="h-20 w-20 text-gray-400" />
                            </div>
                          )}
                          <div className="flex flex-col justify-between space-y-4">
                            <div className="space-y-1 text-sm font-medium">
                              <h3 className="text-gray-900">{pet.petName}</h3>
                              <p className="text-gray-900">{pet.breed}</p>
                              <p className="text-gray-500">{pet.age}</p>
                              <p className="text-gray-500">{pet.gender}</p>

                              <>
                                <table className="  w-full bg-white/30 p-3 ">
                                  <tbody>
                                    <tr>
                                      <td className="border border-gray-900 p-2 text-blue-600">
                                        Price
                                      </td>
                                      <td className="border border-gray-900 p-2">
                                        ${(pet.price / 100).toFixed(2)}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="border border-gray-900 p-2 text-green-600">
                                        Tax Rate
                                      </td>
                                      <td className="border border-gray-900 p-2">
                                        {pet.taxData.rate}
                                      </td>
                                    </tr>

                                    <tr>
                                      <td className="border border-gray-900 p-2 text-red-600">
                                        Total Tax
                                      </td>
                                      <td className="border border-gray-900 p-2">
                                        ${(pet.taxAmount / 100).toFixed(2)}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="border border-gray-900 p-2 text-indigo-600">
                                        Total Amount
                                      </td>
                                      <td className="border border-gray-900 p-2">
                                        $
                                        {(pet.priceIncludingTax / 100).toFixed(
                                          2
                                        )}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </section>

        {/* Order summary */}
        <section
          aria-labelledby="summary-heading"
          className="hidden w-full max-w-md flex-col bg-gradient-to-r from-amber-50 to-amber-200 lg:flex h-screen"
        >
          <h2 id="summary-heading" className="sr-only">
            Order summary
          </h2>

          <ul
            role="list"
            className="flex-auto divide-y divide-gray-900 overflow-y-auto px-6"
            style={{ marginRight: "-17px" }} // Compensate for scrollbar width
          >
            {mergedData.map((pet) => {
              return (
                <li key={pet.petId} className="flex space-x-6 py-6">
                  {pet.profilePicURL ? (
                    <img
                      src={pet.profilePicURL}
                      alt={pet.petName}
                      className="h-20 w-20 flex-none rounded-md bg-gray-200 object-cover object-center"
                    />
                  ) : (
                    <div className="h-20 w-20 flex-none rounded-md bg-gray-50 flex items-center justify-center">
                      <PiDogFill className="h-20 w-20 text-amber-900" />
                    </div>
                  )}
                  <div className="flex flex-col justify-between space-y-4">
                    <div className="space-y-1 text-sm font-medium">
                      <h3 className="text-gray-900">{pet.petName}</h3>
                      <p className="text-gray-900">{pet.breed}</p>
                      <p className="text-gray-500">{pet.age}</p>
                      <p className="text-gray-500">{pet.gender}</p>

                      <>
                        <table className="border-collapse border border-gray-900 w-full bg-white/30 p-3 rouned-lg">
                          <tbody>
                            <tr>
                              <td className="border border-gray-900 p-2 text-blue-600">
                                Price
                              </td>
                              <td className="border border-gray-900 p-2">
                                ${(pet.price / 100).toFixed(2)}
                              </td>
                            </tr>
                            <tr>
                              <td className="border border-gray-900 p-2 text-green-600">
                                Tax Rate
                              </td>
                              <td className="border border-gray-900 p-2">
                                {pet.taxData.rate}
                              </td>
                            </tr>

                            <tr>
                              <td className="border border-gray-900 p-2 text-red-600">
                                Total Tax
                              </td>
                              <td className="border border-gray-900 p-2">
                                ${(pet.taxAmount / 100).toFixed(2)}
                              </td>
                            </tr>
                            <tr>
                              <td className="border border-gray-900 p-2 text-indigo-600">
                                Total Amount
                              </td>
                              <td className="border border-gray-900 p-2">
                                ${(pet.priceIncludingTax / 100).toFixed(2)}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </section>
        {intent && intent.setupIntent && (
          <div className="w-full ">
            <div className="w-full">
              <div className="w-full">
                {intent && (
                  <div className="px-5 lg:px-40 py-5 shadow-sm mb-10  ">
                    {intentError ? (
                      // Display an error message if there is an intent error
                      <div>Error: {intentError}</div>
                    ) : (
                      <Elements
                        stripe={stripePromise}
                        options={{
                          clientSecret: intent.setupIntent,
                        }}
                      >
                        <CheckoutForm
                          intentError={intentError}
                          clientId={intent.setupIntentId}
                          clientSecret={intent.setupIntent}
                          totalAmount={totalAmount}
                          taxAmount={taxAmount}
                          taxPercentage={taxPercentage}
                          // finalTotal={finalTotal}
                        />
                      </Elements>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </main>
    </>
  );
};

export default NewCheckout;
