import React, { useState, useEffect } from "react";
import "./PetDetails.scss";
import SinglePet from "../../components/ui/SinglePet/SinglePet";
import SinglePetCard from "./SinglePetCard";
import Page from "../../components/core/Page/Page";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { getPets } from "../../components/services/api.service";
import { useAuth } from "../../components/context/authContext";
import petsImg from "../../utils/petdogs.png";
import { AiOutlineArrowRight, AiOutlineClose } from "react-icons/ai";
import { HiExternalLink } from "react-icons/hi";
import axios from "axios";
import { FaDog } from "react-icons/fa6";
import { CiCirclePlus } from "react-icons/ci";
import moment from "moment";
import Panels from "./Panels";
import { Button } from "antd";
import SinglePetNew from "../../components/ui/SinglePet/SinglePetNew";
import AddPetButton from "./AddPetButton";
import PrimaryDog from "../../components/ui/SinglePet/PrimaryDog";

export default function PetDetails() {
  const navigate = useNavigate();
  const { subscription } = useAuth();

  const { data: pets = [] } = useQuery("pets", getPets);
  const [petData, setPetData] = useState(false);

  const [hasSubscription, setHasSubscription] = useState(true);
  const [firstPet, setFirstPet] = useState([]);
  const [hasValidSubscription, setHasValidSubscription] = useState(false);

  const [multiplePets, setMultiplePets] = useState(false);
  const [activePetPresent, setActivePetPresent] = useState(false);
  const [hasPausedSubscription, setHasPausedSubscription] = useState(false);
  const [hasCancelledSubscription, setHasCancelledSubscription] =
    useState(false);
  const [cancelledSubscriptionPet, setCancelledSubscriptionPet] =
    useState(null);
  const [loading, setLoading] = useState(null);
  const [filteredPets, setFilteredPets] = useState([]);
  const [primaryPet, setPrimaryPet] = useState(null);
  useEffect(() => {
    const user = localStorage.getItem("user"); // Adjust key according to how you store user data
    if (!user) {
      navigate("/"); // Redirect to homepage if user is not logged in
    }
  }, [navigate]);
  const determinePrimaryPet = (pets) => {
    // Filter out pets with cancelledSubscription as true
    const activePets = pets.filter(
      (pet) => !pet.isDeceased && !pet.cancelledSubscription
    );

    if (activePets.length === 0) {
      return null; // If no active pets are left, return null
    }

    if (activePets.length === 1) {
      return activePets[0];
    }

    // Sort by planSize first (small > medium > large), then by createdAt
    const sizeOrder = ["small", "medium", "large"];
    const sortedPets = activePets.sort((a, b) => {
      const sizeComparison =
        sizeOrder.indexOf(a.planSize) - sizeOrder.indexOf(b.planSize);

      return sizeComparison !== 0
        ? sizeComparison
        : new Date(a.createdAt) - new Date(b.createdAt);
    });

    return sortedPets[0];
  };
  // useEffect(() => {
  //   if (localStorage.getItem("recallagain")) {
  //     localStorage.removeItem("recallagain");
  //     window.location.reload();
  //   }
  // }, []);

  // Filter function for active pets (not deceased)
  const getActivePets = (petsArray) => {
    return petsArray.filter(
      (pet) => !pet.isDeceased && !pet.cancelledSubscription
    );
  };

  // Get filtered pets for v2 (excluding primary pet)
  const getFilteredPets = (petsArray) => {
    return petsArray
      .filter((pet) => pet.id !== primaryPet?.id)
      .filter((pet) => !pet.isDeceased && !pet.cancelledSubscription);
  };

  const handleCheckout = () => {
    navigate("/checkout");
  };
  useEffect(() => {
    const fetchPetData = async () => {
      setLoading(true);
      try {
        const petResponse = await axios.get("/pet-profiles/user-pets");
        const petDataResponse = petResponse.data;

        const firstPet = petDataResponse.data[0] || null;
        setFirstPet(firstPet);

        if (petDataResponse.success) {
          const pets = petDataResponse.data;

          // Filter out pets with "isDeceased" as true
          const filteredPets = pets.filter((pet) => !pet.isDeceased);
          setFilteredPets(filteredPets);

          const hasPausedSubscription = pets.some(
            (pet) => pet.isPausedSubscription
          );

          const cancelledSubscriptionPet = pets.find(
            (pet) => pet.cancelledSubscription
          );

          const hasActivePet = filteredPets.some(
            (pet) => pet.petStatus === "active"
          );

          const hasValidSubscriptions = filteredPets.some(
            (pet) => pet.subscriptionCreatedAt !== null
          );

          // Update states
          setHasValidSubscription(hasValidSubscriptions);
          setPrimaryPet(determinePrimaryPet(pets));
          setActivePetPresent(hasActivePet);
          setPetData(filteredPets.length > 0 ? filteredPets : []);
          setMultiplePets(filteredPets.length > 1);
          setHasPausedSubscription(hasPausedSubscription);
          setHasCancelledSubscription(!!cancelledSubscriptionPet);
          setCancelledSubscriptionPet(cancelledSubscriptionPet || null);

          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    };

    const fetchSubscriptionData = async () => {
      try {
        const subscriptionResponse = await axios.get("/users/subscription");
        const subscriptionData = subscriptionResponse.data;

        if (
          subscriptionResponse.status === 400 &&
          subscriptionData.messageCode === "CUSTOMER_HAS_NO_SUBSCRIPTION"
        ) {
          setHasSubscription(false);
        } else {
          setHasSubscription(true);
        }
      } catch (error) {
        // Handle error and set hasSubscription to false
        setHasSubscription(false);
      }
    };

    fetchPetData();
    fetchSubscriptionData();
  }, []);

  return (
    <Page title="Pet Details">
      <div className="pet-list-page mt-20">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {filteredPets.length === 0 && (
                <div>
                  <div className="dog-img-wrapper ">
                    <img
                      src={petsImg}
                      className="w-56 h-56 rounded-full object-cover ml-10"
                      alt="Pets"
                    />
                  </div>

                  <div className="pet-actions">
                    {pets.length === 0 && (
                      <p className="fs-5 text-muted">
                        No pets found. Please add a pet.
                      </p>
                    )}
                    <Link
                      className="add-pet-button btn btn-primary d-inline-flex p-3 align-items-center gap-2 bg-amber-500 hover:bg-amber-600  font-semibold hover:scale-95 transition-transform duration-300 ease-in-out"
                      to={"/myplans"}
                    >
                      <CiCirclePlus className="text-4xl" />
                      <span className="label">Add a Pet Subscription</span>
                    </Link>
                  </div>
                </div>
              )}

              <div className="pets-list px-1 py-1 mt-10 lg:px-5 lg:py-5">
                {/* {subscription && (
                  <div className="row mb-4">
                    <div className="col-md-12">
                      <div className="px-4 py-4 border-secondary border text-start bg-secondary rounded">
                        <h4 className="m-0 mb-2 fs-5">Active Subscription</h4>
                        <p className="fs-5 text-muted mb-0 mt-1">
                          Current Period :{" "}
                          {moment
                            .unix(subscription.current_period_start)
                            .format("Do MMM")}{" "}
                          -{" "}
                          {moment
                            .unix(subscription.current_period_end)
                            .format("Do MMM")}
                        </p>
                      </div>
                    </div>
                  </div>
                )} */}
                {subscription && activePetPresent && (
                  <div className="relative">
                    <div className="relative max-w-8xl mb-5 lg:px-8 ">
                      <div className="relative isolate overflow-hidden bg-white/5 px-6 py-24 text-center shadow-md sm:rounded-3xl sm:px-16">
                        <h2 className="mx-auto max-w-2xl text-3xl font-bold tracking-tight text-lime-600 sm:text-4xl">
                          Active Subscription
                        </h2>

                        <>
                          <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-800">
                            You're currently subscribed! Enjoy access to our
                            premium features during this period
                          </p>
                          <p className="mx-auto mt-2 max-w-xl text-lg leading-8 text-gray-700">
                            Subscription Period:{" "}
                            {moment
                              .unix(subscription.current_period_start)
                              .format("Do MMM")}{" "}
                            -{" "}
                            {moment
                              .unix(subscription.current_period_end)
                              .format("Do MMM")}
                          </p>
                          <div className="mt-10 flex items-center justify-center gap-x-6">
                            <Link
                              to="/plans"
                              className="flex rounded-md bg-amber-500 px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-amber-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                            >
                              <FaDog className="mr-2 text-xl" />
                              View my active subscription
                            </Link>
                          </div>
                        </>

                        <svg
                          viewBox="0 0 1024 1024"
                          className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)]"
                          aria-hidden="true"
                        >
                          <circle
                            cx={512}
                            cy={512}
                            r={512}
                            fill="url(#827591b1-ce8c-4110-b064-7cb85a0b1217)"
                            fillOpacity="0.7"
                          />
                          <defs>
                            <radialGradient id="827591b1-ce8c-4110-b064-7cb85a0b1217">
                              <stop stopColor="#EAFE00 " />
                              <stop offset={1} stopColor="#FE9200 " />
                            </radialGradient>
                          </defs>
                        </svg>
                      </div>
                    </div>
                  </div>
                )}
                {hasPausedSubscription && !subscription && (
                  <div className="relative">
                    <div className="relative max-w-8xl mb-5 lg:px-8 ">
                      <div className="relative isolate overflow-hidden bg-white/5 px-6 py-24 text-center shadow-md sm:rounded-3xl sm:px-16">
                        <h2 className="mx-auto max-w-2xl text-3xl font-bold tracking-tight text-lime-600 sm:text-4xl">
                          Active Subscription
                        </h2>

                        <>
                          <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-orange-600">
                            Your subscription is paused. To view Subscription
                            period and details, resume the subscription
                          </p>
                        </>

                        <svg
                          viewBox="0 0 1024 1024"
                          className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)]"
                          aria-hidden="true"
                        >
                          <circle
                            cx={512}
                            cy={512}
                            r={512}
                            fill="url(#827591b1-ce8c-4110-b064-7cb85a0b1217)"
                            fillOpacity="0.7"
                          />
                          <defs>
                            <radialGradient id="827591b1-ce8c-4110-b064-7cb85a0b1217">
                              <stop stopColor="#EAFE00 " />
                              <stop offset={1} stopColor="#FE9200 " />
                            </radialGradient>
                          </defs>
                        </svg>
                      </div>
                    </div>
                  </div>
                )}
                <div className="px-10 ">
                  {!hasSubscription &&
                    petData &&
                    !hasPausedSubscription &&
                    filteredPets.length > 0 && (
                      <div className="bg-gray-200 bg-opacity-30 w-full p-0  mb-10 rounded-lg h-auto">
                        <Panels />

                        <div className="p-4">
                          <p className="mb-2 text-gray-700 text-md lg:text-xl">
                            Looks like you don’t have an active subscription
                            yet! When you're ready, just click the ‘Continue’
                            button to keep going.
                          </p>
                          <div className="flex justify-center lg:justify-end">
                            <Button
                              type="button"
                              size="large"
                              className="bg-amber-600 hover:bg-amber-700 text-white font-medium rounded add mt-3 lg:mt-2 w-full md:2/12 lg:w-2/12"
                              onClick={handleCheckout}
                            >
                              Continue
                            </Button>
                          </div>
                        </div>
                      </div>
                    )}
                </div>

                {/* Only show Primary Dog section for v2 and if primary pet is not deceased */}
                {!loading &&
                  primaryPet &&
                  !primaryPet.isDeceased &&
                  !primaryPet.cancelledSubscription === true &&
                  localStorage.getItem("subscriptionType") !== "v1" && (
                    <div>
                      {getActivePets(pets).length !== 0 && (
                        <>
                          <h1 className="text-black flex justify-start text-4xl tracking-tighter items-start ml-11">
                            First Dog
                          </h1>
                          <PrimaryDog
                            loading={loading}
                            firstPet={primaryPet}
                            activePetPresent={activePetPresent}
                            multiplePets={multiplePets}
                          />
                        </>
                      )}
                    </div>
                  )}

                {/* Only show Add-on Dogs heading for v2 if there are active add-on pets */}
                {getActivePets(pets).length > 1 &&
                  localStorage.getItem("subscriptionType") !== "v1" && (
                    <h1 className="text-black flex justify-start text-4xl tracking-tighter items-start ml-11">
                      Pack Pal
                    </h1>
                  )}

                {/* Show filtered pets list */}
                {!loading && (
                  <div className="flex flex-wrap justify-left px-10">
                    {localStorage.getItem("subscriptionType") === "v1"
                      ? getActivePets(pets).map((pet) => (
                          <div
                            key={pet.id}
                            className="w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/4 p-2 "
                          >
                            <SinglePetNew
                              id={pet.id}
                              name={pet.petName}
                              activePetPresent={activePetPresent}
                              gender={pet.gender}
                              weight={pet.weight}
                              age={pet.age}
                              isPausedSubscription={pet.isPausedSubscription}
                              profilePicURL={pet.profilePicURL}
                              breed={pet.breed}
                              plan={pet.planSize}
                              isDeceased={
                                pet.isDeceased || pet.cancelledSubscription
                              }
                              loading={loading}
                              petAddress={pet.Address}
                              hasValidSubscription={pet.subscriptionCreatedAt}
                              isActive={pet.isSubscriptionActive ? true : false}
                              multiplePets={multiplePets}
                            />
                          </div>
                        ))
                      : getFilteredPets(pets).map((pet) => (
                          <div
                            key={pet.id}
                            className="w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/4 p-2"
                          >
                            <SinglePetNew
                              id={pet.id}
                              name={pet.petName}
                              activePetPresent={activePetPresent}
                              gender={pet.gender}
                              weight={pet.weight}
                              age={pet.age}
                              isPausedSubscription={pet.isPausedSubscription}
                              profilePicURL={pet.profilePicURL}
                              breed={pet.breed}
                              plan={pet.planSize}
                              isDeceased={
                                pet.isDeceased || pet.cancelledSubscription
                              }
                              loading={loading}
                              petAddress={pet.Address}
                              hasValidSubscription={pet.subscriptionCreatedAt}
                              isActive={pet.isSubscriptionActive ? true : false}
                              multiplePets={multiplePets}
                            />
                          </div>
                        ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddPetButton />
    </Page>
  );
}
