import React, { useEffect } from "react";
import Lottie from "react-lottie";
import dogLottie from "../../utils/loading.json";
const LoadingFormLoader = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: dogLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when component mounts
  }, []);

  return (
    <div className="flex flex-col items-center justify-center h-[24rem]">
      <div className="mt-10">
        <Lottie options={defaultOptions} height={250} width={250} />
      </div>

      <p className="mx-auto mt-2 mb-5 text-orange-600 text-lg font-bold animate-pulse ">
        Please wait ...
      </p>
    </div>
  );
};

export default LoadingFormLoader;
