import React, { useState, useEffect } from "react";
import axios from "axios";
import { FormGroup, Input } from "../../ui/FormElements/FormElements";
import { FaUserCircle } from "react-icons/fa";
import { EditIcon } from "../../Assets";
import { Modal, Button, List } from "antd";
import { useAuth } from "../../context/authContext";
import { updateAccount } from "../../services/api.service";
import Avatar from "react-avatar";
import LoadingScreen from "../../../pages/AddNewPet/LoadingScreen";
import LoadingScreenWhile from "../../../pages/AddNewPet/LoadingScreenWhile";
import { toast } from "react-toastify";
import { FaArrowCircleLeft } from "react-icons/fa";
import { HiOutlineChevronDown } from "react-icons/hi";
import { clearAccessToken } from "../../services/localstorage";
import ReactSelect from "react-select";
import { Checkbox } from "antd";
import { useNavigate } from "react-router";
import { MODAL_TYPES, useModals } from "../../context/modalContext";
import { FaCircleExclamation } from "react-icons/fa6";
import { IoSettings } from "react-icons/io5";
import { FaCheckCircle } from "react-icons/fa";
const GenderValues = [
  {
    value: "male",
    label: "Male",
  },
  {
    value: "female",
    label: "Female",
  },
  {
    value: "I'd rather not say",
    label: "I'd rather not say",
  },
];
const Profile = () => {
  const ctx = useAuth();
  const modalCtx = useModals();
  const { user, updateUser } = useAuth();
  const [profileData, setProfileData] = useState(null);

  const [hasSubscription, setHasSubscription] = useState(true);
  const [isEmailVerified, setIsEmailVerified] = useState(false);

  const [isVerifyingEmail, setIsVerifyingEmail] = useState(false);

  const [profileUpdateData, setProfileUpdateData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    gender: "",
    dob: "",
    phone: "",
    emailAlertEnabled: false,
    smsAlertEnabled: false,
    profilePicURL: null,
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isPassword, setIsPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [esentialSubsModal, setEsentialSubsModal] = useState(false);
  const [inAppSubsModal, setInAppSubsModal] = useState(false);
  const [fullSubsModal, setFullSubsModal] = useState(false);
  const [noSubsModal, setNoSubsModal] = useState(false);
  const [subscriptionType, setSubscriptionType] = useState(null);

  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);

    return () => {
      window.scrollTo(0, 0);
    };
  }, []);

  const handleDelete = () => {
    setLoading(true);
    if (subscriptionType === "Essential Subscription") {
      setEsentialSubsModal(true);
    } else if (subscriptionType === "In App Subscription") {
      setInAppSubsModal(true);
    } else if (subscriptionType === "Full-Subscription") {
      setFullSubsModal(true);
    } else if (subscriptionType === "No Subscription") {
      setNoSubsModal(true);
    }
  };

  const handleModalOk = () => {
    // Redirect to subscriptions page
    navigate("/pets");
    setIsModalVisible(false);
  };

  const handleEsentialModalCancel = () => {
    setEsentialSubsModal(false);
    setLoading(false);
  };
  const handleInAppModalCancel = () => {
    setInAppSubsModal(false);
    setLoading(false);
  };

  const handFullSubModalCancel = () => {
    setFullSubsModal(false);
    setLoading(false);
  };
  const handleNoSubsModal = () => {
    setNoSubsModal(false);
    setLoading(false);
  };
  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };
  const toggleAccordionPassword = () => {
    setIsPassword(!isPassword);
  };
  const handleAccountDelete = () => {
    axios
      .delete("/users/delete-account")
      .then((response) => {
        ctx.updateUser(null);
        localStorage.clear();
        clearAccessToken();
        navigate("/");
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    axios
      .get("/users/account")
      .then((response) => {
        if (response.data.success) {
          const { isSubscribed, iapSubscription, isEmailVerified } =
            response.data.data;
          setProfileData(response.data.data);
          setIsEmailVerified(isEmailVerified);
          if (isSubscribed && iapSubscription) {
            setSubscriptionType("Full-Subscription");
          } else if (iapSubscription && !isSubscribed) {
            setSubscriptionType("In App Subscription");
          } else if (!iapSubscription && isSubscribed) {
            setSubscriptionType("Essential Subscription");
          } else {
            setSubscriptionType("No Subscription");
          }
          setProfileUpdateData({
            firstName: response.data.data.firstName || "",
            lastName: response.data.data.lastName || "",
            gender: response.data.data.gender || "",
            dob: response.data.data.dob || "",
            phone: response.data.data.phone || "",
            emailAlertEnabled: response.data.data.emailAlertEnabled || false,
            smsAlertEnabled: response.data.data.smsAlertEnabled || false,
          });
        } else {
        }
      })
      .catch((error) => {});
  }, []);

  const validateForm = () => {
    let valid = true;
    const errors = {};

    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;

    if (profileUpdateData.email && !isValidEmail(profileUpdateData.email)) {
      errors.email = "Please enter a valid email address";
      valid = false;
    }

    if (profileUpdateData.phone && !/^\d{10}$/.test(profileUpdateData.phone)) {
      errors.phone = "Please enter a valid 10-digit phone number";
      valid = false;
    }

    setErrors(errors);
    return valid;
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleFileUpload = (e) => {
    const files = e.target.files;
    const file = files[0];

    // Check if file size is within the limit (5MB)
    const maxSizeInBytes = 5 * 1024 * 1024; // 5MB
    if (file.size > maxSizeInBytes) {
      toast.error("File size exceeds 5MB limit. Please upload a smaller file.");
      return;
    }

    const data = new FormData();
    data.append(`image`, file, file.name);

    axios
      .post("/file-upload", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(({ data: res }) => {
        const data = res.data;
        updateAccount({
          profilePicURL: data.location,
        }).then((res) => {
          const user = res.data;
          updateUser(user);
        });
      });
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    let inputValue = value;

    if (name === "dob") {
      const today = new Date();
      const selectedDate = new Date(value);
      if (selectedDate > today) {
        inputValue = "";
        toast.error("Opps , can't select future dates ");
      }
    }

    setProfileUpdateData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSelectChange = (selectedOption, name) => {
    setProfileUpdateData((prevData) => ({
      ...prevData,
      [name]: selectedOption.value,
    }));
  };
  const handleCheckboxChange = (name) => {
    setProfileUpdateData((prevData) => ({
      ...prevData,
      [name]: !prevData[name], // Toggle the value of the checkbox field
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsSubmitting(true);

    const filteredData = Object.fromEntries(
      Object.entries(profileUpdateData).filter(([key, value]) => {
        if (typeof value === "boolean") {
          return value;
        } else if (typeof value === "string") {
          return value.trim() !== "";
        }
        return false;
      })
    );

    axios
      .put("/users/account", filteredData)
      .then((response) => {
        if (response.data.success) {
          toast.success("User profile updated successfully.");
          setProfileData(response.data.data);
        } else {
          toast.error("Error updating user profile:", response.data.message);
          setErrors(response.data.errors || {});
        }
      })
      .catch((error) => {})
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const showChangePasswordModal = () => {
    modalCtx.showModal({
      modalType: MODAL_TYPES.CHANGE_PASSWORD,
      modalData: {},
    });
  };
  const handleEmailVerification = () => {
    setIsVerifyingEmail(true);
    axios

      .post("/users/send-email-verification-link")
      .then((response) => {
        if (response.data.success) {
          // Assume the verification is successful and update the state
          // setIsEmailVerified(true);
          toast.success("Email verification link sent!");
        } else {
        }
      })
      .catch((error) => {})
      .finally(() => {
        setIsVerifyingEmail(false);
      });
  };

  return (
    <>
      <div className="flex bg-white p-8">
        <div className="bg-white p-8  w-full pr-12   ">
          {profileData && (
            <div>
              <FaArrowCircleLeft
                className="text-3xl cursor-pointer hover:scale-95 transition-transform duration-300 ease-in-out text-amber-600"
                onClick={() => navigate(-1)}
              />

              <div className="flex flex-col items-center mb-20">
                <div>
                  <div className="d-block ">
                    <Avatar
                      name={
                        profileUpdateData.firstName +
                        " " +
                        profileUpdateData.lastName
                      }
                      size="100"
                      round={true}
                      src={user.profilePicURL}
                      textSizeRatio={1}
                    />
                  </div>
                  <div className="btn btn-text d-inline-flex gap-2 file-upload-trigger">
                    <span className="fw-bold">Edit Photo</span>
                    <EditIcon />
                    <input
                      type="file"
                      className="file-upload"
                      accept="image/png, image/jpeg"
                      onChange={handleFileUpload}
                    />
                  </div>
                </div>
              </div>

              <form onSubmit={handleSubmit}>
                <div className="grid grid-cols-1 gap-y-4">
                  <div className="flex">
                    <div className="w-1/2 pr-2">
                      <label className="block text-sm font-medium text-gray-900 mb-2">
                        First Name
                      </label>
                      <input
                        type="text"
                        name="firstName"
                        value={profileUpdateData.firstName}
                        onChange={handleInputChange}
                        className={`block w-full rounded-md border-gray-300 focus:ring-amber-500 focus:border-amber-500 sm:text-sm h-10 bg-gray-50 p-4 ${
                          errors.firstName ? "border-red-500" : ""
                        }`}
                      />
                      {errors.firstName && (
                        <p className=" text-red-500 text-sm">
                          {errors.firstName}
                        </p>
                      )}
                    </div>

                    <div className="w-1/2 pl-2">
                      <label className="block text-sm font-medium text-gray-900 mb-2">
                        Last Name
                      </label>
                      <input
                        type="text"
                        name="lastName"
                        value={profileUpdateData.lastName}
                        onChange={handleInputChange}
                        className={`block w-full rounded-md border-gray-300 focus:ring-amber-500 focus:border-amber-500 sm:text-sm h-10 bg-gray-50 p-4 ${
                          errors.lastName ? "border-red-500" : ""
                        }`}
                      />
                      {errors.lastName && (
                        <p className=" text-red-500 text-sm">
                          {errors.lastName}
                        </p>
                      )}
                    </div>
                  </div>
                  <div>
                    <div className="flex justify-between items-center">
                      <label className="block text-sm font-medium text-gray-900 mb-2">
                        Email
                      </label>
                      <button
                        type="button"
                        className={` text-sm font-semibold mr-2 flex gap-1 justify-center items-center flex    ${
                          isEmailVerified ? "text-green-600" : "text-amber-500"
                        } `}
                        onClick={handleEmailVerification}
                        disabled={isVerifyingEmail || isEmailVerified}
                      >
                        {isVerifyingEmail
                          ? "Verifying..."
                          : isEmailVerified
                          ? "Verified"
                          : "Verify Email"}
                        {!isEmailVerified && (
                          <FaCircleExclamation className="text-amber-500 text-xl " />
                        )}
                        {isEmailVerified && (
                          <FaCheckCircle className="text-green-500 text-xl " />
                        )}
                      </button>
                    </div>

                    <div className="flex items-center">
                      <input
                        type="email"
                        name="email"
                        value={profileData.email}
                        disabled
                        onChange={handleInputChange}
                        className={`block w-full rounded-md border-gray-300 focus:ring-amber-500 focus:border-amber-500 sm:text-sm h-10 bg-gray-100 p-4 ${
                          errors.email ? "border-red-500" : ""
                        }`}
                      />
                    </div>
                    {errors.email && (
                      <p className="text-red-500 text-sm">{errors.email}</p>
                    )}
                  </div>

                  <div className="flex items-center">
                    <Checkbox
                      id="emailAlert"
                      name="emailAlertEnabled"
                      checked={profileUpdateData.emailAlertEnabled}
                      onChange={(e) =>
                        handleCheckboxChange(
                          "emailAlertEnabled",
                          e.target.checked
                        )
                      }
                      className="h-4 w-4 text-amber-600 focus:ring-amber-500 border-gray-300 rounded"
                    />

                    <label
                      htmlFor="emailAlert"
                      className="ml-2 block text-xs lg:text-sm text-gray-900"
                    >
                      Use this email to receive EMAIL alerts for urgent or
                      important concerns regarding my account or shipments.
                    </label>
                  </div>
                  <div className="flex">
                    <div className="w-1/2 pr-2">
                      <label className="block text-sm font-medium text-gray-900 mb-2">
                        Date of Birth
                      </label>
                      <Input
                        type="date"
                        name="dob"
                        value={profileUpdateData.dob}
                        max={new Date().toISOString().split("T")[0]}
                        onChange={handleInputChange}
                        // className={`block w-full rounded-md border-gray-300 focus:ring-amber-500 focus:border-amber-500 sm:text-sm h-8 bg-gray-50 p-4 ${
                        //   errors.dob ? "border-red-500" : ""
                        // }`}
                      />
                      {errors.dob && (
                        <p className=" text-red-500 text-sm">{errors.dob}</p>
                      )}
                    </div>
                    {/* <FormGroup>
                    <div>
                      <label htmlFor="dob">Birthdate</label>
                      <Field name="dob">
                        <Input
                          value={user?.dob || ""}
                          type="date"
                          max={new Date().toISOString().split("T")[0]} // Set max attribute to current date
                        />
                      </Field>
                    </div>
                  </FormGroup> */}
                    <div className="w-1/2 pl-2">
                      <label className="block text-sm font-medium text-gray-900 mb-2">
                        Gender
                      </label>
                      <ReactSelect
                        name="gender"
                        value={GenderValues.find(
                          (option) => option.value === profileUpdateData.gender
                        )}
                        onChange={(selectedOption) =>
                          handleSelectChange(selectedOption, "gender")
                        }
                        options={GenderValues}
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            padding: "4px",
                            backgroundColor:
                              "background-color: rgb(249 250 251 / var(--tw-bg-opacity))",
                            boxShadow: state.isFocused
                              ? "0 0 0 1px red"
                              : "none",
                          }),
                        }}
                        className={`block  w-full rounded-md border-gray-300 focus:ring-amber-500 focus:border-amber-500 sm:text-sm h-10 bg-gray-50 ${
                          errors.gender ? "border-red-500" : ""
                        }`}
                      />
                    </div>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-900 mb-2">
                      Phone
                    </label>
                    <input
                      type="text"
                      name="phone"
                      value={profileUpdateData.phone}
                      onChange={handleInputChange}
                      className={`block w-full rounded-md border-gray-300 focus:ring-amber-500 focus:border-amber-500 sm:text-sm h-10 bg-gray-50 p-4 ${
                        errors.phone ? "border-red-500" : ""
                      }`}
                    />
                    {errors.phone && (
                      <p className=" text-red-500 text-sm">{errors.phone}</p>
                    )}
                  </div>
                  <div className="flex items-center">
                    <Checkbox
                      id="smsAlert"
                      name="smsAlertEnabled"
                      checked={profileUpdateData.smsAlertEnabled}
                      onChange={(e) =>
                        handleCheckboxChange(
                          "smsAlertEnabled",
                          e.target.checked
                        )
                      }
                      className="h-4 w-4 text-amber-600 focus:ring-amber-500 border-gray-300 rounded"
                    />
                    <label
                      htmlFor="smsAlert"
                      className="ml-2 block text-xs lg:text-sm text-gray-900"
                    >
                      Use this mobile to receive SMS text alerts for urgent or
                      important concerns regarding my account or shipments.
                    </label>
                  </div>
                </div>
                <div className="flex items-center justify-end mt-6">
                  <button
                    type="submit"
                    className="rounded-md bg-amber-600 px-8 py-3 text-sm font-semibold text-white shadow-sm hover:bg-amber-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-600"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Saving..." : "Update"}
                  </button>
                </div>
              </form>

              <div className="flex mt-4">
                <IoSettings className="text-3xl text-gray-400" />
                <h3 className="mt-1 ml-1 text-gray-500 text-xl">Settings</h3>
              </div>

              <div className="w-full mx-auto bg-white rounded mt-4">
                <button
                  type="button"
                  className="flex items-center justify-between w-full p-3 font-medium text-gray-500 border border-gray-200 rounded-t-xl bg-gray-50"
                  onClick={toggleAccordion}
                >
                  <span>Delete Account?</span>
                  <HiOutlineChevronDown
                    className={`w-5 h-5 ${
                      isOpen ? "transform rotate-180" : ""
                    }`}
                  />
                </button>
                {isOpen && (
                  <div className="p-4 border border-t-0 border-gray-200">
                    <p className="mb-2 text-gray-500">
                      Deleting your account is a permanent action and cannot be
                      undone. This will remove all your data associated with
                      this account, including your profile information,
                      preferences, and any content you have created. Are you
                      absolutely sure you want to proceed with deleting your
                      account?
                    </p>
                    <div className="flex justify-end">
                      <Button
                        type="button"
                        className="bg-red-600 hover:bg-red-700 text-white font-medium  rounded add"
                        loading={loading}
                        onClick={handleDelete}
                      >
                        Delete Account
                      </Button>
                    </div>
                  </div>
                )}
              </div>
              <div className="w-full mx-auto bg-white rounded mt-4">
                <button
                  type="button"
                  className="flex items-center justify-between w-full p-3 font-medium text-gray-500 border border-gray-200 rounded-t-xl bg-gray-50"
                  onClick={toggleAccordionPassword}
                >
                  <span>Change Password</span>
                  <HiOutlineChevronDown
                    className={`w-5 h-5 ${
                      isPassword ? "transform rotate-180" : ""
                    }`}
                  />
                </button>
                {isPassword && (
                  <div className="p-4 border border-t-0 border-gray-200">
                    <p className="mb-2 text-gray-500">
                      To change your password, please enter your current
                      password followed by your new password. Make sure your new
                      password is strong and secure.
                    </p>
                    <div className="flex justify-end">
                      <Button
                        type="button"
                        className="bg-amber-600 hover:bg-amber-700 text-white font-medium  rounded add"
                        loading={loading}
                        onClick={showChangePasswordModal}
                      >
                        Change Password
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}

          {!profileData && <LoadingScreenWhile />}
        </div>
      </div>

      <Modal
        title="Cancel Subscriptions"
        visible={esentialSubsModal}
        onOk={handleModalOk}
        onCancel={handleEsentialModalCancel}
        footer={[
          <button
            onClick={handleModalOk}
            className="bg-amber-500 bg-opacity-20 hover:bg-amber-600 hover:text-black p-2 rounded-lg mr-3"
          >
            Subscriptions
          </button>,
          <button
            key="doLater"
            onClick={handleEsentialModalCancel}
            className="bg-blue-500 bg-opacity-20 hover:bg-blue-400 hover:bg-opacity-20 p-2 rounded-lg "
          >
            Do it later
          </button>,
        ]}
        className="custom-modal"
      >
        <div className="text-center text-gray-800">
          <p className="mb-4 text-lg mt-3">
            You cannot delete your account because you have an active
            subscription.
          </p>
          <p className="text-sm">
            Please cancel your subscriptions to proceed with account deletion.
          </p>
        </div>
      </Modal>

      <Modal
        title="Cancel Subscriptions"
        visible={inAppSubsModal}
        onCancel={handleInAppModalCancel}
        footer={[
          <a
            href="https://play.google.com/store/apps/details?id=com.tailscore"
            className="bg-amber-500 bg-opacity-20 hover:bg-amber-600 hover:text-black p-2 rounded-lg mr-3"
          >
            Subscriptions
          </a>,
          <button
            key="doLater"
            onClick={handleInAppModalCancel}
            className="bg-blue-500 bg-opacity-20 hover:bg-blue-400 hover:bg-opacity-20 p-2 rounded-lg "
          >
            Do it later
          </button>,
        ]}
        className="custom-modal"
      >
        <div className="text-center text-gray-800">
          <p className="mb-4 text-lg mt-3">
            You cannot delete your account because you have{" "}
            <span className=" font-bold text-green-600">
              Digital Dog active subscription.
            </span>
          </p>
          <p className="text-sm">
            Please cancel your subscriptions to proceed with account deletion.
          </p>
        </div>
      </Modal>

      <Modal
        title="Cancel Subscriptions"
        visible={fullSubsModal}
        onCancel={handFullSubModalCancel}
        footer={[
          <button
            onClick={handleModalOk}
            className="bg-amber-500 bg-opacity-20 hover:bg-amber-600 hover:text-black p-2 rounded-lg mr-3"
          >
            Subscriptions
          </button>,
          <button
            key="doLater"
            onClick={handFullSubModalCancel}
            className="bg-blue-500 bg-opacity-20 hover:bg-blue-400 hover:bg-opacity-20 p-2 rounded-lg "
          >
            Do it later
          </button>,
        ]}
        className="custom-modal"
      >
        <div className="text-center text-gray-800">
          <p className="mb-4 text-lg mt-3">
            You cannot delete your account because you have{" "}
            <span className=" font-bold text-green-600">
              active subscriptions.
            </span>
          </p>
          <p className="text-sm">
            Please follow the following steps to proceed with Deletion of your
            account
          </p>

          <List
            size="small"
            className=" font-bold italic"
            bordered
            dataSource={[
              "Step-1 You need to cancel the Essential subscription. ",
              "Step-2 You need to cancel the Digital Dog subscription.",
            ]}
            renderItem={(item) => <List.Item>{item}</List.Item>}
          />
        </div>
      </Modal>

      <Modal
        title="Delete Account"
        visible={noSubsModal}
        onCancel={handleNoSubsModal}
        footer={[
          <button
            onClick={handleAccountDelete}
            className="bg-red-500 bg-opacity-20 hover:bg-red-600 hover:text-black p-2 rounded-lg mr-3"
          >
            Delete
          </button>,
          <button
            key="doLater"
            onClick={handleNoSubsModal}
            className="bg-blue-500 bg-opacity-20 hover:bg-blue-400 hover:bg-opacity-20 p-2 rounded-lg "
          >
            Do it later
          </button>,
        ]}
        className="custom-modal"
      >
        <div className="text-center text-gray-800">
          <p className="mb-4 text-md mt-3 text-left">
            Deleting your account is a permanent action and cannot be undone.
            This will remove all your data associated with this account,
            including your profile information, preferences, and any content you
            have created. Are you absolutely sure you want to proceed with
            deleting your account?
          </p>
          <p className="text-sm text-left italic text-red-400">
            Are you sure you want to delete your account ?
          </p>
        </div>
      </Modal>
    </>
  );
};

export default Profile;
