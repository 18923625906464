import React, { useEffect } from "react";
import Lottie from "react-lottie";
import dogLottie from "../../utils/loading.json";

const LoadingScreenTrans = () => {
  // Specify Lottie options
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: dogLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when component mounts
  }, []);

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-white/15 ">
      <Lottie options={defaultOptions} height={250} width={250} />
      <p className="text-gray-600 mt-4 text-lg font-semibold animate-pulse">
        Loading...
      </p>
    </div>
  );
};

export default LoadingScreenTrans;
