import React, { useState, useEffect, useRef } from "react";
import HomeBanner from "../../components/sections/HomeBanner/HomeBanner";
import AppCtaBanner from "../../components/sections/AppCtaBanner/AppCtaBanner";
import Footer from "../../components/core/Footer/Footer";
import PetDetails from "../../components/sections/PetDetails.jsx/PetDetails";
import HomeBannnerAlt from "../../components/sections/HomeBannnerAlt/HomeBannnerAlt";
import FAQSection from "../../components/sections/FAQSection/FAQSection";
import MealPlanner from "../../components/sections/MealPlanner/MealPlanner";
import NutritionPlan from "../../components/sections/NutritionPlan/NutritionPlan";
import { useSelector, useDispatch } from "react-redux";
import { setEmailVerified, selectIsEmailVerified } from "../../store/authSlice";
import Modal from "../../components/core/Modal/Modal";
import { Modal as AntModal, Button } from "antd";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import OtpInput from "react18-input-otp";
import otplottie from "../../utils/otpanimation.json";
import Lottie from "react-lottie";
import TestHero from "../../components/test-pages/TestHero";
import Testimonials from "../../components/test-pages/Testimonials";
import IntroPage from "../../components/test-pages/IntroPage";
import Pricing from "../MyPlan/Pricing";
import FAQSectionUpdated from "../../components/sections/FAQSection/FAQSectionUpdated";
import NutritionPlanNew from "../../components/sections/NutritionPlan/NutritionPlanNew";
import HeroBannerNew from "../../components/sections/HomeBanner/HeroBannerNew";
import { MdEmail } from "react-icons/md";
import { FaPhone } from "react-icons/fa6";
import { AiTwotoneMail } from "react-icons/ai";

export default function Homepage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isEmailVerified = useSelector(selectIsEmailVerified);
  const [otpModal, setOtpModal] = useState(false);
  const [otp, setOtp] = useState("");
  const [userEmail, setUserEmail] = useState("your email");
  const [userPhone, setUserPhone] = useState("");
  const [resendOtpModal, setResendOtpModal] = useState(false);
  const [introPage, setIntroPage] = useState(true);
  const [scrollModal, setScrollModal] = useState(false);
  const [otpType, setOtpType] = useState("email"); // State to store the OTP type
  const [emailVarModal, setEmailVarModal] = useState(false);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: otplottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const pricingRef = useRef(null);

  useEffect(() => {
    const fetchAccountData = async () => {
      try {
        const accountResponse = await axios.get("/users/account");
        const userData = accountResponse.data.data;

        if (userData.email) {
          setUserEmail(userData.email);
        }
        if (userData.phone) {
          setUserPhone(userData.phone);
        }
        // Check if email is not verified
        if (!userData.isEmailVerified) {
          setOtpModal(true); // Show OTP modal if email is not verified
        } else {
          setOtpModal(false); // Hide OTP modal if email is verified
          dispatch(setEmailVerified(true));
        }
      } catch (error) {}
    };

    const fetchSubscriptionData = async () => {
      try {
        const subscriptionResponse = await axios.get("/users/subscription");
        const subscriptionData = subscriptionResponse.data.data;

        // Save subscription type to localStorage
        if (subscriptionData?.metadata?.version) {
          localStorage.setItem(
            "subscriptionType",
            subscriptionData.metadata.version
          );
        }
      } catch (error) {}
    };

    fetchAccountData();
    fetchSubscriptionData();
  }, [dispatch]);

  const handleOtpSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post("/users/verify-otp", { otp, otpType });

      dispatch(setEmailVerified(true));
      setOtpModal(false);
      setScrollModal(true);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleResendOtp = async (otpType) => {
    setOtpType(otpType); // Set the OTP type for resend
    try {
      await axios.post("/users/resend-otp", { otpType });
      setOtpModal(false);
      setResendOtpModal(true);

      setTimeout(() => {
        setResendOtpModal(false);
        setOtpModal(true);
      }, 5000);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleSendCodeToPhone = () => {
    setOtpType("phone");
    handleResendOtp("phone");
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIntroPage(false);
    }, 3000);

    return () => clearTimeout(timeout);
  }, []);

  const handleScrollToPricing = () => {
    pricingRef.current.scrollIntoView({ behavior: "smooth" });
    setScrollModal(false);
  };

  return (
    <>
      <div>
        <HeroBannerNew />
        <div ref={pricingRef}>
          <Pricing />
        </div>
        <FAQSectionUpdated />
        <MealPlanner />
        <div className="mb-5"> {/* <NutritionPlanNew /> */}</div>
        <div className="app-cta-banner-section mb-0">
          {/* <AppCtaBanner /> */}
        </div>
        <AntModal
          visible={scrollModal}
          onCancel={() => setScrollModal(false)}
          footer={
            <div className="flex  w-full">
              <Button
                key="back"
                onClick={handleScrollToPricing}
                className="rounded-md bg-[#448EBE] text-white hover:bg-[#73a5c6] w-full h-12 "
              >
                Add pet
              </Button>
            </div>
          }
        >
          <div className="flex justify-center items-center text-center pt-10">
            <p className="text-black text-xl">
              <span className="text-[#73a5c6] font-bold italic">
                Thank you for registering with Carson & Bear.
              </span>{" "}
              <p className="w-3/4 text-center mx-auto">
                Please click below to add your pet to the program.
              </p>
            </p>
          </div>
        </AntModal>
        <Modal open={resendOtpModal} closeIcon={false}>
          <div className="flex justify-center flex-col">
            <Lottie options={defaultOptions} height={200} width={200} />
            <div className="mt-4 text-2xl mb-5 font-semibold text-center text-green-600 ">
              Verification code is resent
            </div>
          </div>
        </Modal>
        <Modal open={emailVarModal} closeIcon={false}>
          <div className="flex justify-center flex-col ">
            <div className=" text-2xl  font-semibold text-center text-green-600 ">
              Please verify your email
            </div>
            <AiTwotoneMail className="text-[10rem] text-gray-600 mx-auto mb-4 mt-4" />

            <button className="w-full rounded-lg p-3 text-white font-bold bg-amber-500">
              Verify
            </button>
          </div>
        </Modal>
        <Modal
          open={otpModal}
          closeIcon={false}
          style={{ marginTop: "100px" }}
          type={"otp"}
        >
          <div className=" text-center">
            <div className="mx-auto flex w-full max-w-md flex-col space-y-6">
              <div className="flex flex-col items-center justify-center text-center space-y-2">
                {otpType === "email" && (
                  <div className="font-semibold justify-center  text-3xl flex">
                    <MdEmail className="text-2xl mr-2 mt-1 text-[#448EBE]" />
                    <p className="text-2xl font-semibold text-[#448EBE]">
                      Email Verification
                    </p>
                  </div>
                )}
                {otpType === "phone" && (
                  <div className="font-semibold justify-center  text-3xl flex">
                    <FaPhone className="text-2xl mr-2 mt-1 text-[#448EBE]" />
                    <p className="text-2xl font-semibold text-[#448EBE]">
                      Phone Verification
                    </p>
                  </div>
                )}
                <div className="flex flex-row text-sm font-medium text-[#73a5c6]">
                  {otpType === "email" && (
                    <p>We have sent a code to {userEmail}</p>
                  )}
                  {otpType === "phone" && (
                    <p>We have sent a code to {userPhone}</p>
                  )}
                </div>
              </div>

              <form action="" method="post" onSubmit={handleOtpSubmit}>
                <div className="flex flex-col space-y-6">
                  <div className="flex justify-center">
                    <OtpInput
                      value={otp}
                      onChange={(otp) => setOtp(otp)}
                      inputClassName="otp-input"
                      numInputs={6}
                      separator={<span>-</span>}
                      inputStyle={{
                        border: "1px solid #ced4da",
                        borderRadius: "5px",
                        width: "3rem",
                        height: "3rem",
                        margin: "0 0.25rem",
                        fontSize: "1rem",
                        textAlign: "center",
                      }}
                    />
                  </div>

                  <div className="flex flex-col space-y-5 mt-5">
                    <div>
                      <button
                        className="flex items-center justify-center text-center w-full border rounded-xl text-mg font-semibold outline-none py-4 bg-amber-500 border-none text-white shadow-sm hover:bg-amber-400"
                        type="submit"
                      >
                        Verify Account
                      </button>
                    </div>

                    <div className="flex items-center justify-center text-center text-sm font-medium space-x-1 text-gray-500 mt-4">
                      <p className="text-[#73a5c6]">
                        Didn't receive the code?{" "}
                        <span
                          className="text-amber-600 font-bold"
                          onClick={() => handleResendOtp("email")}
                          style={{ cursor: "pointer" }}
                        >
                          Resend
                        </span>
                      </p>
                    </div>
                    <p className="text-sm text-gray-400 mt-0 mb-3 ">or</p>

                    <div className="flex items-center justify-center text-center text-sm font-medium space-x-1 text-gray-500 mt-0">
                      <p className="flex items-center justify-center text-[#73a5c6]">
                        Receive the code on your{" "}
                        <span
                          className="text-amber-600 font-bold ml-2"
                          onClick={handleSendCodeToPhone}
                          style={{ cursor: "pointer" }}
                        >
                          Phone
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
}
