import React, { useEffect } from "react";
import LoadingScreen from "../../pages/AddNewPet/LoadingScreen";
import { useParams } from "react-router-dom";

const Nomination = () => {
  const { code } = useParams();
  const decodedCode = atob(code);

  useEffect(() => {
    // Save the decoded code in localStorage
    localStorage.setItem("Nomination", decodedCode);

    // Redirect to the route after 2 seconds
    const redirectTimer = setTimeout(() => {
      window.location.href = "/?isOpen=true";
    }, 2000);

    // Clean up the timer on component unmount
    return () => clearTimeout(redirectTimer);
  }, [decodedCode]);

  return (
    <div>
      <LoadingScreen />
    </div>
  );
};

export default Nomination;
