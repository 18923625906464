import React, { Fragment } from "react";
import classNames from "classnames";
import "./FormElements.scss";

export function Input({
  value = "",
  icon,
  placeholder,
  name,
  id,
  type = "text",
  label,
  onChange,
  onBlur,
  error,
  ...props
}) {
  //
  return (
    <div className="input-form-wrapper mb-3">
      <div
        className={classNames("input-group auth-form-field", {
          "has-error": !!error,
        })}
      >
        {icon && <span className="input-group-text">{icon}</span>}
        <input
          // value={value}
          type={type}
          className="form-control"
          placeholder={placeholder}
          name={name}
          id={id}
          onBlur={onBlur}
          onChange={onChange}
          value={value}
          {...props}
        />
      </div>
      <p className="text-sm font-semibold text-red-500">{error}</p>
    </div>
  );
}

export function FormGroup({ children }) {
  return <div className="form-group mb-4">{children}</div>;
}
