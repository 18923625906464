import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    isEmailVerified: false,
  },
  reducers: {
    setEmailVerified: (state, action) => {
      state.isEmailVerified = action.payload;
    },
  },
});

export const { setEmailVerified } = authSlice.actions;
export const selectIsEmailVerified = (state) => state.auth.isEmailVerified;

export default authSlice.reducer;
