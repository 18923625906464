import React from "react";
const testimonials = [
  {
    body: "I recently signed up for Tailscore's Small plan for my beloved pet, and I couldn't be happier. ",
    author: {
      name: "Leslie Alexander",
      handle: "lesliealexander",
      imageUrl:
        "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
  },
  {
    body: " The vet-formulated SuperFoodTM & Salmon Oil provided in daily packets ensure that my pet receives the best nutrition. The customized nutrition plan tailored to my pet's breed-specific needs is impressive. ",
    author: {
      name: "John Doe",
      handle: "johndoe",
      imageUrl: "https://randomuser.me/api/portraits/men/1.jpg",
    },
  },
  {
    body: "Since switching to Tailscore's Large plan for my pet, I've seen a remarkable improvement in their overall health and vitality. ",
    author: {
      name: "Jane Smith",
      handle: "janesmith",
      imageUrl: "https://randomuser.me/api/portraits/women/2.jpg",
    },
  },
  {
    body: "I can't recommend Tailscore's Small plan enough! The vet-formulated SuperFoodTM & Salmon Oil provided in daily packets ensure that my pet receives optimal nutrition every day. ",
    author: {
      name: "Emily Davis",
      handle: "emilydavis",
      imageUrl: "https://randomuser.me/api/portraits/women/4.jpg",
    },
  },
  {
    body: "Choosing Tailscore's Medium plan for my pet was one of the best decisions I've made. The vet-formulated SuperFoodTM & Salmon Oil provided in daily packets ensure that my pet receives optimal nutrition. ",
    author: {
      name: "Michael Johnson",
      handle: "michaeljohnson",
      imageUrl: "https://randomuser.me/api/portraits/men/3.jpg",
    },
  },
  {
    body: "I'm extremely satisfied with Tailscore's Large plan for my pet. The vet-formulated SuperFoodTM & Salmon Oil provided in daily packets ensure that my pet receives the best nutrition. ",
    author: {
      name: "Daniel Brown",
      handle: "danielbrown",
      imageUrl: "https://randomuser.me/api/portraits/men/5.jpg",
    },
  },
  {
    body: "Tailscore's Small plan has exceeded my expectations in every way. The vet-formulated SuperFoodTM & Salmon Oil provided in daily packets ensure that my pet receives the best nutrition. ",
    author: {
      name: "Sarah Wilson",
      handle: "sarahwilson",
      imageUrl: "https://randomuser.me/api/portraits/women/6.jpg",
    },
  },
  {
    body: "I highly recommend Tailscore's Medium plan to all pet owners. The vet-formulated SuperFoodTM & Salmon Oil provided in daily packets ensure that my pet receives optimal nutrition. ",
    author: {
      name: "Christopher Miller",
      handle: "christophermiller",
      imageUrl: "https://randomuser.me/api/portraits/men/7.jpg",
    },
  },
  {
    body: "Since switching to Tailscore's Large plan, my pet's health and vitality have improved significantly. ",
    author: {
      name: "Olivia Thompson",
      handle: "oliviathompson",
      imageUrl: "https://randomuser.me/api/portraits/women/8.jpg",
    },
  },
];

const Testimonials = () => {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-xl text-center">
          <h2 className="text-lg font-semibold leading-8 tracking-tight text-amber-600">
            Testimonials
          </h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            We have worked with thousands of amazing people
          </p>
        </div>
        <div className="mx-auto mt-16 flow-root max-w-2xl sm:mt-20 lg:mx-0 lg:max-w-none">
          <div className="-mt-8 sm:-mx-4 sm:columns-2 sm:text-[0] lg:columns-3">
            {testimonials.map((testimonial) => (
              <div
                key={testimonial.author.handle}
                className="pt-8 sm:inline-block sm:w-full sm:px-4"
              >
                <figure className="rounded-2xl bg-amber-600/10 p-8 text-sm leading-6 shadow-md">
                  <blockquote className="text-gray-900">
                    <p>{`“${testimonial.body}”`}</p>
                  </blockquote>
                  <figcaption className="mt-6 flex items-center gap-x-4">
                    <img
                      className="h-10 w-10 rounded-full bg-gray-50"
                      src={testimonial.author.imageUrl}
                      alt=""
                    />
                    <div>
                      <div className="font-semibold text-gray-900">
                        {testimonial.author.name}
                      </div>
                      <div className="text-gray-600">{`@${testimonial.author.handle}`}</div>
                    </div>
                  </figcaption>
                </figure>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
