import { useState } from "react";
import { Dialog } from "@headlessui/react";
import { motion } from "framer-motion";
import { FaArrowRight } from "react-icons/fa6";
import petsImg from "../../utils/petstwo.jpg";
import { Logo } from "../Assets";
export default function TestHero() {
  return (
    <div className="bg-white">
      <div className="relative isolate pt-14">
        <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ffc67b] to-[#89e1fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
        <div className="py-10 sm:py-32 lg:pb-40">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl text-center">
              <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                CARSON&BEAR™ DOG WELLNESS PROGRAM
              </h1>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                Our research-backed platform makes dog parenting easy with a
                paw-some approach to nutrition, personalized by veterinarians
                for you and your fur baby.
              </p>
              <div className="mt-10 flex items-center justify-center gap-x-6">
                <a
                  href="#"
                  className="rounded-md bg-amber-600 px-5 py-3 text-sm font-semibold text-white shadow-sm hover:bg-amber-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-amber-600"
                >
                  Login
                </a>
                <a
                  href="#"
                  className="rounded-md bg-[#89e1fc] px-5 py-3 text-sm font-semibold text-white shadow-sm hover:bg-amber-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-amber-600"
                >
                  Signup
                </a>
              </div>
            </div>
            <div className="relative mt-16 flow-root sm:mt-24">
              <motion.img
                initial={{ y: +800, opacity: 0 }}
                animate={{ y: 0, opacity: 5 }}
                transition={{ type: "spring", duration: 1 }}
                src={petsImg}
                alt="App screenshot"
                width={2432}
                height={1442}
                className="rounded-md shadow-2xl ring-1 ring-gray-900/10 rounded-t-full w-scree h-full"
              />
              <div className="w-full h-32 filter blur-3xl bg-white absolute opacity-100 top-full"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
