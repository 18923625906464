import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "@reduxjs/toolkit";

const initialState = {
  shouldTriggerAction: false,
  discountTotalAmount: 0,
};

const slice = createSlice({
  name: "trigger",
  initialState,
  reducers: {
    setShouldTriggerAction(state, action) {
      state.shouldTriggerAction = action.payload;
    },
    setDiscountTotalAmount(state, action) {
      state.discountTotalAmount = action.payload;
    },
  },
});

export const { setShouldTriggerAction, setDiscountTotalAmount } = slice.actions;

export default slice.reducer;

export const selectShouldTriggerAction = (state) =>
  state.trigger.shouldTriggerAction;

export const selectDiscountTotalAmount = (state) =>
  state.trigger.discountTotalAmount;

export const selectCombinedState = createSelector(
  [selectShouldTriggerAction, selectDiscountTotalAmount],
  (shouldTriggerAction, discountTotalAmount) => ({
    shouldTriggerAction,
    discountTotalAmount,
  })
);
