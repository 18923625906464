import React, { useState, useEffect } from "react";
import { Modal as AntModal } from "antd";
import Form, { Field } from "rc-field-form";
import { FormGroup, Input } from "../../ui/FormElements/FormElements";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import axios from "axios";
import "./AddNewAddress.scss";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  createAddress,
  getAddressesById,
  updateAddress,
} from "../../services/api.service";
// import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import LoadingScreenTrans from "../../../pages/AddNewPet/LoadingScreenTrans";
import StepsAddress from "../../StepsAddress";

const EditAddressNew = () => {
  const [addressData, setAddressData] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [hasSubscription, setHasSubscription] = useState(true);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [markerPosition, setMarkerPosition] = useState(null);
  const [loading, setLoading] = useState(true);
  const [path, setPath] = useState(null);
  const [petId, setPetId] = useState(null);

  const [searchedAddress, setSearchedAddress] = useState({
    title: "",
    city: "",
    state: "",
    postalCode: "",
    latLng: {
      lat: null,
      lng: null,
    },
    fullAddress: "",
    country: "",
    information: "",
  });

  const [isConfirmAddress, setIsConfirmAddress] = useState(false);
  const [newMap, setNewMap] = useState(false);
  const { id } = useParams();
  const containerStyle = {
    width: "100%", // Change to full width
    height: "300px", // Adjust height as needed
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBItZMSTc-nNwwZw-5923atDsSVi3_K4CM",
  });

  useEffect(() => {
    const planPath = localStorage.getItem("planpath");

    setPath(planPath);
  }, []);
  useEffect(() => {
    const petid = localStorage.getItem("editPetId");

    setPetId(petid);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`/users/addresses/${id}`);
        setAddressData(response.data.data);
        setSearchedAddress({
          title: response.data.data.title,
          city: response.data.data.city,
          state: response.data.data.state,
          postalCode: response.data.data.zipcode,
          latLng: {
            lat: parseFloat(response.data.data.latitude), // Convert latitude to float
            lng: parseFloat(response.data.data.longitude), // Convert longitude to float
          },
          fullAddress: response.data.data.address,
          country: response.data.data.country,
          information: response.data.data.information,
        });
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const fetchSubscriptionData = async () => {
      try {
        const subscriptionResponse = await axios.get("/users/subscription");
        const subscriptionData = subscriptionResponse.data;

        if (
          subscriptionResponse.status === 400 &&
          subscriptionData.messageCode === "CUSTOMER_HAS_NO_SUBSCRIPTION"
        ) {
          setHasSubscription(false);
        } else {
          setHasSubscription(true);
        }
      } catch (error) {
        //         setHasSubscription(false);
      }
    };

    fetchSubscriptionData();
  }, []);

  const handleSelect = async (selectedAddress) => {
    try {
      const results = await geocodeByAddress(selectedAddress);
      const latLng = await getLatLng(results[0]);

      const city =
        results[0].address_components.find((component) =>
          component.types.includes("locality")
        )?.long_name || "";
      const state =
        results[0].address_components.find((component) =>
          component.types.includes("administrative_area_level_1")
        )?.long_name || "";
      const postalCode =
        results[0].address_components.find((component) =>
          component.types.includes("postal_code")
        )?.long_name || "";
      const country =
        results[0].address_components.find((component) =>
          component.types.includes("country")
        )?.long_name || "";

      setSearchedAddress({
        city,
        state,
        postalCode,
        latLng,
        fullAddress: selectedAddress,
        country, // Add the country property
      });

      localStorage.setItem("latitude", latLng.lat);
      localStorage.setItem("longitude", latLng.lng);

      form.setFieldsValue({
        title: "",
        address: selectedAddress,
        state,
        city,
        zipcode: postalCode,
        information: "",
        latitude: latLng.lat,
        longitude: latLng.lng,
        country, // Add the country property
      });
      // setNewMap(true);
      window.scrollTo({
        top: document.body.scrollHeight / 3,
        behavior: "smooth", // Optional smooth scrolling behavior
      });
    } catch (error) {}
  };

  const handleSubmitAgain = async () => {
    const planPath = localStorage.getItem("planPath");
    try {
      setLoading(true);

      const body = {
        title: searchedAddress.title ? searchedAddress.title.trim() : null,
        address: searchedAddress.fullAddress
          ? searchedAddress.fullAddress.trim()
          : null,
        state: searchedAddress.state ? searchedAddress.state.trim() : null,
        city: searchedAddress.city ? searchedAddress.city.trim() : null,
        zipcode: searchedAddress.postalCode,
        latitude: searchedAddress.latitude
          ? searchedAddress.latitude.toString()
          : null,
        longitude: searchedAddress.longitude
          ? searchedAddress.longitude.toString()
          : null,
        isVerified: false, // Added isVerified property with value false
      };

      // Remove null or empty properties from the payload
      Object.keys(body).forEach(
        (key) => body[key] === null && delete body[key]
      );

      await axios.put(`/users/addresses/${id}`, body);

      toast.success("Address updated successfully!");
      const editWrongAddress = localStorage.getItem("editWrongAddress");
      if (editWrongAddress === "true") {
        // Mark the updated address as default
        await axios.put(`/users/addresses/markDefault/${id}`);

        // Remove editWrongAddress flag from local storage
        localStorage.removeItem("editWrongAddress");

        // Redirect to planPath from local storage
      }
      if (path) {
        navigate(path);
      } else if (petId) {
        navigate("/addresses");
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
        navigate("/addresses");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    const planPath = localStorage.getItem("planPath");
    try {
      setLoading(true);

      // Verification logic here
      const verificationResponse = await axios.get(
        "/users/addresses/validation/verification",
        {
          params: {
            streetAddress: searchedAddress.fullAddress,
            city: searchedAddress.city,
            state: searchedAddress.state,
            zipcode: searchedAddress.postalCode,
          },
        }
      );

      const { verificationStatus, correctedAddress } =
        verificationResponse.data.data;

      if (verificationStatus === "UNKNOWN_ADDRESS") {
        setIsModalVisible(true);
        return;
      } else if (
        verificationStatus === "CORRECTED_ADDRESS" &&
        correctedAddress
      ) {
        // Update searchedAddress with correctedAddress
        Object.assign(searchedAddress, correctedAddress);
      }

      const body = {
        title: searchedAddress.title ? searchedAddress.title.trim() : null,
        address: searchedAddress.fullAddress
          ? searchedAddress.streetAddress.trim()
          : null,
        state: searchedAddress.state ? searchedAddress.state.trim() : null,
        city: searchedAddress.city ? searchedAddress.city.trim() : null,
        zipcode: searchedAddress.postalCode,
        latitude: searchedAddress.latitude
          ? searchedAddress.latitude.toString()
          : null,
        longitude: searchedAddress.longitude
          ? searchedAddress.longitude.toString()
          : null,
        isVerified: true,
      };

      // Remove null or empty properties from the payload
      Object.keys(body).forEach(
        (key) => body[key] === null && delete body[key]
      );

      await axios.put(`/users/addresses/${id}`, body);

      toast.success("Address updated successfully!");
      const editWrongAddress = localStorage.getItem("editWrongAddress");
      if (editWrongAddress === "true") {
        await axios.put(`/users/addresses/markDefault/${id}`);

        localStorage.removeItem("editWrongAddress");
      }

      if (path) {
        navigate(path);
      } else if (petId) {
        navigate("/addresses");
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
        navigate("/addresses");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleMarkerDragEnd = (e) => {
    const newPosition = {
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    };
    setMarkerPosition(newPosition);

    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: newPosition }, (results, status) => {
      if (status === "OK") {
        if (results[0]) {
          const newAddress = results[0].formatted_address;

          const addressComponents = results[0].address_components;
          const newCity =
            addressComponents.find((component) =>
              component.types.includes("locality")
            )?.long_name || "";
          const newState =
            addressComponents.find((component) =>
              component.types.includes("administrative_area_level_1")
            )?.long_name || "";
          const newPostalCode =
            addressComponents.find((component) =>
              component.types.includes("postal_code")
            )?.long_name || "";

          form.setFieldsValue({
            address: newAddress,
            city: newCity,
            state: newState,
            zipcode: newPostalCode,
          });

          setSearchedAddress((prevState) => ({
            ...prevState,
            fullAddress: newAddress,
            city: newCity,
            state: newState,
            postalCode: newPostalCode,
            latLng: newPosition,
          }));
        } else {
        }
      } else {
      }
    });
  };
  return (
    <>
      {loading ? (
        <LoadingScreenTrans />
      ) : (
        <div className="">
          {loading ? (
            <LoadingScreenTrans />
          ) : (
            <div className="w-full ">
              <div className="col mt-5 mb-4">
                <div className="col-md-12 text-center mt-20">
                  <h2 className="font-bold text-3xl">Edit Address</h2>
                </div>

                <div className="w-10/12 md:w-8/12 lg:w-8/12 h-fit border-2 border-gray-700 rounded-lg mx-auto mt-10">
                  <div className="w-full px-5 mt-5">
                    {" "}
                    <p className="text-md ">
                      <span className="text-xl font-bold ">PLEASE NOTE: </span>
                      If setting up multiple pets that live at different
                      addresses, please complete your sign-up process and
                      pay. Next, go to the{" "}
                      <span className="text-lg text-sky-500">
                        Pet Details section and add or change shipping addresses
                        per pet.
                      </span>
                    </p>
                    <p className="text-xl">
                      Please only add your billing address during sign-up.
                    </p>
                  </div>
                  <div className="px-5 mt-5">
                    <PlacesAutocomplete
                      value={searchedAddress.fullAddress}
                      onChange={(value) => {
                        setSearchedAddress({
                          ...searchedAddress,
                          fullAddress: value,
                        });
                        setNewMap(true);
                      }}
                      onSelect={handleSelect}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div className="flex flex-col">
                          <label
                            htmlFor="searchAddress"
                            className="font-semibold mb-2"
                          >
                            Search Address
                          </label>
                          <input
                            style={{ height: "3rem" }}
                            className="w-full border-none outline-none rounded-full bg-amber-600/5 px-4 py-2 mr-4 mb-4 md:mr-0 md:mb-0"
                            {...getInputProps({
                              placeholder: "Search for an address...",
                            })}
                          />

                          <div
                            className="autocomplete-dropdown-container"
                            style={{
                              backgroundColor: "rgba(290, 255, 255, 0.9)",
                              padding: "10px",
                            }}
                          >
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion) => {
                              const className = suggestion.active
                                ? "suggestion-item--active"
                                : "suggestion-item";
                              return (
                                <div
                                  style={{
                                    marginBottom: "20px",
                                    cursor: "pointer",
                                  }}
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                  })}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </div>

                  <div className="col pt-10 pl-5 md:pl-10 lg:pl-20">
                    <div className="col-md-11">
                      <div className="mb-4">
                        <GoogleMap
                          mapContainerStyle={{
                            width: "100%",
                            height: "300px",
                          }}
                          center={{
                            lat: searchedAddress.latLng.lat,
                            lng: searchedAddress.latLng.lng,
                          }}
                          zoom={13}
                        >
                          {searchedAddress.latLng.lat && (
                            <Marker
                              position={
                                {
                                  lat: searchedAddress.latLng.lat,
                                  lng: searchedAddress.latLng.lng,
                                } || markerPosition
                              }
                              draggable={true}
                              onDragEnd={handleMarkerDragEnd}
                            />
                          )}
                        </GoogleMap>
                      </div>

                      <Form
                        className="create-form"
                        // onFinish={handleSubmit}
                        form={form}
                        initialValues={{
                          title: searchedAddress?.title,
                          address: searchedAddress?.fullAddress,
                          state: searchedAddress?.state,
                          city: searchedAddress?.city,
                          zipcode: searchedAddress?.postalCode,
                          information: searchedAddress?.information,
                        }}
                        onValuesChange={(changedValues, allValues) => {
                          setSearchedAddress((prevAddress) => ({
                            ...prevAddress,
                            title: allValues.title,
                            fullAddress: allValues.address,
                            state: allValues.state,
                            city: allValues.city,
                            postalCode: allValues.zipcode,
                            information: allValues.information,
                          }));
                        }}
                      >
                        {(values, form) => (
                          <div>
                            <div className="col">
                              <div className="w-full">
                                <FormGroup>
                                  <label htmlFor="firstName">
                                    Title{" "}
                                    <span className="text-black  text-xs  font-bold">
                                      {" "}
                                      (Optional)
                                    </span>
                                  </label>
                                  <Field name="title">
                                    <Input
                                      className="w-full"
                                      error={form.getFieldError("title")[0]}
                                    />
                                  </Field>
                                </FormGroup>
                              </div>
                              <div className="col-md-12">
                                <FormGroup>
                                  <label htmlFor="address">Address</label>
                                  <span className="text-red-600  text-xs italic font-bold">
                                    {" "}
                                    (*Required )
                                  </span>
                                  <Field
                                    name="address"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please input address",
                                      },
                                    ]}
                                  >
                                    <Input
                                      className="w-full"
                                      error={form.getFieldError("address")[0]}
                                    />
                                  </Field>
                                </FormGroup>
                              </div>

                              <div className="col-md-12">
                                <FormGroup>
                                  <label htmlFor="state">State</label>
                                  <span className="text-red-600  text-xs italic font-bold">
                                    {" "}
                                    (*Required )
                                  </span>
                                  <Field
                                    name="state"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please input state",
                                      },
                                    ]}
                                  >
                                    <Input
                                      className="w-full"
                                      error={form.getFieldError("state")[0]}
                                    />
                                  </Field>
                                </FormGroup>
                              </div>

                              <div className="col-md-12">
                                <FormGroup>
                                  <label htmlFor="city">City</label>
                                  <span className="text-red-600  text-xs italic font-bold">
                                    {" "}
                                    (*Required )
                                  </span>
                                  <Field
                                    name="city"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please input city",
                                      },
                                    ]}
                                  >
                                    <Input
                                      className="w-full"
                                      error={form.getFieldError("city")[0]}
                                    />
                                  </Field>
                                </FormGroup>
                              </div>

                              <div className="col-md-12">
                                <FormGroup>
                                  <label htmlFor="zipcode">Zip Code</label>
                                  <span className="text-red-600  text-xs italic font-bold">
                                    {" "}
                                    (*Required )
                                  </span>
                                  <Field
                                    name="zipcode"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please input zipcode",
                                      },
                                    ]}
                                  >
                                    <Input
                                      className="w-full"
                                      error={form.getFieldError("zipcode")[0]}
                                    />
                                  </Field>
                                </FormGroup>
                              </div>

                              <div className="col-md-12">
                                <FormGroup>
                                  <label htmlFor="information">
                                    Additional Information{" "}
                                    <span className="text-black  text-xs  font-bold">
                                      {" "}
                                      (Optional)
                                    </span>
                                  </label>
                                  <Field name="information">
                                    <Input
                                      className="w-full"
                                      error={
                                        form.getFieldError("information")[0]
                                      }
                                    />
                                  </Field>
                                </FormGroup>
                              </div>
                            </div>
                          </div>
                        )}
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="w-12/12 lg:w-8/12 h-fit flex mt-5 justify-between items-end mx-auto">
            <div className="ml-auto">
              <button
                className="bg-[#E68F00] hover:bg-[#ffb031] text-white font-bold py-2 px-4 rounded-md mr-2"
                onClick={handleSubmit}
              >
                Update Address
              </button>
              <button className="bg-amber-400/10 hover:bg-amber-400/5 text-[#E68F00] font-bold py-2 px-4 rounded-md border-2 border-[#E68F00]">
                <Link to="/addresses">Cancel</Link>
              </button>
            </div>
          </div>
        </div>
      )}
      <AntModal
        title="Unknown Address"
        visible={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false);
        }}
        footer={[
          <button
            key="edit"
            className="edit-button border-0 px-5 w-36 h-10 bg-amber-600 rounded-full text-white"
            onClick={() => {
              setIsModalVisible(false);
            }}
          >
            Edit
          </button>,
          <button
            key="continue"
            className="continue-button border-0 px-5 w-36 h-10 bg-[#448EBE] rounded-full ml-2 text-white"
            onClick={handleSubmitAgain}
          >
            Continue
          </button>,
        ]}
      >
        <p>Your address is not verified. Do you want to edit or proceed?</p>
      </AntModal>
    </>
  );
};

export default EditAddressNew;
