import React, { useState } from "react";
import { Form, Input, Button } from "antd";
import { useSelector, useDispatch } from "react-redux";
import {
  updateFormData,
  selectSuggestedVetData,
} from "../../store/suggestedVetSlice";
import SubmitAnimation from "./SubmitAnnimation";

const SuggestedVetForm = ({ onClose }) => {
  const dispatch = useDispatch();
  const suggestedVetData = useSelector(selectSuggestedVetData);
  const [animation, setAnimation] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false); // Track form validity
  const [form] = Form.useForm(); // Using AntD Form instance to manually trigger validation

  const [formData, setFormData] = useState({
    clinicName: "",
    vetName: "",
    address: "",
    phoneNumber: "",
    email: "",
  });

  const handleSubmit = (values) => {
    dispatch(updateFormData(values));
    // Clear the form after submission
    setFormData({
      clinicName: "",
      vetName: "",
      address: "",
      phoneNumber: "",
      email: "",
    });
    setAnimation(true);
    setTimeout(() => {
      onClose();
    }, 2000);
  };

  // Handle form changes and update the validation state
  const handleFieldsChange = (_, allFields) => {
    const hasErrors = allFields.some((field) => field.errors.length > 0);
    setIsFormValid(!hasErrors); // If no errors, form is valid
  };

  return (
    <div className="m-4">
      {animation ? (
        <SubmitAnimation />
      ) : (
        <Form
          layout="vertical"
          onFinish={handleSubmit}
          form={form} // Bind the form instance for manual control
          onFieldsChange={handleFieldsChange} // Handle validation changes
        >
          <Form.Item
            label="Clinic Name"
            name="clinicName"
            rules={[
              { required: true, message: "Please enter the clinic name." },
              {
                max: 100,
                message: "Clinic name must be at most 100 characters.",
              },
              {
                pattern: /^[a-zA-Z0-9\s'-]*$/,
                message: "Clinic name cannot contain special characters.",
              },
            ]}
          >
            <Input
              value={formData.clinicName}
              onChange={(e) =>
                setFormData({ ...formData, clinicName: e.target.value })
              }
            />
          </Form.Item>

          <Form.Item
            label="Vet Name"
            name="vetName"
            rules={[
              { required: true, message: "Please enter the vet name." },
              { max: 30, message: "Vet name must be at most 30 characters." },
              {
                pattern: /^[a-zA-Z0-9\s'-]*$/,
                message: "Vet name cannot contain special characters.",
              },
            ]}
          >
            <Input
              value={formData.vetName}
              onChange={(e) =>
                setFormData({ ...formData, vetName: e.target.value })
              }
            />
          </Form.Item>

          <Form.Item
            label="Address"
            name="address"
            rules={[
              { required: true, message: "Please enter the address." },
              { max: 100, message: "Address must be at most 100 characters." },
            ]}
          >
            <Input
              value={formData.address}
              onChange={(e) =>
                setFormData({ ...formData, address: e.target.value })
              }
            />
          </Form.Item>

          <Form.Item
            label="Phone Number"
            name="phoneNumber"
            rules={[
              { required: true, message: "Please enter the phone number." },
              {
                pattern: /^[0-9]*$/,
                message: "Phone number can only contain numbers.",
              },
            ]}
          >
            <Input
              value={formData.phoneNumber}
              onChange={(e) =>
                setFormData({ ...formData, phoneNumber: e.target.value })
              }
            />
          </Form.Item>

          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: "Please enter your email." },
              { type: "email", message: "Please enter a valid email." },
            ]}
          >
            <Input
              type="email"
              value={formData.email}
              onChange={(e) =>
                setFormData({ ...formData, email: e.target.value })
              }
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="w-full bg-amber-500 hover:bg-amber-300 hover:text-white hover:border-0 p-2 rounded-lg font-bold text-lg h-12"
              disabled={!isFormValid} // Disable button if form is not valid
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      )}
    </div>
  );
};

export default SuggestedVetForm;
