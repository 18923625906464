import logo from "./logo.svg";
import "./App.scss";
import Header from "./components/core/Header/Header";
import HeaderAlt from "./components/core/HeaderAlt/HeaderAlt";
import HomeBanner from "./components/sections/HomeBanner/HomeBanner";
import { Route, Router, Routes, useLocation, Redirect } from "react-router-dom";
import {
  CSSTransition,
  SwitchTransition,
  TransitionGroup,
} from "react-transition-group";
// import { Switch, Route, useLocation } from 'react-router-dom';
import Homepage from "./pages/Homepage/Homepage";
import Footer from "./components/core/Footer/Footer";
import FooterAlt from "./components/core/FooterAlt/FooterAlt";
import ModalConfigWrapper from "./components/modals/ModalConfigWrapper";
import PetDetails from "./pages/PetDetails/PetDetails";
import MyPlan from "./pages/MyPlan/MyPlan";
import AddressList from "./components/sections/AddressList/AddressList";
import AddNewAddress from "./components/sections/AddNewAddress/AddNewAddress";
import AddNewPet from "./pages/AddNewPet/AddNewPet";
import Checkout from "./pages/Checkout/Checkout";
import CheckoutSuccess from "./pages/CheckoutSuccess/CheckoutSuccess";
import HomeBannnerAlt from "./components/sections/HomeBannnerAlt/HomeBannnerAlt";
import NutritionPlan from "./components/sections/NutritionPlan/NutritionPlan";
import MealPlanner from "./components/sections/MealPlanner/MealPlanner";
import FAQSection from "./components/sections/FAQSection/FAQSection";
import CheckoutTest from "./pages/stripeCheckout/checkoutTest";
import CheckoutSuccessPayment from "./pages/stripeCheckout/checkoutPayment";
import EditProfilePage from "./components/modals/EditProfileModal/EditProfilePage";
import ResetPassword from "./components/forget-password/ResetPassword";
import PrivacyPolicyPage from "./pages/Checkout/PrivacyPolicyPage";
import MyPlansDropdown from "./pages/MyPlan/MyPlansDropdown";
import LoadingScreen from "./pages/AddNewPet/LoadingScreen";
import NewHeader from "./components/core/HeaderAlt/NewHeader";
import Nomination from "./components/nomination-flow/Nomination";
import Profile from "./components/modals/EditProfileModal/Profile";
import { Dialog } from "@headlessui/react";
import TaxFail from "./pages/Checkout/TaxFail";
import Example from "./pages/Checkout/Example";
import NewCheckout from "./pages/Checkout/NewCheckout";
import Pricing from "./pages/MyPlan/Pricing";
import Testimonials from "./components/test-pages/Testimonials";
import TestHero from "./components/test-pages/TestHero";
import IntroPage from "./components/test-pages/IntroPage";
import PricingNew from "./pages/MyPlan/PricingNew";
import MyPlansNew from "./pages/MyPlan/MyPlansNew";
import EditAddress from "./components/sections/AddNewAddress/EditAddress";
import EditAddressNew from "./components/sections/AddNewAddress/EditAddressNew";
import Invoice from "./pages/Checkout/Invoice";
import SelectCard from "./components/ui/AddNewCard/SelectCard";
import CheckoutSuccessTwo from "./pages/CheckoutSuccess/CheckoutSuccessTwo";
import { EmailAddressIcon } from "./components/Assets";
import EmailVerification from "./components/modals/EditProfileModal/EmailVerification";
import { useEffect } from "react";

function App() {
  const location = useLocation();
  useEffect(() => {
    const intervalId = setInterval(() => {
      console.clear(); // Clears the console every 500 milliseconds
    }, 300);

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  const isResetPasswordPage = location.pathname
    .toLowerCase()
    .includes("passwordresettoken");

  if (isResetPasswordPage) {
    return <ResetPassword />;
  }
  const isPrivacyPolicyPage = location.pathname
    .toLowerCase()
    .includes("privacy-policy");

  if (isPrivacyPolicyPage) {
    return <PrivacyPolicyPage />;
  }
  const isloading = location.pathname.toLowerCase().includes("load");

  if (isloading) {
    return <LoadingScreen />;
  }
  const isNewPet = location.pathname.toLowerCase().includes("/pets/add");

  if (isNewPet) {
    return <AddNewPet />;
  }
  const isTax = location.pathname.toLowerCase().includes("/taxFail");

  if (isTax) {
    return <TaxFail />;
  }

  const istestimonials = location.pathname
    .toLowerCase()
    .includes("/testimonials");

  if (istestimonials) {
    return <Testimonials />;
  }
  const ishero = location.pathname.toLowerCase().includes("/testhero");

  if (ishero) {
    return <TestHero />;
  }
  const isIntro = location.pathname.toLowerCase().includes("/intro");

  if (isIntro) {
    return <IntroPage />;
  }
  const invoice = location.pathname.toLowerCase().includes("/invoice");

  if (invoice) {
    return <Invoice />;
  }
  // const isNomination = location.pathname
  //   .toLowerCase()
  //   .includes("vet-nomination");

  // if (isNomination) {
  //   return <Nomination />;
  // }
  return (
    <>
      <Routes>
        <Route path="/stripe-redirect-checkout" element={<CheckoutTest />} />
      </Routes>
      <Routes>
        <Route path="/intro" element={<IntroPage />} />
      </Routes>
      <Routes>
        <Route path="/passwordResetToken/:token" element={<ResetPassword />} />
      </Routes>

      <Routes>
        <Route path="load" element={<LoadingScreen />} />
      </Routes>
      <Routes>
        <Route path="/pets/add" element={<AddNewPet />} />
      </Routes>
      <Routes>
        <Route path="/taxFail" element={<TaxFail />} />
      </Routes>

      <Routes>
        <Route path="/new" element={<NewCheckout />} />
      </Routes>

      <Routes>
        <Route path="/testimonials" element={<Testimonials />} />
      </Routes>
      <Routes>
        <Route path="/testhero" element={<TestHero />} />
      </Routes>
      <Routes>
        <Route path="/invoice" element={<Invoice />} />
      </Routes>
      <div>
        <NewHeader />
        <div className="page-content">
          <Routes location={location}>
            <Route path="/" element={<Homepage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
            <Route path="select-card" element={<SelectCard />} />
            <Route path="/user/new" element={<EditProfilePage />} />
            <Route path="/user" element={<Profile />} />
            <Route path="/pets" element={<PetDetails />} />
            <Route path="/addresses" element={<AddressList />} />
            <Route path="/vet-nomination/:code" element={<Nomination />} />
            <Route path="/myplans" element={<PricingNew />} />
            <Route path="/addresses/update/:id" element={<EditAddress />} />
            <Route path="/addresses/edit/:id" element={<EditAddressNew />} />
            <Route path="/addresses/new" element={<AddNewAddress />} />
            <Route
              path="/emailVerificationToken/:token"
              element={<EmailVerification />}
            />
            {/* <Route path="/myplans" element={<MyPlan />} /> */}
            {/* <Route path="/plans" element={<MyPlansDropdown />} /> */}
            <Route path="/plans" element={<MyPlansNew />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route
              path="/checkout/success"
              element={<CheckoutSuccess />}
            />{" "}
            <Route path="example" element={<Example />} />
            <Route
              path="/checkout/success/new"
              element={<CheckoutSuccessTwo />}
            />
            <Route
              path="/checkoutPayment/success"
              element={<CheckoutSuccessPayment />}
            />
          </Routes>
          <ModalConfigWrapper />
        </div>
        <div>{/* <HomeBannnerAlt /> */}</div>

        <div className="footer-section">
          <FooterAlt />
        </div>
      </div>
    </>
  );
}

export default App;
