import classNames from "classnames";
import React from "react";

export default function Page({ title, className, children }) {
  return (
    <div className={classNames("core-page", className)}>
      <div className="container">
        <div className="row mt-5">
          <div className="col-md-12 text-center">
            <h2 className="fs-2 mt-5 font-bold">{title}</h2>
          </div>
        </div>
      </div>
      <div className="page-wrapper">{children}</div>
    </div>
  );
}
