const TOKEN_KEY = 'BEARCARSON_TOKEN';

export const getAccessToken = () => {
  return localStorage.getItem(TOKEN_KEY);
};

export const setAccessToken = (token) => {
  return localStorage.setItem(TOKEN_KEY, token);
};

export const clearAccessToken = () => {
  return localStorage.clear(TOKEN_KEY);
};

export const saveUserToLocalstorage = (user) => {
  return localStorage.setItem('user', JSON.stringify(user));
};

export const getUserFromLocalstorage = () => {
  let userData = localStorage.getItem('user');
  if(userData){

    return JSON.parse(userData);
  }
  return  ""
};

export const clearUserFromLocalstorage = () => {
  return localStorage.removeItem('user');
};