import React, { useEffect, useState, useRef } from "react";
import { motion } from "framer-motion";
import Lottie from "react-lottie";
import axios from "../../components/services/axios";
import { initSubscription } from "../../components/services/api.service";
import { Button, Tag } from "antd";
import { AiOutlinePlus } from "react-icons/ai";
import { GiCancel } from "react-icons/gi";
import { MdCancel } from "react-icons/md";
import { FaArrowCircleLeft } from "react-icons/fa";
import { BsFileEarmarkPerson } from "react-icons/bs";
import { Tooltip } from "react-tooltip";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { FaLock } from "react-icons/fa";
import dogBreeds from "./dogBreeds.json";
import Page from "../../components/core/Page/Page";
import Form, { Field } from "rc-field-form";
import {
  FormGroup,
  Input,
} from "../../components/ui/FormElements/FormElements";
import Select from "react-select";
import "./AddNewPet.scss";
import {
  createPet,
  getAddresses,
  getPetBreeds,
} from "../../components/services/api.service";
import { useQuery } from "react-query";
import Modal from "../../components/core/Modal/Modal";
import { AddIcon, EditIcon } from "../../components/Assets";
import Avatar from "react-avatar";
import SingleAddressPet from "../../components/ui/FormElements/SingleAddress/SingleAddressPet";
import { getAddressLine } from "../../utils";
import { toast } from "react-toastify";
import { FaCheck } from "react-icons/fa";
import { useAuth } from "../../components/context/authContext";
import { FaInfoCircle } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import {
  updateFormData,
  selectSuggestedVetData,
} from "../../store/suggestedVetSlice";
const GenderValues = [
  {
    value: "male",
    label: "Male",
  },
  {
    value: "female",
    label: "Female",
  },
];
import animationData from "../../utils/address-lottie.json";
import SingleBillingAddress from "../../components/ui/FormElements/SingleAddress/SingleBillingAddress";
import InvitedVet from "./InvitedVet";
import SuggestedVetForm from "./SuggestedVetForm";
import SuggestVetInfo from "./SuggestVetInfo";
import LoadingScreenTrans from "./LoadingScreenTrans";
import StepsAddPet from "../../components/StepsAddPet";
import LoadingScreen from "./LoadingScreen";

export default function AddNewPet() {
  const [NoEdit, setNoEditModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inputVetName, setInputVetName] = useState("");
  const [checkoutloading, setcheckoutloading] = useState(false);
  const suggestedVetData = useSelector(selectSuggestedVetData);
  const [inputValue, setInputValue] = useState("");
  const navigate = useNavigate();
  const [isLoadingScreen, setIsLoadingScreen] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [addressesBilling, setAddressesBilling] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const plan = searchParams.get("plan");
  const [selectedAddress, setSelectedAddress] = React.useState(null);

  const [isCorrectAddressModal, setIsCorrectAddressModal] = useState(false);

  //when selected address is not == null
  const [isPetPresent, setIsPetPresent] = useState(false);
  const [secondPayload, setSecondPayload] = useState(null);

  const [selectedAddressId, setSelectedAddressId] = useState(null);
  const [selectedAddressBilling, setSelectedAddressBilling] = useState(null);
  const [shipmentType, setShipmentType] = useState("monthly");

  const [selectedBillingAddress, setSelectedBillingAddress] =
    React.useState(null);

  const [petForm, showPetForm] = useState(false);

  const [showSelectedAddressModal, setShowSelectedAddressModal] =
    React.useState(false);

  const [showSelectedAddressModalBilling, setShowSelectedAddressModalBilling] =
    React.useState(false);
  const [form] = Form.useForm();
  const [showNoAddressesModal, setShowNoAddressesModal] = useState(false);

  const { data: breeds = [] } = useQuery("breeds", () => getPetBreeds());
  const { data: addresses = [] } = useQuery("addresses", () => getAddresses());

  const [addedPopup, setAddedPopup] = useState(false);

  const breedOptions = breeds.map((b) => ({
    value: b.name,
    label: b.name,
  }));

  const [errors, setErrors] = useState({});
  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
  };

  const [formData, setFormData] = useState({
    clinicName: "",
    clinicPhoneNumber: "",
    clinicAddress: "",
    vetName: "",
  });

  const [clinicSuggestions, setClinicSuggestions] = useState([]);

  //

  const [currentPage, setCurrentPage] = useState(1);
  const [hasMoreSuggestions, setHasMoreSuggestions] = useState(true);
  const [vetNameSuggestions, setVetNameSuggestions] = useState([]);
  const [selectedVetName, setSelectedVetName] = useState("");

  const [dunsNos, setDunsNo] = useState(null);
  const [planId, setPlanId] = useState(null);
  const [vspInfo, setVspInfo] = useState({
    invitedClinicName: "",
    invitedClinicNumber: "",
    invitedDUNSNumber: "",
    invitedPrimaryAddress: "",
    invitedVet: "",
  });

  const [vetInviteId, setVetInviteId] = useState(null);
  const [newInvitedVet, setNewInvitedVet] = useState(false);
  const [isInfoModalVisible, setIsInfoModalVisible] = useState(false);
  const [suggestedVetForm, setSugestedVetForm] = useState(false);
  const [hasSubscription, setHasSubscription] = useState(true);

  const [subscriptionDates, setSubscriptionDates] = useState({
    subscriptionCreatedAt: null,
    subscriptionPausedAt: null,
  });
  useEffect(() => {
    const user = localStorage.getItem("user"); // Adjust key according to how you store user data
    if (!user) {
      navigate("/"); // Redirect to homepage if user is not logged in
    }
  }, [navigate]);
  useEffect(() => {
    const fetchSubscriptionData = async () => {
      try {
        const subscriptionResponse = await axios.get("/users/subscription");
        const subscriptionData = subscriptionResponse.data;

        if (
          subscriptionResponse.status === 400 &&
          subscriptionData.messageCode === "CUSTOMER_HAS_NO_SUBSCRIPTION"
        ) {
          setHasSubscription(false);
        } else {
          setHasSubscription(true);
        }
      } catch (error) {
        // Handle error and set hasSubscription to false
        setHasSubscription(false);
      }
    };

    fetchSubscriptionData();
  }, []);
  const handleSuggestedVetFormOpen = () => {
    setSugestedVetForm(true);
  };
  const handleSuggestedVetFormClose = () => {
    setSugestedVetForm(false);
  };
  const showInfoModal = () => {
    setIsInfoModalVisible(true);
  };

  const handleInfoModalOk = () => {
    setIsInfoModalVisible(false);
  };

  const handleInfoModalCancel = () => {
    setIsInfoModalVisible(false);
  };
  useEffect(() => {
    // Check if invitedByVet is present in local storage
    const invitedByVet = localStorage.getItem("invitedByVet");
    if (invitedByVet) {
      setVetInviteId(invitedByVet);
    }
  }, []);

  useEffect(() => {
    // Call the API to get VSP info if vetInviteId is not null
    if (vetInviteId) {
      axios
        .get(`/users/get-vsp-info/${vetInviteId}`)
        .then((response) => {
          const data = response.data.data;
          // Extract required fields and update state
          setVspInfo({
            invitedClinicName: data.clinicName,
            invitedClinicNumber: data.clinicNumber,
            invitedDUNSNumber: data.DUNSNumber,
            invitedPrimaryAddress: `${data.primaryAddress}  ${data.stateIssued}`,
            invitedVet: `${data.firstName} ${data.lastName}`,
          });
        })
        .catch((error) => {});
    }
  }, [vetInviteId]);

  const checkPetPresence = () => {
    axios
      .get("/pet-profiles/user-pets")
      .then((response) => {
        const data = response.data;
        if (data && data.data && data.data.length > 0) {
          setIsPetPresent(true);
        } else {
          setIsPetPresent(false);
        }
      })
      .catch((error) => {
        // Handle the error if needed
      });
  };

  useEffect(() => {
    checkPetPresence();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/users/addresses");
        const addresses = response.data.data;

        // Filter addresses based on conditions
        const defaultAddress = addresses.find(
          (address) => address.isDefault === true
        );
        const billingAddress = addresses.find(
          (address) => address.isBillingAddress === true
        );

        // Update component state

        const editPetId = localStorage.getItem("editPetId");

        if (!editPetId) {
          setSelectedAddress(defaultAddress);
        }

        setSelectedBillingAddress(billingAddress);
      } catch (error) {}
    };

    fetchData();
  }, []);
  React.useEffect(() => {
    if (selectedAddress && !selectedAddress.isVerified) {
      setIsCorrectAddressModal(true);
    }
  }, [selectedAddress]);

  useEffect(() => {
    // Check if both selectedAddress and selectedBillingAddress are not null
    if (selectedAddress !== null && selectedBillingAddress !== null) {
      // Set showPetForm to true
      showPetForm(true);
    }
  }, [selectedAddress, selectedBillingAddress]);

  const loadClinicSuggestions = async (inputValue, page) => {
    try {
      const response = await axios.get(
        `/clinics/clinic-suggestion?selectFields=id,tradeStyle,DUNSNumber,email,addressLine1,addressLine2,addressLine3,state,city,postalCode,countryOrRegion,firstName,lastName,phone&searchTerms=tradeStyle`,
        {
          params: { search: inputValue, page },
        }
      );

      const suggestions = response.data.data;

      // Update the state with unique suggestions only if there are new suggestions
      setClinicSuggestions((prevSuggestions) => {
        const existingIds = new Set(prevSuggestions.map((s) => s.id));
        const newSuggestions = suggestions.filter(
          (s) => !existingIds.has(s.id)
        );
        return [...prevSuggestions, ...newSuggestions];
      });

      setCurrentPage(page + 1);
      setHasMoreSuggestions(
        suggestions.length % 10 === 0 && page < response.data.totalPages
      );
    } catch (error) {}
  };

  const handleInputChange = (newValue) => {
    const maxCharacters = 15;
    const specialCharPattern = /[^a-zA-Z0-9\s-']/;

    // Restrict input to max 5 characters and prevent special characters
    if (
      newValue.length <= maxCharacters &&
      !specialCharPattern.test(newValue)
    ) {
      setInputValue(newValue); // Update controlled input value

      setFormData({
        ...formData,
        //  clinicName: newValue,
      });

      // Load suggestions if the input length is 4 or more
      if (newValue.length >= 4) {
        loadClinicSuggestions(newValue, 1);
      }
    }
  };
  const handleVetNameChange = (newValue) => {
    const maxCharacters = 5;
    const specialCharPattern = /[^a-zA-Z0-9\s-']/;

    // Restrict input to max 5 characters and prevent special characters
    if (
      newValue.length <= maxCharacters &&
      !specialCharPattern.test(newValue)
    ) {
      setInputVetName(newValue); // Update controlled input value

      // If you need to update formData or other state, you can do it here
      setFormData({
        ...formData,
        vetName: newValue,
      });
    }
  };

  const handleChangeShipment = (event) => {
    setShipmentType(event.target.value);
  };
  const handleLoadMore = () => {
    loadClinicSuggestions(formData.clinicName, currentPage);
  };
  const handleClinicSelect = async (selectedOption) => {
    const dunsNo = selectedOption.duns;
    const selectedClinicName = selectedOption.value;

    setFormData((prevFormData) => ({
      ...prevFormData,
      clinicName: selectedClinicName,
    }));

    try {
      const clinicDetailsResponse = await axios.get(
        `/clinics/clinic-suggestion?selectFields=id,tradeStyle,DUNSNumber,email,addressLine1,addressLine2,addressLine3,state,city,postalCode,countryOrRegion,firstName,lastName,phone&searchTerms=tradeStyle&search=${selectedClinicName}`
      );

      const clinicDetails = clinicDetailsResponse.data.data[0];

      const vetNamesResponse = await axios.get(
        `/clinics/clinic-suggestion?selectFields=id,tradeStyle,firstName,lastName,addressLine1,addressLine2,addressLine3,city,state,postalCode,phone,DUNSNumber&DUNSNumber=${dunsNo}`
      );

      const vetNames = vetNamesResponse.data.data.map(
        (vet) => `${vet.firstName} ${vet.lastName}`
      );

      setVetNameSuggestions(vetNames);
      setSelectedVetName(null);

      setFormData((prevFormData) => ({
        ...prevFormData,
        clinicName: clinicDetails.tradeStyle,
        clinicPhoneNumber: clinicDetails.phone,
        clinicAddress: `${clinicDetails.addressLine1} ${clinicDetails.addressLine2} ${clinicDetails.addressLine3} ${clinicDetails.city} ${clinicDetails.state} ${clinicDetails.postalCode} ${clinicDetails.countryOrRegion}`,
        vetName: "",
      }));
    } catch (error) {}
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px solid #ddd",
      color: state.isSelected ? "white" : "black",
      background: state.isSelected ? "#007BFF" : "white",
      "&:hover": {
        background: "#007BFF",
        color: "white",
      },
    }),
  };
  useEffect(() => {
    const fetchAddresses = async () => {
      try {
        const response = await axios.get("/users/addresses");
        if (response.data.success) {
          setAddressesBilling(response.data.data);
        } else {
        }
      } catch (error) {}
    };

    if (showSelectedAddressModalBilling) {
      fetchAddresses();
    }
  }, [showSelectedAddressModalBilling]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoadingScreen(true);
        const response = await axios.get("/users/addresses");
        const addressesData = response.data;
        setIsLoadingScreen(false);
        if (
          !addressesData ||
          !addressesData.success ||
          addressesData.data.length === 0
        ) {
          navigate("/addresses/new");
          setShowNoAddressesModal(true);
        }
      } catch (error) {
        setIsLoadingScreen(false);
        // Handle error if needed
      }
    };

    fetchData();
  }, []);
  const handleSetBillingAddress = async (address) => {
    try {
      const response = await axios.put(
        `/users/addresses/markAsBillingAddress/${selectedBillingAddress.id}`
      );
      if (response.data.success) {
        setShowSelectedAddressModalBilling(false);
        setSelectedBillingAddress(response.data.data);
        setSelectedAddressId(address.id); // Update selectedAddressId with the new billing address ID
        toast.success("Billing address set successfully");
        // window.location.reload();
      } else {
        toast.error("Failed to set billing address");
      }
    } catch (error) {
      toast.error("An error occurred while setting billing address");
    }
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    if (formData.clinicName.trim() === "") {
      newErrors.clinicName = "Clinic name is required";
      valid = false;
    }
    if (formData.clinicPhoneNumber.trim() === "") {
      newErrors.clinicPhoneNumber = "Clinic phone number is required";
      valid = false;
    }
    if (formData.clinicAddress.trim() === "") {
      newErrors.clinicAddress = "Clinic address is required";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleSubmitVet = (e) => {
    e.preventDefault();
    if (validateForm()) {
      // handleSubmit(formData);
      toast.success("Vet Details Added ");
    } else {
    }
  };

  const { subscription } = useAuth();

  const handleAddVetClick = () => {
    setShowForm(true);

    setTimeout(() => {
      window.scrollTo(0, document.body.scrollHeight);
    }, 400);
  };

  const handleCloseVetClick = () => {
    setShowForm(false);
  };
  const handlePetform = () => {
    showPetForm(true);
  };
  useEffect(() => {
    const editPetId = localStorage.getItem("editPetId");

    if (editPetId) {
      axios
        .get(`/pet-profiles/${editPetId}`)
        .then((response) => {
          const petDetails = response.data.data;
          const nicknameValue = petDetails.nickName || undefined;
          const breedVlue = petDetails.breed || undefined;
          form.setFieldsValue({
            name: petDetails.petName,
            breed: breedVlue,
            gender: petDetails.gender,
            nickname: nicknameValue,
            weight: petDetails.weight,
            ageYears: Math.floor(petDetails.age / 12),
            ageMonth: petDetails.age % 12,
            profilePicURL: petDetails.profilePicURL,
          });
          setShipmentType(petDetails.shipmentType);
          setSelectedAddress({
            id: petDetails.addressId,
            title: petDetails.Address.title,
            address: petDetails.Address.address,
            city: petDetails.Address.city,
            state: petDetails.Address.state,
            zipcode: petDetails.Address.zipcode,
            country: petDetails.Address.country,
            isVerified: petDetails.Address.isVerified,
          });
          setFormData({
            clinicName: petDetails.clinicName,
            clinicAddress: petDetails.clinicAddress,
            clinicPhoneNumber: petDetails.clinicPhone,
            vetName: petDetails.vet,
          });
          setSubscriptionDates({
            subscriptionCreatedAt: petDetails.subscriptionCreatedAt,
            subscriptionPausedAt: petDetails.subscriptionPausedAt,
          });
        })
        .catch((error) => {});
    }
  }, [form]);

  const handleSubmit = (values) => {
    // Check if a mailing address is selected
    if (!selectedAddress) {
      toast.error("Please select a shipping address");
      return;
    }

    // Check if a billing address is selected
    if (!selectedBillingAddress) {
      toast.error("Please select a Billing Address");
      return;
    }
    if (selectedAddress && !selectedAddress.isVerified) {
      setIsCorrectAddressModal(true);
      return;
    }

    // Retrieve editPetId and editPetPlan from localStorage
    const editPetId = localStorage.getItem("editPetId");
    const editPetPlan = localStorage.getItem("editPetPlan");

    // Calculate age in months
    const ageMonths = parseInt(values.ageMonth) || 0;

    // Define the initial payload with common properties
    let payload = {
      petName: values.name,
      breed: values.breed,
      gender: values.gender,
      nickName: values.nickname,
      weight: parseInt(values.weight),
      age: parseInt(values.ageYears) * 12 + ageMonths,
      planSize: plan || editPetPlan,
      addressId: selectedAddress.id,
      shipmentType: shipmentType,
    };

    // Check if suggestedVetData is not an empty object
    if (Object.keys(suggestedVetData).length !== 0) {
      // Include suggestedVetData as an independent object in the payload
      payload.suggestedVetData = { ...suggestedVetData };
    }

    // Check if nickname is provided and update payload if so
    if (values.nickname !== null && values.nickname !== "") {
      payload.nickName = values.nickname;
    }

    // Check if breed is provided and update payload if so
    if (values.breed !== null && values.breed !== "") {
      payload.breed = values.breed;
    }

    // Handle vet invitation data or form data
    if (vetInviteId && !newInvitedVet) {
      payload.clinicName = vspInfo.invitedClinicName;
      payload.clinicPhone = vspInfo.invitedClinicNumber;
      payload.clinicAddress = vspInfo.invitedPrimaryAddress;
      payload.DUNSNumber = vspInfo.invitedDUNSNumber;
      payload.vet = vspInfo.invitedVet;
    } else {
      if (formData.clinicName) payload.clinicName = formData.clinicName;
      if (formData.clinicPhoneNumber)
        payload.clinicPhone = formData.clinicPhoneNumber;
      if (formData.clinicAddress)
        payload.clinicAddress = formData.clinicAddress;
      if (selectedVetName) payload.vet = selectedVetName;
      if (dunsNos) payload.DUNSNumber = dunsNos;
      const profilePicURL = form.getFieldValue("profilePicURL");
      if (profilePicURL) {
        payload.profilePicURL = profilePicURL;
      }
    }

    if (editPetId) {
      // if (
      //   subscriptionDates.subscriptionCreatedAt === null &&
      //   subscriptionDates.subscriptionPausedAt === null
      // ) {
      payload.addressId = selectedAddress.id;
      // } else {
      //   // Remove addressId from the payload
      //   delete payload.addressId;
      // }

      axios
        .put(`/pet-profiles/${editPetId}`, payload)
        .then((res) => {
          toast.success("Pet details edited successfully");
          navigate("/pets");
        })
        .catch((error) => {})
        .finally(() => {
          localStorage.removeItem("editPetId");
          localStorage.removeItem("editPetPlan");
        });
    } else {
      // Include addressId when adding a new pet profile
      payload.addressId = selectedAddress.id;

      // Set second payload for further processing
      setSecondPayload(payload);

      // Show popup or confirmation modal based on subscription status
      if (!subscription) {
        setAddedPopup(true);
      } else {
        setShowConfirmationModal(true);
      }
    }
  };

  const closeCheckoutModal = () => {
    setAddedPopup(false);
  };
  // Add loading state in your component

  const handleConfirmation = async () => {
    setLoading(true); // Start loading
    try {
      await createPet(secondPayload); // Await the API call
      setShowConfirmationModal(false);

      localStorage.setItem("recallagain", "true");
      toast.success("New subscription added");

      navigate("/pets"); // Only navigate after the API call is successful
    } catch (error) {
      toast.error("Failed to add subscription");
    } finally {
      setLoading(false); // End loading regardless of success or failure
    }
  };

  const handleCancelConfirmation = () => {
    setShowConfirmationModal(false);
  };
  const addAnotherDog = () => {
    createPet(secondPayload)
      .then(() => {
        navigate("/myplans");
      })
      .catch((error) => {
        // Handle error if needed
      });
  };

  const checkout = async () => {
    try {
      // navigate("/load");
      setcheckoutloading(true);

      await createPet(secondPayload);
      setcheckoutloading(false);
      navigate("/checkout");
    } catch (error) {}
  };

  const handleFileUpload = (e) => {
    const files = e.target.files;
    const file = files[0];
    const data = new FormData();
    data.append(`image`, file, file.name);

    axios
      .post("/file-upload", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(({ data: res }) => {
        const data = res.data;
        const loc = data.location;
        form.setFieldValue("profilePicURL", loc);
      });
  };

  const checkIfAddressSelected = (address) => {
    if (!selectedAddress) {
      return false;
    }
    return selectedAddress.id === address.id;
  };

  const updateAddress = (e, address) => {
    if (e.target.checked) {
      setSelectedAddress(address);
    } else {
      setSelectedAddress(null);
    }
  };
  const billingaddmodalopen = () => {
    // setSelectedBillingAddress(null);
    setShowSelectedAddressModalBilling(true);
  };
  const handleFinishFailed = (errorInfo) => {
    // Extract failed field names
    const failedFields = errorInfo.errorFields.map((field) => field.name[0]);

    // Show a single toast message with failed field names
    toast.error(
      `Please fill in the following fields: ${failedFields.join(", ")}`
    );
  };

  const editPetId = localStorage.getItem("editPetId");

  const handleEditAddress = () => {
    setShowSelectedAddressModal(true);
  };
  const editWrongAddress = () => {
    localStorage.setItem("editWrongAddress", "true");

    navigate(`/addresses/edit/${selectedAddress.id}`);
  };
  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <div>
          {checkoutloading ? (
            <LoadingScreen />
          ) : (
            <div>
              {isLoadingScreen ? (
                <LoadingScreenTrans />
              ) : (
                <>
                  <div className="w-full ">
                    {!hasSubscription && (
                      <div className="px-20 mt-20">
                        <StepsAddPet />
                      </div>
                    )}
                  </div>

                  <Page title={"Enter Your Pet Details"}>
                    <div className="container pet-add-form">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="py-5">
                            <Form
                              className="create-form "
                              onFinish={handleSubmit}
                              onFinishFailed={handleFinishFailed}
                              form={form}
                              initialValues={{}}
                            >
                              {(values, form) => {
                                return (
                                  <div className="">
                                    <div className="w-12/12 md:w-8/12 lg:w-10/12 h-fit border-2 border-gray-700 rounded-xl mx-auto element shadow-md  ">
                                      <div className=" ">
                                        <FaArrowCircleLeft
                                          className="text-3xl mb-5 cursor-pointer hover:scale-95 transition-transform duration-300 ease-in-out text-amber-600"
                                          onClick={() => {
                                            localStorage.removeItem(
                                              "editPetId"
                                            ); // Remove editPetId from local storage
                                            localStorage.removeItem(
                                              "editPetPlan"
                                            ); // Remove editPetPlan from local storage
                                            navigate("/myPlans");
                                          }}
                                        />
                                      </div>

                                      <div className="flex flex-col md:flex-col lg:flex-row gap-5 mx-auto">
                                        <FormGroup>
                                          <div className="flex gap-2 flex-row">
                                            {selectedBillingAddress ? (
                                              <div className="flex flex-col w-auto">
                                                <label
                                                  htmlFor="billing"
                                                  className="text-left text-2xl mb-2"
                                                >
                                                  Billing Address
                                                  <span className="text-red-600 text-xs italic font-bold">
                                                    (*Required )
                                                  </span>
                                                </label>

                                                <div className="flex flex-row">
                                                  {isPetPresent && (
                                                    <div className="p-2 bg-[#FFD3A7] shadow-sm w-auto h-auto text-black text-sm rounded-md">
                                                      <div className="flex">
                                                        {
                                                          selectedBillingAddress.title
                                                        }{" "}
                                                        {
                                                          selectedBillingAddress.address
                                                        }
                                                        ,
                                                        {
                                                          selectedBillingAddress.city
                                                        }
                                                        ,
                                                        {
                                                          selectedBillingAddress.state
                                                        }{" "}
                                                        {
                                                          selectedBillingAddress.zipcode
                                                        }
                                                        ,
                                                        {
                                                          selectedBillingAddress.country
                                                        }
                                                        {/* {
                                                          selectedBillingAddress.city
                                                        } */}
                                                        <div className="flex items-center">
                                                          {selectedBillingAddress.isVerified ? (
                                                            <Tag className="bg-green-800 text-white font-bold">
                                                              Verified
                                                            </Tag>
                                                          ) : (
                                                            <Tag className="bg-red-700 text-white font-bold">
                                                              Unverified
                                                            </Tag>
                                                          )}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  )}
                                                  {!isPetPresent && (
                                                    <div className="flex flex-col">
                                                      <div className="p-2 bg-[#FFD3A7] shadow-sm w-auto h-auto text-black text-sm rounded-md">
                                                        <div className="flex items-center justify-between">
                                                          <div>
                                                            {
                                                              selectedBillingAddress.title
                                                            }{" "}
                                                            {
                                                              selectedBillingAddress.address
                                                            }
                                                            ,
                                                            {
                                                              selectedBillingAddress.city
                                                            }
                                                            ,{" "}
                                                            {
                                                              selectedBillingAddress.state
                                                            }{" "}
                                                            {
                                                              selectedBillingAddress.zipcode
                                                            }
                                                            ,{" "}
                                                            {
                                                              selectedBillingAddress.country
                                                            }
                                                            {/* {
                                                              selectedBillingAddress.city
                                                            } */}
                                                          </div>
                                                          <div className="flex items-center">
                                                            {selectedBillingAddress.isVerified ? (
                                                              <Tag className="bg-green-800 text-white font-bold">
                                                                Verified
                                                              </Tag>
                                                            ) : (
                                                              <Tag className="bg-red-700 text-white font-bold">
                                                                Unverified
                                                              </Tag>
                                                            )}
                                                            <FaInfoCircle
                                                              className="text-xl text-blue-400 cursor-pointer ml-2"
                                                              onClick={
                                                                showInfoModal
                                                              }
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <button
                                                        type="button"
                                                        className="btn btn-text d-flex gap-2 align-items-center px-0 mt-1 ml-auto"
                                                        onClick={() =>
                                                          billingaddmodalopen()
                                                        }
                                                      >
                                                        Edit <EditIcon />
                                                      </button>
                                                    </div>
                                                  )}
                                                </div>
                                              </div>
                                            ) : (
                                              <div className="w-full flex flex-col items-center justify-start mt-5">
                                                <label
                                                  htmlFor="billing"
                                                  className="text-left text-2xl"
                                                >
                                                  Billing Address
                                                  <span className="text-red-600 text-xs italic font-bold">
                                                    (*Required )
                                                  </span>
                                                </label>
                                                {/* <button
                                          type="button"
                                          className="bg-amber-500 text-white py-3 px-6 rounded-full flex items-center gap-2 shadow-md hover:bg-amber-600 transition w-2/5 mt-4"
                                          onClick={() =>
                                            setShowSelectedAddressModalBilling(
                                              true
                                            )
                                          }
                                        >
                                          <AiOutlinePlus className="text-lg" />
                                          <span className="text">
                                            Billing Address
                                          </span>
                                        </button> */}
                                              </div>
                                            )}
                                          </div>
                                        </FormGroup>

                                        <FormGroup>
                                          {!selectedAddress && (
                                            <div className="w-full flex flex-col items-center justify-start mt-10">
                                              <label
                                                htmlFor="information"
                                                className="text-left mb-4"
                                              >
                                                Shipping Address
                                                <span className="text-red-600  text-xs italic font-bold">
                                                  {" "}
                                                  (*Required )
                                                </span>
                                              </label>
                                              <button
                                                type="button"
                                                className="bg-amber-500 text-white py-3 px-6 rounded-full flex items-center gap-2 shadow-md hover:bg-amber-600 transition w-2/5"
                                                onClick={() =>
                                                  setShowSelectedAddressModal(
                                                    true
                                                  )
                                                }
                                              >
                                                <AiOutlinePlus className="text-lg" />
                                                <span className="text">
                                                  Shipping Address
                                                </span>
                                              </button>
                                            </div>
                                          )}

                                          <div className="d-flex gap-4">
                                            {selectedAddress?.id &&
                                              getAddressLine(
                                                selectedAddress
                                              ) && (
                                                <div className="d-flex align-items-center gap-3">
                                                  {getAddressLine(
                                                    selectedAddress
                                                  ) && (
                                                    <div className="flex flex-col">
                                                      <label
                                                        htmlFor="billing"
                                                        className="text-left text-2xl mb-2"
                                                      >
                                                        Shipping Address
                                                        <span className="text-red-600  text-xs italic font-bold">
                                                          {" "}
                                                          (*Required )
                                                        </span>
                                                      </label>
                                                      <div className="p-2 bg-[#FFD3A7] shadow-sm w-auto h-auto text-black text-sm rounded-md">
                                                        <div className="flex items-center justify-between">
                                                          {getAddressLine(
                                                            selectedAddress
                                                          ) || "please add "}
                                                          ,
                                                          {
                                                            selectedAddress.country
                                                          }
                                                          {/* ,
                                                          {selectedAddress.city}{" "} */}
                                                          <div className="flex items-center">
                                                            {selectedAddress.isVerified ? (
                                                              <Tag className="bg-green-800 text-white font-bold">
                                                                Verified
                                                              </Tag>
                                                            ) : (
                                                              <Tag className="bg-red-700 text-white font-bold">
                                                                Unverified
                                                              </Tag>
                                                            )}
                                                          </div>
                                                        </div>
                                                      </div>
                                                      {/* <Tag
                                                color="#FFD3A7"
                                                className="text-xs md:text-sm lg:text-md rounded-xl font-bold p-2 flex text-black"
                                              >
                                                ,{selectedAddress.country},
                                                {selectedAddress.city}{" "}
                                              </Tag> */}
                                                      <button
                                                        type="button"
                                                        className="btn btn-text d-flex gap-2 align-items-center px-0 mt-1 ml-auto"
                                                        onClick={
                                                          handleEditAddress
                                                        }
                                                      >
                                                        Edit <EditIcon />
                                                      </button>
                                                    </div>
                                                  )}
                                                </div>
                                              )}
                                          </div>
                                        </FormGroup>
                                      </div>

                                      <div
                                        className="row"
                                        initial={{ y: -100, opacity: 0 }}
                                        animate={
                                          showForm
                                            ? { y: 0, opacity: 1 }
                                            : { y: -100 }
                                        }
                                        transition={{
                                          type: "spring",
                                          duration: 0.5,
                                        }}
                                      >
                                        <div className="w-full flex">
                                          <div className="col-md-3 text-center mb-4">
                                            <div>
                                              <div className="d-block">
                                                <Avatar
                                                  size="100"
                                                  round={true}
                                                  src={form.getFieldValue(
                                                    "profilePicURL"
                                                  )}
                                                  color="gray"
                                                />
                                              </div>
                                              <div className="btn btn-text d-inline-flex gap-2 file-upload-trigger">
                                                <span className="fw-bold">
                                                  Edit Photo
                                                </span>
                                                <EditIcon />
                                                <input
                                                  type="file"
                                                  name="profilePicURL"
                                                  className="file-upload"
                                                  accept="image/png, image/jpeg"
                                                  onChange={handleFileUpload}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className="w-full">
                                            <div className="col-md-12">
                                              <FormGroup>
                                                <label htmlFor="name">
                                                  Fur baby name{" "}
                                                  <span className="text-red-600  text-xs italic font-bold">
                                                    {" "}
                                                    (*Required )
                                                  </span>
                                                </label>
                                                <Field
                                                  name="name"
                                                  rules={[
                                                    {
                                                      required: true,
                                                      message:
                                                        "Please enter pet name",
                                                    },
                                                    {
                                                      max: 25,
                                                      message:
                                                        "Pet name cannot be longer than 25 characters",
                                                    },
                                                  ]}
                                                >
                                                  <Input
                                                    error={
                                                      form.getFieldError(
                                                        "name"
                                                      )[0]
                                                    }
                                                    placeholder={"Pet Name"}
                                                  />
                                                </Field>
                                              </FormGroup>
                                            </div>
                                            <div className="row">
                                              <div className="col-md-6">
                                                <Field
                                                  name="gender"
                                                  rules={[
                                                    {
                                                      required: true,
                                                      message:
                                                        "Please select gender",
                                                    },
                                                  ]}
                                                >
                                                  <FormGroup>
                                                    <div>
                                                      <label htmlFor="gender">
                                                        Gender{" "}
                                                        <span className="text-red-600 text-xs italic font-bold">
                                                          {" "}
                                                          (*Required )
                                                        </span>
                                                      </label>
                                                      <div className="select-wrap">
                                                        <Select
                                                          name="gender"
                                                          className="react-select-custom"
                                                          classNamePrefix="react-select-custom"
                                                          options={GenderValues}
                                                          value={
                                                            GenderValues.find(
                                                              (v) =>
                                                                v.value ===
                                                                form.getFieldValue(
                                                                  "gender"
                                                                )
                                                            ) || ""
                                                          }
                                                          onChange={({
                                                            value,
                                                          }) => {
                                                            form.setFieldValue(
                                                              "gender",
                                                              value
                                                            );
                                                          }}
                                                        />
                                                      </div>
                                                    </div>
                                                  </FormGroup>
                                                </Field>
                                              </div>
                                              <div className="col-md-6">
                                                <FormGroup>
                                                  <label htmlFor="weight">
                                                    Weight (Lbs){" "}
                                                    <span className="text-red-600 text-xs italic font-bold">
                                                      {" "}
                                                      (*Required )
                                                    </span>
                                                  </label>
                                                  <Field
                                                    name="weight"
                                                    rules={[
                                                      {
                                                        required: true,
                                                        message:
                                                          "Please enter the pet weight",
                                                      },
                                                    ]}
                                                  >
                                                    <Input
                                                      error={
                                                        form.getFieldError(
                                                          "weight"
                                                        )[0]
                                                      }
                                                      placeholder={
                                                        "Weight (Lbs)"
                                                      }
                                                      type="number"
                                                    />
                                                  </Field>
                                                </FormGroup>
                                              </div>
                                            </div>

                                            <div className="col-md-6"></div>

                                            <div className="col-md-12">
                                              <FormGroup>
                                                <label htmlFor="nickname">
                                                  Nickname{" "}
                                                </label>
                                                <Field name="nickname">
                                                  <Input
                                                    error={
                                                      form.getFieldError(
                                                        "nickname"
                                                      )[0]
                                                    }
                                                    placeholder={"Nickname"}
                                                  />
                                                </Field>
                                              </FormGroup>
                                            </div>
                                            <div className="row">
                                              <div className="col-md-6">
                                                <Field name="breed">
                                                  <FormGroup>
                                                    <div>
                                                      <label htmlFor="gender">
                                                        Breed
                                                      </label>
                                                      <div className="select-wrap">
                                                        <Select
                                                          name="breed"
                                                          className="react-select-custom"
                                                          classNamePrefix="react-select-custom"
                                                          options={dogBreeds.breeds.map(
                                                            (breed) => ({
                                                              value: breed.name,
                                                              label: breed.name,
                                                            })
                                                          )}
                                                          value={
                                                            form.getFieldValue(
                                                              "breed"
                                                            )
                                                              ? {
                                                                  label:
                                                                    form.getFieldValue(
                                                                      "breed"
                                                                    ),
                                                                  value:
                                                                    form.getFieldValue(
                                                                      "breed"
                                                                    ),
                                                                }
                                                              : null
                                                          }
                                                          onChange={(
                                                            selectedOption
                                                          ) => {
                                                            form.setFieldValue(
                                                              "breed",
                                                              selectedOption
                                                                ? selectedOption.value
                                                                : ""
                                                            );
                                                          }}
                                                        />
                                                      </div>
                                                    </div>
                                                  </FormGroup>
                                                </Field>
                                              </div>

                                              <div className="col-md-6">
                                                <FormGroup>
                                                  <label htmlFor="information">
                                                    Age{" "}
                                                    <span className="text-red-600  text-xs italic font-bold">
                                                      {" "}
                                                      (*Required )
                                                    </span>
                                                  </label>
                                                  <div className="d-flex gap-4">
                                                    <Field
                                                      name="ageYears"
                                                      rules={[
                                                        {
                                                          required: true,
                                                          message:
                                                            "Please add the age of the pet",
                                                        },
                                                      ]}
                                                    >
                                                      <Input
                                                        error={
                                                          form.getFieldError(
                                                            "ageYears"
                                                          )[0]
                                                        }
                                                        placeholder={"Years"}
                                                        type="number"
                                                        value={1}
                                                        min="0"
                                                      />
                                                    </Field>

                                                    <Field
                                                      name="ageMonth"
                                                      className
                                                    >
                                                      <Input
                                                        error={
                                                          form.getFieldError(
                                                            "ageMonth"
                                                          )[0]
                                                        }
                                                        placeholder={"Months"}
                                                        type="number"
                                                        value={1}
                                                        min="0"
                                                      />
                                                    </Field>
                                                  </div>
                                                </FormGroup>
                                              </div>
                                              <div className="row mb-5">
                                                <div className="col-md-6">
                                                  <div className="form-group">
                                                    <label
                                                      htmlFor="shipmentType"
                                                      className="block text-sm font-medium text-gray-700"
                                                    >
                                                      Shipment Type
                                                    </label>
                                                    <div className="mt-2 flex space-x-4">
                                                      <div className="flex justify-center items-center">
                                                        <input
                                                          type="radio"
                                                          id="monthly"
                                                          name="shipmentType"
                                                          value="monthly"
                                                          checked={
                                                            shipmentType ===
                                                            "monthly"
                                                          }
                                                          onChange={
                                                            handleChangeShipment
                                                          }
                                                          className="focus:ring-orange-500 h-6 w-6 text-orange-600 border-gray-300"
                                                        />
                                                        <label
                                                          htmlFor="monthly"
                                                          className="ml-3 block text-sm font-medium text-gray-700 mt-1"
                                                        >
                                                          Monthly
                                                        </label>
                                                      </div>
                                                      {/* <div className="flex justify-center items-center">
                                                        <input
                                                          type="radio"
                                                          id="quarterly"
                                                          name="shipmentType"
                                                          value="quarterly"
                                                          checked={
                                                            shipmentType ===
                                                            "quarterly"
                                                          }
                                                          onChange={
                                                            handleChangeShipment
                                                          }
                                                          className="focus:ring-indigo-500 h-6 w-6 text-indigo-600 border-gray-300"
                                                        />
                                                        <label
                                                          htmlFor="quarterly"
                                                          className="ml-3 block text-sm font-medium text-gray-700 mt-1"
                                                        >
                                                          Quarterly
                                                        </label>
                                                      </div> */}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        {isPetPresent && vetInviteId && (
                                          <div className="px-4 py-6 sm:px-6 flex justify-end">
                                            <span
                                              className="border-amber-500 border-2  rounded-lg hover:bg-gray-50 p-3 font-bold  text-center text-amber-500 flex justify-center items-center cursor-pointer "
                                              onClick={() =>
                                                setNewInvitedVet(true)
                                              }
                                            >
                                              Change vet
                                            </span>
                                          </div>
                                        )}

                                        {!vetInviteId ||
                                          (newInvitedVet && (
                                            <div className="flex w-full">
                                              {!localStorage.getItem(
                                                "editPetId"
                                              ) && (
                                                <div className="pl-1 pt-2 flex flex-col mb-5 mx-auto">
                                                  <span
                                                    className="border-amber-500 border-2  rounded-lg hover:bg-gray-50 p-3 font-bold  text-center text-amber-500 flex justify-center items-center cursor-pointer "
                                                    onClick={handleAddVetClick}
                                                  >
                                                    + Add your vet
                                                  </span>
                                                </div>
                                              )}
                                              {localStorage.getItem(
                                                "editPetId"
                                              ) && (
                                                <div className="pl-1 pt-2 flex flex-col mb-5 mx-auto">
                                                  <span
                                                    className="border-amber-500 border-2  rounded-lg hover:bg-gray-50 p-3 font-bold  text-center text-amber-500 flex justify-center items-center cursor-pointer "
                                                    onClick={handleAddVetClick}
                                                  >
                                                    + Edit a vet
                                                  </span>
                                                </div>
                                              )}
                                            </div>
                                          ))}

                                        {!vetInviteId && (
                                          <div className="flex w-full">
                                            {!localStorage.getItem(
                                              "editPetId"
                                            ) && (
                                              <div className="pl-1 pt-2 flex flex-col lg:flex-row  space-x-3 mb-5 lg:mx-72">
                                                <span
                                                  className="border-amber-500 border-2  rounded-lg hover:bg-gray-50 p-3 font-bold  text-center text-amber-500 flex justify-center items-center cursor-pointer "
                                                  onClick={handleAddVetClick}
                                                >
                                                  + Add your vet
                                                </span>
                                              </div>
                                            )}
                                            {localStorage.getItem(
                                              "editPetId"
                                            ) && (
                                              <div className="pl-1 pt-2 flex flex-col mb-5 mx-10">
                                                <span
                                                  className="border-amber-500 border-2  rounded-lg hover:bg-gray-50 p-3 font-bold  text-center text-amber-500 flex justify-center items-center cursor-pointer "
                                                  onClick={handleAddVetClick}
                                                >
                                                  + Edit a vet
                                                </span>
                                              </div>
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    </div>

                                    {/* {!vetInviteId ||
                                      (newInvitedVet && (
                                        <div>
                                          {showForm && (
                                            <motion.div
                                              initial={{ y: -100, opacity: 0 }}
                                              animate={
                                                showForm
                                                  ? { y: 0, opacity: 1 }
                                                  : { y: -100 }
                                              }
                                              transition={{
                                                type: "spring",
                                                duration: 0.5,
                                              }}
                                              className="relative w-10/12 md:w-8/12 lg:w-10/12 h-fit border-2 border-gray-700 rounded-xl mx-auto p-0 md:p-5 lg:p-5   "
                                            >
                                              <div
                                                className="absolute right-0  top-0 bg-amber-600  p-1 flex space-x-2 rounded-lg w-36 ml-auto cursor-pointer text-gray-50 hover:scale-105 text-xs"
                                                onClick={handleCloseVetClick}
                                              >
                                                I'll update this later{" "}
                                                <GiCancel className="text-lg ml-1" />{" "}
                                              </div>
                                              <div className="lg:p-10 sm:p-2 md:p-3">
                                                <h2 className="text-sm lg:text-2xl font-semibold leading-7 text-gray-900 text-center mt-10 lg:mt-2 lg:text-left">
                                                  Enter Your Vet Details
                                                </h2>
                                              </div>

                                              <div className="border-t border-gray-100">
                                                <dl className="divide-y divide-gray-100">
                                                  <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                                    <dt className="text-sm font-medium text-gray-900">
                                                      {" "}
                                                      Clinic Name:
                                                      <span className="text-green-600 font-semibold italic text-xs">
                                                        (Add at least four
                                                        characters for
                                                        suggestions)
                                                      </span>
                                                    </dt>

                                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                                      <Select
                                                        id="clinicName"
                                                        name="clinicName"
                                                        value={{
                                                          label:
                                                            formData.clinicName,
                                                          value:
                                                            formData.clinicName,
                                                        }}
                                                        isSearchable={true}
                                                        onChange={
                                                          handleClinicSelect
                                                        } // Assuming handleClinicSelect is defined elsewhere
                                                        onInputChange={
                                                          handleInputChange
                                                        }
                                                        inputValue={inputValue} // Set the controlled input value
                                                        options={clinicSuggestions.map(
                                                          (suggestion) => ({
                                                            label: `${suggestion.tradeStyle} ${suggestion.addressLine1} ${suggestion.addressLine2} ${suggestion.addressLine3} ${suggestion.state} ${suggestion.city} ${suggestion.postalCode}`,
                                                            value:
                                                              suggestion.tradeStyle,
                                                            duns: suggestion.DUNSNumber,
                                                            clinicDetails:
                                                              suggestion,
                                                          })
                                                        )}
                                                        styles={customStyles} // Assuming customStyles is defined elsewhere
                                                        placeholder="Search or select a clinic"
                                                        onBlur={() => {
                                                          setInputValue(""); // Clear input value
                                                          setClinicSuggestions([]); // Clear suggestions on blur
                                                        }} 
                                                      />
                                                    </dd>
                                                  </div>

                                                  <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                                    <dt className="text-sm font-medium text-gray-900">
                                                      Clinic Phone Number:
                                                    </dt>
                                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                                      <input
                                                        type="text"
                                                        id="clinicPhoneNumber"
                                                        name="clinicPhoneNumber"
                                                        value={
                                                          formData.clinicPhoneNumber
                                                        }
                                                        onChange={() => {}}
                                                        className="border-2 p-2 w-full outline-none rounded-lg"
                                                        readOnly
                                                      />
                                                    </dd>
                                                  </div>

                                                  <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                                    <dt className="text-sm font-medium text-gray-900">
                                                      Name of the Vet:
                                                    </dt>
                                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                                      <Select
                                                        id="vetName"
                                                        name="vetName"
                                                        value={{
                                                          label:
                                                            selectedVetName ||
                                                            formData.vetName ||
                                                            "",
                                                          value:
                                                            selectedVetName ||
                                                            formData.vetName ||
                                                            "",
                                                        }}
                                                        onChange={(
                                                          selectedOption
                                                        ) => {
                                                          setSelectedVetName(
                                                            selectedOption.label
                                                          );
                                                        }}
                                                        onInputChange={
                                                          handleVetNameChange
                                                        } // Use the new handler
                                                        inputValue={
                                                          inputVetName
                                                        } // Set the controlled input value
                                                        options={vetNameSuggestions.map(
                                                          (name) => ({
                                                            label: name,
                                                            value: name,
                                                          })
                                                        )}
                                                        styles={customStyles}
                                                        placeholder="Select a vet name"
                                                      />
                                                    </dd>
                                                  </div>

                                                  <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                                    <dt className="text-sm font-medium text-gray-900">
                                                      {" "}
                                                      Clinic Address:
                                                    </dt>
                                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                                      <input
                                                        type="text"
                                                        id="clinicAddress"
                                                        name="clinicAddress"
                                                        value={
                                                          formData.clinicAddress
                                                        }
                                                        onChange={() => {}}
                                                        className="border-2 p-2 w-full outline-none  rounded-lg"
                                                        readOnly
                                                      />
                                                    </dd>
                                                  </div>
                                                </dl>
                                                <div className="pl-72 pr-72 mt-3 mb-3">
                                                  {" "}
                                                  <hr />
                                                </div>
                                                <div className="flex text-center justify-center text-amber-600">
                                                  Or
                                                </div>
                                                <div className="text-xs md:text-sm lg:text-md p-2 text-amber-600 italic font-semibold flex text-center justify-center mb-4">
                                                  Couldn't find your vet or have
                                                  any queries, please write us
                                                  at
                                                  customersupport@carsonandbear.com
                                                </div>
                                                <span
                                                  onClick={
                                                    handleSuggestedVetFormOpen
                                                  }
                                                  className="p-3 w-1/2 lg:w-2/12 text-xs md:text-md lg:text-xl mx-auto mb-4 bg-amber-500/10 text-black rounded-lg font-bold flex space-x-2 hover:bg-amber-400/20 border-1 border-amber-900 cursor-pointer text-center justify-center items-center"
                                                >
                                                  <BsFileEarmarkPerson className="text-xl mr-2" />
                                                  Suggest your Vet
                                                </span>
                                              </div>
                                            </motion.div>
                                          )}
                                          {Object.keys(suggestedVetData)
                                            .length !== 0 && (
                                            <>
                                              <SuggestVetInfo
                                                clinicName={
                                                  suggestedVetData.clinicName
                                                }
                                                vetName={
                                                  suggestedVetData.vetName
                                                }
                                                address={
                                                  suggestedVetData.address
                                                }
                                                phoneNumber={
                                                  suggestedVetData.phoneNumber
                                                }
                                                email={suggestedVetData.email}
                                              />
                                              {handleCloseVetClick()}
                                            </>
                                          )}
                                        </div>
                                      ))} */}

                                    {!vetInviteId && (
                                      <div>
                                        {showForm && (
                                          <div className="px-1 md:px-20 lg:px-20 py-5">
                                            <motion.div
                                              initial={{ y: -100, opacity: 0 }}
                                              animate={
                                                showForm
                                                  ? { y: 0, opacity: 1 }
                                                  : { y: -100 }
                                              }
                                              transition={{
                                                type: "spring",
                                                duration: 0.5,
                                              }}
                                              className="relative w-10/12 md:w-8/12 lg:w-10/12 h-fit border-2 border-gray-700 rounded-xl mx-auto p-0  md:p-5 lg:p-5   "
                                            >
                                              <div
                                                className="absolute right-0  top-0 bg-amber-600  p-1 flex space-x-2 rounded-lg w-36 ml-auto cursor-pointer text-gray-50 hover:scale-105 text-xs"
                                                onClick={handleCloseVetClick}
                                              >
                                                I'll update this later{" "}
                                                <GiCancel className="text-lg ml-1" />{" "}
                                              </div>
                                              <div className="lg:p-10 sm:p-2 md:p-3">
                                                <h2 className="text-sm lg:text-2xl font-semibold leading-7 text-gray-900 text-center mt-10 lg:mt-2 lg:text-left">
                                                  Enter Your Vet Details
                                                </h2>
                                              </div>

                                              <div className="border-t border-gray-100">
                                                <dl className="divide-y divide-gray-100">
                                                  <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                                    <dt className="text-sm font-medium text-gray-900">
                                                      {" "}
                                                      Clinic Name:
                                                      <span className="text-green-600 font-semibold italic text-xs">
                                                        (Add at least four
                                                        characters for
                                                        suggestions)
                                                      </span>
                                                    </dt>

                                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                                      <Select
                                                        id="clinicName"
                                                        name="clinicName"
                                                        value={{
                                                          label:
                                                            formData.clinicName,
                                                          value:
                                                            formData.clinicName,
                                                        }}
                                                        isSearchable={true}
                                                        onChange={
                                                          handleClinicSelect
                                                        } // Assuming handleClinicSelect is defined elsewhere
                                                        onInputChange={
                                                          handleInputChange
                                                        }
                                                        inputValue={inputValue} // Set the controlled input value
                                                        options={clinicSuggestions.map(
                                                          (suggestion) => ({
                                                            label: `${suggestion.tradeStyle} ${suggestion.addressLine1} ${suggestion.addressLine2} ${suggestion.addressLine3} ${suggestion.state} ${suggestion.city} ${suggestion.postalCode}`,
                                                            value:
                                                              suggestion.tradeStyle,
                                                            duns: suggestion.DUNSNumber,
                                                            clinicDetails:
                                                              suggestion,
                                                          })
                                                        )}
                                                        styles={customStyles} // Assuming customStyles is defined elsewhere
                                                        placeholder="Search or select a clinic"
                                                        onBlur={() => {
                                                          setInputValue(""); // Clear input value
                                                          setClinicSuggestions(
                                                            []
                                                          ); // Clear suggestions on blur
                                                        }}
                                                      />
                                                    </dd>
                                                  </div>

                                                  <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                                    <dt className="text-sm font-medium text-gray-900">
                                                      Clinic Phone Number:
                                                    </dt>
                                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                                      <input
                                                        type="text"
                                                        id="clinicPhoneNumber"
                                                        name="clinicPhoneNumber"
                                                        value={
                                                          formData.clinicPhoneNumber
                                                        }
                                                        onChange={() => {}}
                                                        className="border-2 p-2 w-full outline-none rounded-lg"
                                                        readOnly
                                                      />
                                                    </dd>
                                                  </div>

                                                  <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                                    <dt className="text-sm font-medium text-gray-900">
                                                      Name of the Vet:
                                                    </dt>
                                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                                      <Select
                                                        id="vetName"
                                                        name="vetName"
                                                        value={{
                                                          label:
                                                            selectedVetName ||
                                                            formData.vetName ||
                                                            "",
                                                          value:
                                                            selectedVetName ||
                                                            formData.vetName ||
                                                            "",
                                                        }}
                                                        onChange={(
                                                          selectedOption
                                                        ) => {
                                                          setSelectedVetName(
                                                            selectedOption.label
                                                          );
                                                        }}
                                                        onInputChange={
                                                          handleVetNameChange
                                                        } // Use the new handler
                                                        inputValue={
                                                          inputVetName
                                                        } // Set the controlled input value
                                                        options={vetNameSuggestions.map(
                                                          (name) => ({
                                                            label: name,
                                                            value: name,
                                                          })
                                                        )}
                                                        styles={customStyles}
                                                        placeholder="Select a vet name"
                                                      />
                                                    </dd>
                                                  </div>

                                                  <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                                    <dt className="text-sm font-medium text-gray-900">
                                                      {" "}
                                                      Clinic Address:
                                                    </dt>
                                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                                      <input
                                                        type="text"
                                                        id="clinicAddress"
                                                        name="clinicAddress"
                                                        value={
                                                          formData.clinicAddress
                                                        }
                                                        onChange={() => {}}
                                                        className="border-2 p-2 w-full outline-none  rounded-lg"
                                                        readOnly
                                                      />
                                                    </dd>
                                                  </div>
                                                </dl>
                                                <div className="pl-72 pr-72 mt-3 mb-3">
                                                  {" "}
                                                  <hr />
                                                </div>
                                                <div className="flex text-center justify-center text-amber-600">
                                                  Or
                                                </div>
                                                <div className="text-xs md:text-sm lg:text-md p-2  text-amber-600 italic  font-semibold flex text-center justify-center mb-4">
                                                  Couldn't find your vet, please
                                                  suggest your vet below. If you
                                                  have any queries, please write
                                                  us at
                                                  customersupport@carsonandbear.com
                                                </div>

                                                <span
                                                  onClick={
                                                    handleSuggestedVetFormOpen
                                                  }
                                                  className="p-3 w-1/2 lg:w-2/12 mx-auto mb-4 text-xs md:text-md lg:text-xl bg-amber-500/10 text-black rounded-lg font-bold flex space-x-2 hover:bg-amber-400/20 border-1 border-amber-900 cursor-pointer text-center justify-center items-center"
                                                >
                                                  <BsFileEarmarkPerson className="text-xs md:text-md lg:text-xl mr-2" />
                                                  Suggest your Vet
                                                </span>
                                              </div>
                                            </motion.div>
                                          </div>
                                        )}
                                        {Object.keys(suggestedVetData)
                                          .length !== 0 && (
                                          <>
                                            <SuggestVetInfo
                                              clinicName={
                                                suggestedVetData.clinicName
                                              }
                                              vetName={suggestedVetData.vetName}
                                              address={suggestedVetData.address}
                                              phoneNumber={
                                                suggestedVetData.phoneNumber
                                              }
                                              email={suggestedVetData.email}
                                            />
                                            {handleCloseVetClick()}
                                          </>
                                        )}
                                      </div>
                                    )}
                                    <div className="row mt-3 w-10/12 md:w-8/12 lg:w-10/12 mx-auto">
                                      <div className="flex justify-center md:justify-end space-x-3">
                                        <button className="btn-theme border-0 rounded w-full md:w-auto">
                                          Submit details
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                );
                              }}
                            </Form>
                            {vetInviteId && !newInvitedVet && (
                              <InvitedVet
                                clinicName={vspInfo.invitedClinicName}
                                clinicPhoneNumber={vspInfo.invitedClinicNumber}
                                vetName={vspInfo.invitedVet}
                                clinicAddress={vspInfo.invitedPrimaryAddress}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <Modal
                      open={suggestedVetForm}
                      title={"Suggest Your Vet"}
                      onClose={handleSuggestedVetFormClose}
                    >
                      <SuggestedVetForm onClose={handleSuggestedVetFormClose} />
                    </Modal>
                    <Modal
                      open={showConfirmationModal}
                      onClose={handleCancelConfirmation}
                    >
                      <p className="p-2 text-2xl font-semibold">
                        Are you sure you want to continue with this plan?
                      </p>
                      <div className="flex space-x-4 justify-between p-2">
                        <button
                          className="p-3 w-1/2 bg-amber-100 rounded-lg font-semiBold hover:bg-gray-200 hover:scale-95 transition-transform duration-300 ease-in-out"
                          onClick={handleCancelConfirmation}
                        >
                          Cancel
                        </button>

                        <button
                          className="p-3 w-1/2 bg-amber-500 rounded-lg font-semiBold hover:bg-amber-600 hover:scale-95 transition-transform duration-300 ease-in-out"
                          onClick={handleConfirmation}
                        >
                          Continue
                        </button>
                      </div>
                    </Modal>
                    <Modal open={showNoAddressesModal} closeIcon={false}>
                      <div className="py-2 text-center">
                        <Lottie
                          options={lottieOptions}
                          height={300}
                          width={300}
                        />
                        <p className="text-center text-xl font-semibold">
                          No billing address found. Please add your billing
                          address.
                        </p>
                        <Link
                          to="/addresses/new"
                          className="btn btn-text py-1 px-3 text-primary d-inline-block mx-auto"
                        >
                          <button
                            className="bg-amber-500 p-3 text-white rounded-xl font-semibold lg:w-[27rem] sm:w-[24rem] hover:bg-amber-600"
                            // onClick={checkout}
                          >
                            Add Billing Address
                          </button>
                        </Link>
                      </div>
                    </Modal>
                    {/* <Modal open={NoEdit} closeIcon={false}>
              <div className="py-2 text-center">
                <p className="text-center text-xl font-semibold">
                  Address is associated with a subscribed pet profile. And, thus
                  it cannot be updated.
                </p>

                <button
                  className="bg-amber-500 p-3 text-white rounded-xl font-semibold lg:w-[27rem] sm:w-[24rem] hover:bg-amber-600"
                  onClick={() => setNoEditModal(false)}
                >
                  Close{" "}
                </button>
              </div>
            </Modal> */}
                    <Modal
                      open={isInfoModalVisible}
                      onClose={() => setIsInfoModalVisible(false)}
                    >
                      <p className="flex ">
                        When adding the first pet, you can choose any of the
                        billing addresses from the list. Please note that the
                        billing address can only be set once for the first pet,
                        and the same billing address will be used for other pets
                        as well.
                      </p>
                    </Modal>
                    <Modal
                      open={isCorrectAddressModal}
                      onClose={() => setIsCorrectAddressModal(false)}
                    >
                      <p className="flex w-3/4 text-center justify-center items-center text-xl mb-10 mx-auto">
                        Your selected shipping address is unverified. Please
                        verify shipping address to continue.
                      </p>

                      <div className="w-full">
                        <button
                          className="w-full p-3 bg-[#448EBE] text-white rounded-md hover:bg-[#68a7c7] "
                          onClick={editWrongAddress}
                        >
                          Edit Address
                        </button>
                      </div>
                    </Modal>
                    <Modal
                      open={addedPopup}
                      onClose={closeCheckoutModal}
                      // closeIcon={false}
                      title={"Congratulations"}
                    >
                      <div className="pet-added-form">
                        <div className="text-center mb-4 main-form flex flex-col">
                          <p className="text-lg md:text-xl">
                            Great Job! Please move to checkout to join the pack
                            or add another pet.
                          </p>
                          <div className="mt-4 md:mt-6 flex justify-center items-center">
                            <img
                              src="/images/pet-pack.png"
                              alt="Pet Pack"
                              className="w-full md:w-auto max-w-xs md:max-w-none"
                            />
                          </div>
                        </div>

                        <div className="actions px-5">
                          <button
                            className="btn btn-primary w-100 my-2 py-2 fs-5"
                            onClick={addAnotherDog}
                          >
                            Add Another Dog
                          </button>
                          <button
                            className="btn btn-checkout w-100 my-2 py-2 fs-5"
                            onClick={checkout}
                          >
                            Checkout
                          </button>
                        </div>
                      </div>
                    </Modal>
                    <Modal
                      open={showSelectedAddressModalBilling}
                      title={"Select Address"}
                      onClose={() => {
                        setShowSelectedAddressModalBilling(false);
                      }}
                    >
                      {addressesBilling.map((address) => (
                        <div
                          key={address}
                          className={`bg-gray-200 p-4 mb-4 rounded-2xl h-44 ${
                            selectedAddressId === address
                              ? " rounded-2xl bg-amber-400 bg-opacity-35"
                              : ""
                          }`}
                          style={{ position: "relative" }}
                        >
                          <div className="flex items-center justify-between">
                            <div>
                              <p className="text-xl font-semibold">
                                {address.title}
                              </p>
                              <p className="text-gray-600">{address.address}</p>
                              <p className="text-gray-600">
                                {address.city}, {address.state}{" "}
                                {address.zipcode}
                              </p>
                            </div>
                            <label className="cursor-pointer">
                              <input
                                type="radio"
                                checked={selectedBillingAddress === address}
                                onChange={() =>
                                  setSelectedBillingAddress(address)
                                }
                                className="hidden"
                              />
                              <div className="bg-white border-1  w-7 h-7 flex items-center justify-center rounded-full">
                                {selectedBillingAddress === address && (
                                  <div className="w-8 h-7 bg-green-500 rounded-full border-2 border-green-600">
                                    {" "}
                                    <FaCheck className="text-md text-white text-center mt-1 ml-1" />
                                  </div>
                                )}
                              </div>
                            </label>
                          </div>
                        </div>
                      ))}
                      <span
                        onClick={() => navigate("/addresses/new")}
                        className="text-amber-600 text-xl font-semibold cursor-pointer flex text-center items-center justify-center mb-2 hover:scale-105 hover:text-amber-500"
                      >
                        + Add new address
                      </span>
                      <button
                        className="bg-amber-500 text-white px-4 py-3 rounded-md w-full"
                        onClick={handleSetBillingAddress}
                        // disabled={!selectedAddressId}
                      >
                        Set as Billing Address
                      </button>
                    </Modal>
                    <Modal
                      open={showSelectedAddressModal}
                      title={"Select Address"}
                      onClose={() => {
                        setShowSelectedAddressModal(false);
                      }}
                    >
                      {addresses.map((address) => {
                        // Check if title is not null
                        if (address.title == null) {
                          return (
                            <>
                              <SingleAddressPet
                                allowEdit={false}
                                selectable={true}
                                id={address.id}
                                address={getAddressLine(address)}
                                checked={checkIfAddressSelected(address)}
                                onCheckedUpdate={(e) =>
                                  updateAddress(e, address)
                                }
                              />
                            </>
                          );
                        }
                        return (
                          <>
                            <SingleAddressPet
                              allowEdit={false}
                              selectable={true}
                              id={address.id}
                              title={address.title || ""}
                              address={getAddressLine(address)}
                              checked={checkIfAddressSelected(address)}
                              onCheckedUpdate={(e) => updateAddress(e, address)}
                            />
                          </>
                        );
                      })}
                      <span
                        onClick={() => navigate("/addresses/new")}
                        className="text-amber-600 text-xl font-semibold cursor-pointer flex text-center items-center justify-center mb-2 hover:scale-105 hover:text-amber-500"
                      >
                        + Add new address
                      </span>

                      <button
                        className="btn btn-primary w-100 py-3 fs-5"
                        onClick={() => {
                          setShowSelectedAddressModal(false);
                        }}
                        disabled={!selectedAddress}
                      >
                        Select Address
                      </button>
                    </Modal>
                  </Page>
                </>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
}
