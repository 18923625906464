import Lottie from "react-lottie";
import animationData from "../../utils/done.json";

const SubmitAnimation = () => {
  const defaultOptions = {
    loop: false, // Set loop to false
    autoplay: true, // Autoplay the animation
    animationData: animationData, // Animation data
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="flex flex-col items-center justify-center text-center">
      <Lottie options={defaultOptions} height={400} width={400} />
      <h2 className="text-green-700 italic text-xl font-bold">
        Thank you for Suggesting your vet !
      </h2>
    </div>
  );
};

export default SubmitAnimation;
