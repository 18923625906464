import React, { useState } from "react";
import { FormGroup, Input } from "../../ui/FormElements/FormElements";
import Form, { Field } from "rc-field-form";
import { EditIcon } from "../../Assets";
import Avatar from "react-avatar";
import { useAuth } from "../../context/authContext";
import Select from "react-select";
import { updateAccount } from "../../services/api.service";
import "./EditProfileModal.scss";
import axios from "../../services/axios";
import { toast } from "react-toastify";
import { MdPlace } from "react-icons/md";
import SingleAddress from "../../ui/FormElements/SingleAddress/SingleAddress";
import { getAddressLines } from "../../../utils/indexx";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";

const GenderValues = [
  {
    value: "male",
    label: "Male",
  },
  {
    value: "female",
    label: "Female",
  },
  {
    value: "I'd rather not say",
    label: "I'd rather not say",
  },
];

const EditProfilePage = () => {
  const navigate = useNavigate();
  const { user, updateUser } = useAuth();

  // Check if user data is available
  if (!user) {
    // Handle case where user data is not yet available
    return <div>Loading...</div>;
  }
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const [addresses, setAddresses] = React.useState([]);
  const [loadingAddresses, setLoadingAddresses] = React.useState(true);
  const [emailAlertEnabled, setEmailAlertEnabled] = React.useState(
    user?.emailAlertEnabled || false
  );
  const [smsAlertEnabled, setSmsAlertEnabled] = React.useState(
    user?.smsAlertEnabled || false
  );
  const [phone, setPhone] = React.useState(user?.phone || "");
  const [phoneError, setPhoneError] = useState(""); // State for phone number error

  React.useEffect(() => {
    fetchAddresses();
  }, []);

  const fetchAddresses = () => {
    axios
      .get("/users/addresses")
      .then((response) => {
        const data = response.data;
        if (data.success) {
          setAddresses(data.data);
        }
      })
      .catch((error) => {})
      .finally(() => {
        setLoadingAddresses(false);
      });
  };

  const handleSubmit = (values) => {
    const update = {
      firstName: values.firstName,
      lastName: values.lastName,
    };

    if (values.dob !== null) {
      update.dob = values.dob;
    }
    if (values.gender) {
      update.gender = values.gender;
    }
    if (values.phone !== null && values.phone !== "") {
      update.phone = values.phone;
    }
    if (emailAlertEnabled) {
      update.emailAlertEnabled = true;
    }
    if (smsAlertEnabled) {
      update.smsAlertEnabled = true;
    }

    updateAccount(update).then((res) => {
      const updatedUser = res.data;
      updateUser(updatedUser);
      toast.success("Profile updated successfully");
    });
  };

  const handleFileUpload = (e) => {
    const files = e.target.files;
    const file = files[0];
    const data = new FormData();
    data.append(`image`, file, file.name);

    axios
      .post("/file-upload", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(({ data: res }) => {
        const data = res.data;
        updateAccount({
          profilePicURL: data.location,
        }).then((res) => {
          const user = res.data;
          updateUser(user);
        });
      });
  };

  // Function to handle phone number input change
  const handlePhoneChange = (e) => {
    const newPhone = e.target.value;
    // Validate if input is a valid phone number (only digits)
    if (/^\d*$/.test(newPhone) || newPhone === "") {
      // Validate length
      if (newPhone.length <= 10) {
        setPhone(newPhone);
        setPhoneError("");
      } else {
        setPhoneError("Phone number should not exceed 10 digits");
      }
    } else {
      setPhoneError("Please enter only numbers");
    }
  };

  const navigateToEditAddress = (id) => {
    navigate(`/addresses/update/${id}`);
  };

  const deleteMutation = useMutation((id) => deleteAddress(id), {
    onSuccess: () => {
      toast.success("Address deleted successfully");
      queryClient.invalidateQueries("addresses");
    },
  });

  const handleDelete = (id) => {
    deleteMutation.mutate(id);
  };

  return (
    <div className="p-20">
      <Form
        className="create-form"
        onFinish={handleSubmit}
        form={form}
        initialValues={{
          firstName: user?.firstName,
          lastName: user?.lastName,
          email: user?.email,
          dob: user?.dob,
          gender: user?.gender,
          phone: user?.phone,
        }}
      >
        {(values, form) => {
          return (
            <div>
              <div className="row pb-5 mb-10">
                <div className="col-md-12 text-center">
                  <div>
                    <div className="d-block">
                      <Avatar
                        name={user.firstName + " " + user.lastName}
                        size="100"
                        round={true}
                        src={user.profilePicURL}
                        textSizeRatio={1}
                      />
                    </div>
                    <div className="btn btn-text d-inline-flex gap-2 file-upload-trigger">
                      <span className="fw-bold">Edit Photo</span>
                      <EditIcon />
                      <input
                        type="file"
                        className="file-upload"
                        accept="image/png, image/jpeg"
                        onChange={handleFileUpload}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <FormGroup>
                    <label htmlFor="firstName">First Name</label>
                    <Field
                      name="firstName"
                      rules={[
                        {
                          required: true,
                          message: "Please input your first name!",
                        },
                        {
                          pattern: /^[A-Za-z\s]+$/, // Add a pattern for valid characters
                          message:
                            "Invalid characters. Only letters and spaces are allowed.",
                        },
                      ]}
                    >
                      <Input
                        value={user.firstName}
                        error={form.getFieldError("firstName")[0]}
                      />
                    </Field>
                  </FormGroup>
                </div>
                <div className="col-md-6">
                  <FormGroup>
                    <label htmlFor="lastName">Last Name</label>
                    <Field
                      name="lastName"
                      rules={[
                        {
                          required: true,
                          message: "Please input your last name!",
                        },
                        {
                          pattern: /^[A-Za-z\s]+$/, // Add a pattern for valid characters
                          message:
                            "Invalid characters. Only letters and spaces are allowed.",
                        },
                      ]}
                    >
                      <Input
                        value={user?.lastName}
                        error={form.getFieldError("lastName")[0]}
                      />
                    </Field>
                  </FormGroup>
                </div>
                <div className="col-md-12">
                  <Field name="email">
                    <FormGroup>
                      <div>
                        <label htmlFor="email">Email</label>
                        <Input
                          value={user?.email}
                          error={form.getFieldError("email")[0]}
                          disabled={true}
                        />
                      </div>
                    </FormGroup>
                  </Field>
                </div>

                <div className="flex items-center mb-5">
                  <input
                    type="checkbox"
                    id="emailAlertCheckbox"
                    className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                    checked={emailAlertEnabled}
                    onChange={(e) => setEmailAlertEnabled(e.target.checked)}
                  />
                  <label htmlFor="emailAlertCheckbox" className="ml-2 ">
                    Use this email to receive EMAIL alerts for urgent or
                    important concerns regarding my account or shipments.
                  </label>
                </div>
                <div className="col-md-6">
                  <FormGroup>
                    <div>
                      <label htmlFor="dob">Birthdate</label>
                      <Field name="dob">
                        <Input
                          value={user?.dob || ""}
                          type="date"
                          max={new Date().toISOString().split("T")[0]} // Set max attribute to current date
                        />
                      </Field>
                    </div>
                  </FormGroup>
                </div>
                <div className="col-md-6">
                  <Field name="gender">
                    <FormGroup>
                      <div>
                        <label htmlFor="gender">Gender</label>
                        <div className="select-wrap">
                          <Select
                            name="gender"
                            className="react-select-custom"
                            classNamePrefix="react-select-custom"
                            options={GenderValues}
                            value={
                              GenderValues.find(
                                (v) => v.value === form.getFieldValue("gender")
                              ) || ""
                            }
                            onChange={({ value }) => {
                              form.setFieldValue("gender", value);
                            }}
                          />
                        </div>
                      </div>
                    </FormGroup>
                  </Field>
                </div>
                <div className="col-md-12">
                  <Field name="phone">
                    <FormGroup>
                      <div>
                        <label htmlFor="phone">Phone Number</label>
                        <Input
                          value={phone}
                          onChange={handlePhoneChange}
                          error={phoneError} // Pass error message to the Input component
                        />
                      </div>
                    </FormGroup>
                  </Field>
                </div>
              </div>
              <div className="flex items-center mb-2">
                <input
                  type="checkbox"
                  id="smsAlertCheckbox"
                  className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                  checked={smsAlertEnabled}
                  onChange={(e) => setSmsAlertEnabled(e.target.checked)}
                />
                <label htmlFor="smsAlertCheckbox" className="ml-2">
                  Use this mobile to receive SMS text alerts for urgent or
                  important concerns regarding my account or shipments.
                </label>
              </div>

              <div className="d-flex justify-content-between">
                <button className="btn btn-theme ml-auto mt-5" type="submit">
                  Update
                </button>
              </div>
            </div>
          );
        }}
      </Form>
    </div>
  );
};

export default EditProfilePage;
