import React, { useEffect, useState } from "react";
import axios from "axios";
import "./HomeBanner.scss";
import { SearchArrorRight, SearchIcon } from "../../Assets";
import AppCtaBanner from "../AppCtaBanner/AppCtaBanner";
import Footer from "../../core/Footer/Footer";
import { useAuth } from "../../context/authContext";
import { useNavigate } from "react-router-dom";
import leftBg from "../../../utils/Group.png";
import leftDog from "../../../utils/new-white-dog.png";
import RightBg from "../../../utils/Ellipse 307.png";
import RightDog from "../../../utils/shutterstock_52816996-removebg-preview 1.png";
import RightCircle from "../../../utils/Ellipse 308.png";
import RightCircleSmall from "../../../utils/Ellipse 309.png";
import { Modal, Button } from "antd";

const HeroBannerNew = () => {
  const ctx = useAuth();
  const navigate = useNavigate();
  const [hasPet, setHasPet] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/pet-profiles/user-pets");
        const petData = response.data;

        if (petData.success && petData.data && petData.data.length > 0) {
          setHasPet(true);
        } else {
          setHasPet(false);
          // setScrollModal(true);
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  const handlePetpage = () => {
    navigate("/myplans");
    window.location.reload();
  };

  return (
    <>
      <div className="h-fit md:h-[40rem] lg:h-screen bg-gradient-to-r from-[#FFE0BA] to-[#fcd09b] flex flex-col relative">
        <div className="border-2 border-amber-500 px-6 py-2 text-amber-600 text-center justify-center mx-auto mt-32 md:mt-10 lg:mt-20 md rounded-md shadow-md bg-white/5 font-bold z-50 ">
          CARSON & BEAR™ Dog Wellness Program
        </div>
        <div className=" px-6 py-2 text-gray-800 text-center justify-center mx-auto mt-5 rounded-md  md:w-2/3 lg:w-2/4 w-full text-2xl md:text-2xl lg:text-3xl font-bold z-40">
          Welcome to the only wellness program able to guarantee our program
          will improve health span and increase longevity, backed by our
          LifePromise™
        </div>
        <div className=" px-6 py-2 text-gray-800 text-center justify-center mx-auto  rounded-md  md:w-2/3 lg:w-2/5 w-full text-xl md:text-xl lg:text-2xl z-40 mb-20">
          Our science-based wellness platform makes dog parenting easy with a
          paw-some approach to nutrition, personalized by veterinarians for you
          and your fur baby.
        </div>
        {ctx.userLoaded && !ctx.user && (
          <div className="mx-auto mt-5 flex-row flex space-x-4 mb-20">
            <button
              className="bg-[#448EBE] py-3 px-5 rounded-lg text-white hover:scale-95 w-auto cursor-pointer z-50 "
              onClick={() => ctx.showModal("login")}
            >
              Log In
            </button>
            <button
              type="submit"
              className="bg-[#D97706] py-3 px-5 rounded-lg text-white hover:scale-95 w-auto cursor-pointer z-50 border-2 border-[#fcd09b] "
              onClick={() => ctx.showModal("signup")}
            >
              Sign Up
            </button>
          </div>
        )}
        {!loading && ctx.user && !hasPet && (
          <div className="mx-auto mt-5 flex-row flex space-x-4 z-50 mb-20">
            <button
              onClick={handlePetpage}
              className="bg-[#448EBE] py-3 px-5 rounded-lg text-white hover:scale-95 w-auto cursor-pointer z-50"
            >
              Add my first subscription
            </button>
          </div>
        )}

        <div className="hidden md:block lg:block">
          <img
            src={leftBg}
            className=" z-10 absolute  left-0 bottom-0 h-full md:h-[20rem] lg:h-[37rem]"
          />
          <img
            src={leftDog}
            className="z-20 absolute  left-0 bottom-0 h-full md:h-[20rem] lg:h-[26rem] "
          />
          <img
            src={RightBg}
            className="z-10 absolute  right-0 bottom-0 h-full md:h-[15rem] lg:h-[23rem]"
          />
          <img src={RightCircle} className="z-20 absolute  right-10 top-60" />
          <img
            src={RightCircleSmall}
            className="z-20 absolute  right-32 top-72 "
          />
          <img
            src={RightDog}
            className="z-20 absolute  right-0 bottom-0 h-full md:h-[20rem] lg:h-[31rem] "
          />
        </div>
      </div>
    </>
  );
};

export default HeroBannerNew;
