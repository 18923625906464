export const getAddressLines = (addressObject) => {
  if (!addressObject) {
    return ""; // or any default value or handle it appropriately
  }

  const { address, city, state, zipcode } = addressObject;

  // Check if essential address properties are present
  if (address && city && state && zipcode) {
    return ` ${address}, ${city}, ${state} ${zipcode}`;
  } else {
    return `${title}: ${address}`;
  }
};
