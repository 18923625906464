import React from "react";
import { AccountIcon, FAQIcon, SupportIcon } from "../../Assets";
import { Link } from "react-router-dom";
import { useAuth } from "../../context/authContext";

const FAQSectionUpdated = () => {
  const ctx = useAuth();

  const openChat = () => {
    if ($zoho && $zoho.salesiq) {
      $zoho.salesiq.floatwindow.visible("show");
    }
  };
  return (
    <div className="p-10 md:p-20 lg:p-28 grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-10 cursor-pointer  ">
      {ctx.userLoaded && !ctx.user && (
        <div className="p-5 flex flex-col justify-start items-start text-left border-3 hover:border-amber-500 border-cyan-500 rounded-xl shadow-md space-y-10  hover:scale-105 transition-transform duration-300 ease-in-out">
          <AccountIcon />
          <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold text-black">
            Account
          </h2>
          <p className="text-black">
            Already a Member of the Carson & Bear Dog Wellness Program™?
          </p>
          <p
            className="text-2xl text-amber-500 border-b-2 border-amber-500 font-semibold cursor-pointer"
            onClick={() => ctx.showModal("login")}
          >
            Login Here
          </p>
        </div>
      )}
      {ctx.user && (
        <Link to="/user" className="cursor-pointer">
          <div className="p-5 flex flex-col justify-start items-start text-left border-3 hover:border-amber-500 border-cyan-500 rounded-xl shadow-md space-y-10  hover:scale-105 transition-transform duration-300 ease-in-out">
            <AccountIcon />
            <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold text-black">
              Account
            </h2>
            <p className="text-black">
              Already a Member of the Carson & Bear Dog Wellness Program™?
            </p>
            <p className="text-2xl text-amber-500 border-b-2 border-amber-500 font-semibold">
              Login Here
            </p>
          </div>
        </Link>
      )}
      <a
        href="https://help.carsonandbearpets.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="p-5 flex flex-col justify-start items-start text-left border-3 hover:border-amber-500 border-cyan-500 rounded-xl shadow-md space-y-10 cursor-pointer hover:scale-105 transition-transform duration-300 ease-in-out">
          <FAQIcon />
          <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold text-black">
            FAQ
          </h2>
          <p className="text-black">
            Need More Information on the Carson & Bear Dog Wellness Program™?
          </p>
          <div className="text-2xl text-amber-500 border-b-2 border-amber-500 font-semibold">
            Check out our FAQ’s!
          </div>
        </div>
      </a>

      <div
        className="p-5 flex flex-col justify-start items-start text-left border-3 hover:border-amber-500 border-cyan-500 rounded-xl shadow-md space-y-10 cursor-pointer  hover:scale-105 transition-transform duration-300 ease-in-out"
        onClick={openChat}
      >
        <SupportIcon />
        <h2
          className="text-2xl md:text-3xl lg:text-4xl font-bold text-black"
          onClick={openChat}
        >
          Support
        </h2>
        <p className="text-black">
          Need Help? Member and Non-Member Support Available Here.
        </p>
        <p
          onClick={openChat}
          className="text-2xl text-amber-500 border-b-2 border-amber-500 font-semibold"
        >
          Contact Us!
        </p>
      </div>
    </div>
  );
};

export default FAQSectionUpdated;
