import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { deleteAddress, getAddresses } from "../../services/api.service";
import { getAddressLines } from "../../../utils/indexx";
import { toast } from "react-toastify";
import { AddressPlusIcon } from "../../Assets";
import axios from "axios";
import SingleAddressModernBox from "../../ui/FormElements/SingleAddress/SingleAddressModernBox";
import LoadingScreenWhile from "../../../pages/AddNewPet/LoadingScreenWhile";

export default function AddressList() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [addresses, setAddresses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAddresses = async () => {
      try {
        const response = await axios.get("/users/addresses");
        setAddresses(response.data.data);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchAddresses();
  }, []);
  const deleteMutation = useMutation((id) => deleteAddress(id), {
    onSuccess: () => {
      toast.success("Address deleted successfully");
      queryClient.invalidateQueries("addresses");
    },
  });

  const navigateToEditAddress = (id) => {
    navigate(`/addresses/update/${id}`);
  };

  const handleDelete = (id) => {
    deleteMutation.mutate(id);
  };

  return (
    <>
      {loading ? (
        <LoadingScreenWhile />
      ) : (
        <div className="address-list">
          <div className="container">
            <div className="row mt-5">
              <div className="col-md-12">
                <h2 className="text-center text-4xl font-semibold">
                  My Addresses
                </h2>
              </div>
            </div>
            <div className="row my-4 p-5 lg:p-20">
              <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-3 mx-auto">
                {addresses.map((address) => (
                  <div key={address.id}>
                    <SingleAddressModernBox
                      id={address.id}
                      title={address.title}
                      city={address.city}
                      state={address.state}
                      country={address.country}
                      zipcode={address.zipcode}
                      address={getAddressLines(address)}
                      onUpdate={() => navigateToEditAddress(address.id)}
                      onDelete={() => handleDelete(address.id)}
                      isDefault={address.isDefault}
                      isVerified={address.isVerified}
                      isBillingAddress={address.isBillingAddress}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="row my-5">
              <div className="col-md-12 text-center">
                {addresses.length === 0 && (
                  <p className="fs-5 text-muted">
                    No address found. Please add an address.
                  </p>
                )}
                <Link
                  className="px-5 border-0 py-3 bg-amber-600 d-inline-flex gap-2 align-items-center add-new-address-btn rounded text-decoration-none hover:scale-95 transition-transform duration-300 ease-in-out hover:bg-amber-500"
                  to={"/addresses/new"}
                >
                  <AddressPlusIcon />
                  <span className="text fs-5 ">Add New Address</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
