import { Fragment, useState, useEffect } from "react";
import axios from "axios";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Logo } from "../../Assets";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import "./HeaderAlt.scss";
import { useAuth } from "../../context/authContext";
import Modal from "../Modal/Modal";
import LoginAccount from "../../modals/LoginAccount/LoginAccount";
import ForgetPassword from "../../modals/ForgetPassword/ForgetPassword";
import ResetPassword from "../../modals/ResetPassword/ResetPassword";
import CreateAccount from "../../modals/CreateAccount/CreateAccount";
import Avatar from "react-avatar";
import classNames from "classnames";
import { MODAL_TYPES, useModals } from "../../context/modalContext";
import { clearAccessToken } from "../../services/localstorage";
import { FaBars, FaBell, FaTimes } from "react-icons/fa";
import { RiArrowDropDownLine } from "react-icons/ri";
const LoginModal = ({ open, onClose }) => {
  return (
    <Modal open={open} title={"Howdy!"} onClose={onClose}>
      <div className="p-4">
        <LoginAccount onClose={onClose} />
      </div>
    </Modal>
  );
};

const ForgetPasswordModal = ({ open, onClose }) => {
  return (
    <Modal open={open} title={"Howdy!"} onClose={onClose}>
      <div className="p-4">
        <ForgetPassword onClose={onClose} />
      </div>
    </Modal>
  );
};

const ChangePasswordModal = ({ open, onClose }) => {
  return (
    <Modal open={open} title={"Howdy!"} onClose={onClose}>
      <div className="p-4">
        <ForgetPassword onClose={onClose} />
      </div>
    </Modal>
  );
};

const ResetPasswordModal = ({ open, onClose }) => {
  return (
    <Modal open={open} title={"Howdy!"} onClose={onClose}>
      <div className="p-4">
        <ResetPassword onClose={onClose} />
      </div>
    </Modal>
  );
};

const SignupModal = ({ open, onClose, plansRoute, isOpen, onlogin }) => {
  return (
    <Modal open={open} title={"Howdy!"} onClose={onClose}>
      <div className="p-4">
        <CreateAccount
          onClose={onClose}
          plansRoute={plansRoute}
          onlogin={onlogin}
          isOpen={isOpen}
        />
      </div>
    </Modal>
  );
};

export default function NewHeader() {
  const ctx = useAuth();

  const modalCtx = useModals();
  const navigate = useNavigate();

  let [searchParams, setSearchParams] = useSearchParams();
  const show = searchParams.get("show");
  const [visible, setVisible] = useState(true);
  const [hasSubscription, setHasSubscription] = useState(false);

  const handleVisible = () => {
    let url = window?.location.pathname;

    if (
      url === "/stripe-redirect-checkout" ||
      url === "/checkoutPayment/success"
    ) {
      setVisible(false);
    }
  };
  useEffect(() => {
    handleVisible();
  }, []);
  const isOpen = new URLSearchParams(location.search).get("isOpen");
  const isOpenLogin = new URLSearchParams(location.search).get("isOpenLogin");

  useEffect(() => {
    const fetchSubscriptionData = async () => {
      try {
        const subscriptionResponse = await axios.get("/users/subscription");
        const subscriptionData = subscriptionResponse.data;

        if (
          subscriptionResponse.status === 400 &&
          subscriptionData.messageCode === "CUSTOMER_HAS_NO_SUBSCRIPTION"
        ) {
          setHasSubscription(false);
        } else {
          setHasSubscription(true);
        }
      } catch (error) {
        // Handle error and set hasSubscription to false

        setHasSubscription(false);
      }
    };

    fetchSubscriptionData();
  }, []);

  const logout = (e) => {
    e.preventDefault();
    ctx.updateUser(null);
    clearAccessToken();
    window.location.href = "/";
  };
  const handleDelete = () => {
    axios
      .delete("/users/delete-account")
      .then((response) => {
        ctx.updateUser(null);
        localStorage.clear();
        clearAccessToken();
        navigate("/");
      })
      .catch((error) => {});
  };
  const showChangePasswordModal = () => {
    modalCtx.showModal({
      modalType: MODAL_TYPES.CHANGE_PASSWORD,
      modalData: {},
    });
  };

  const handlePetpage = () => {
    navigate("/pets");
    window.location.reload();
  };
  const handlePlanpage = () => {
    navigate("/myplans");
    window.location.reload();
  };
  const handleCardpage = () => {
    navigate("/select-card");
  };
  const handleProfile = () => {
    navigate("/user");
  };
  const handleAddress = () => {
    navigate("/addresses");
  };
  return (
    <Disclosure as="nav" className="bg-white shadow-sm p-2">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-2 sm:px-4 lg:px-8">
            <div className="flex h-16 justify-between">
              <div className="flex px-2 lg:px-0">
                <div className="flex flex-shrink-0 items-center">
                  <Link to={"/"}>
                    <Logo />
                  </Link>
                </div>
              </div>
              <div className="flex flex-1 items-center justify-center px-2 lg:ml-6 lg:justify-end z-50">
                <Menu
                  as="div"
                  className="relative ml-4 flex-shrink-0 hidden md:block"
                >
                  {!ctx.user && (
                    <div className="mx-auto  flex-row flex space-x-4">
                      <button
                        className="bg-[#448EBE] py-3 px-5 rounded-lg text-white hover:scale-95 w-auto cursor-pointer z-50 "
                        onClick={() => ctx.showModal("login")}
                      >
                        Log In
                      </button>
                      <button
                        type="submit"
                        className="bg-[#D97706] py-3 px-5 rounded-lg text-white hover:scale-95 w-auto cursor-pointer z-50 "
                        onClick={() => ctx.showModal("signup")}
                      >
                        Sign Up
                      </button>
                    </div>
                  )}
                </Menu>
              </div>
              <div className="flex items-center lg:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-amber-500">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <FaTimes className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <FaBars className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>

              {ctx.user && (
                <div className="hidden  lg:flex lg:items-center z-50">
                  {/* Profile dropdown */}
                  <Menu as="div" className="relative ml-4 ">
                    <div>
                      <Menu.Button className="flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-amber-500 focus:ring-offset-2">
                        <Avatar
                          name={ctx.user?.firstName + " " + ctx.user?.lastName}
                          size="50"
                          round={true}
                          src={ctx.user?.profilePicURL}
                        />
                        <RiArrowDropDownLine className="text-5xl" />
                      </Menu.Button>
                    </div>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ">
                        <Menu.Item className="block transition duration-300 ease-in-out hover:bg-gray-100">
                          {({ active }) => (
                            <Link
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                              to={"/user"}
                            >
                              Profile
                            </Link>
                          )}
                        </Menu.Item>

                        <Menu.Item className="block transition duration-300 ease-in-out hover:bg-gray-100">
                          {({ active }) => (
                            <div
                              className={classNames(
                                active ? "bg-gray-100 cursor-pointer" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                              onClick={showChangePasswordModal}
                            >
                              Change Password
                            </div>
                          )}
                        </Menu.Item>
                        <Menu.Item className="block transition duration-300 ease-in-out hover:bg-gray-100">
                          {({ active }) => (
                            <Link
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                              to={"/addresses"}
                            >
                              My Addresses
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item className="block transition duration-300 ease-in-out hover:bg-gray-100">
                          {({ active }) => (
                            <div
                              onClick={handlePetpage}
                              className={classNames(
                                active ? "bg-gray-100 cursor-pointer" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                            >
                              Pet Details
                            </div>
                          )}
                        </Menu.Item>
                        <Menu.Item className="block transition duration-300 ease-in-out hover:bg-gray-100">
                          {({ active }) => (
                            <div
                              onClick={handlePlanpage}
                              className={classNames(
                                active ? "bg-gray-100 cursor-pointer" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                            >
                              Plans
                            </div>
                          )}
                        </Menu.Item>
                        {hasSubscription && (
                          <Menu.Item className="block transition duration-300 ease-in-out hover:bg-gray-100">
                            {({ active }) => (
                              <div
                                onClick={handleCardpage}
                                className={classNames(
                                  active ? "bg-gray-100 cursor-pointer" : "",
                                  "block px-4 py-2 text-sm text-gray-700"
                                )}
                              >
                                Payment Cards
                              </div>
                            )}
                          </Menu.Item>
                        )}

                        <Menu.Item className="block transition duration-300 ease-in-out hover:bg-gray-100">
                          {({ active }) => (
                            <a
                              target="_blank"
                              href="https://carsonandbearpets.com/privacy-policy/"
                              className={classNames(
                                active ? "bg-gray-100 cursor-pointer" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                            >
                              Privacy Policy
                            </a>
                          )}
                        </Menu.Item>
                        <Menu.Item className="block transition duration-300 ease-in-out hover:bg-gray-100">
                          {({ active }) => (
                            <a
                              target="_blank"
                              href="https://carsonandbearpets.com/terms-and-conditions/"
                              className={classNames(
                                active ? "bg-gray-100 cursor-pointer" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                            >
                              Terms &amp; Conditions
                            </a>
                          )}
                        </Menu.Item>
                        <Menu.Item className="block transition duration-300 ease-in-out hover:bg-gray-100">
                          {({ active }) => (
                            <a
                              target="_blank"
                              href="https://help.carsonandbearpets.com/portal/en/newticket"
                              className={classNames(
                                active ? "bg-gray-100 cursor-pointer" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                            >
                              Get Help
                            </a>
                          )}
                        </Menu.Item>
                        <Menu.Item className="block transition duration-300 ease-in-out hover:bg-gray-100">
                          {({ active }) => (
                            <a
                              href="/"
                              onClick={logout}
                              className={classNames(
                                active ? "bg-gray-100 cursor-pointer" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                            >
                              Logout
                            </a>
                          )}
                        </Menu.Item>
                        {/* <Menu.Item className="block transition duration-300 ease-in-out hover:bg-gray-100">
                          {({ active }) => (
                            <a
                              href="/"
                              onClick={handleDelete}
                              className={classNames(
                                active ? "bg-gray-100 cursor-pointer" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                            >
                              Delete Account
                            </a>
                          )}
                        </Menu.Item> */}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              )}
            </div>
          </div>

          {ctx.user && (
            <Disclosure.Panel className="lg:hidden">
              <div className="flex items-center px-4 mt-5 mb-4">
                <div className="flex-shrink-0">
                  <Avatar
                    name={ctx.user?.firstName + " " + ctx.user?.lastName}
                    size="40"
                    className="h-10 w-10 rounded-full"
                    round={true}
                    src={ctx.user?.profilePicURL}
                  />
                </div>
                <div className="ml-3">
                  <div className="text-base font-medium text-gray-800">
                    {ctx.user?.firstName + " " + ctx.user?.lastName}
                  </div>
                  <div className="text-sm font-medium text-gray-500">
                    {ctx.user?.email}
                  </div>
                </div>
              </div>
              <div className="border-t border-gray-200 pb-3 pt-2">
                <div className="mt-3 space-y-1">
                  <Disclosure.Button
                    as="a"
                    onClick={handleProfile}
                    className="block px-4 py-2 text-base font-medium text-gray-700 bg-gray-100 hover:bg-amber-600 hover:bg-opacity-50 rounded-lg hover:text-gray-800"
                  >
                    <button onClick={handleProfile}>Profile</button>
                  </Disclosure.Button>
                  <Disclosure.Button
                    onClick={showChangePasswordModal}
                    as="a"
                    className="block px-4 py-2 text-base font-medium text-gray-700 bg-gray-100 hover:bg-amber-600 hover:bg-opacity-50 rounded-lg hover:text-gray-800"
                  >
                    <button
                      className="text-start"
                      onClick={showChangePasswordModal}
                    >
                      Change Password
                    </button>
                  </Disclosure.Button>
                  <Disclosure.Button
                    as="a"
                    onClick={handleAddress}
                    className="block px-4 py-2 text-base font-medium text-gray-700 bg-gray-100 hover:bg-amber-600 hover:bg-opacity-50 rounded-lg hover:text-gray-800"
                  >
                    <Link className="text-start" to={"/addresses"}></Link>
                    <button onClick={handleAddress}>My Addresses</button>
                  </Disclosure.Button>
                  <Disclosure.Button
                    onClick={handlePetpage}
                    as="a"
                    className="block px-4 py-2 text-base font-medium text-gray-700 bg-gray-100 hover:bg-amber-600 hover:bg-opacity-50 rounded-lg hover:text-gray-800 w-full"
                  >
                    <button onClick={handlePetpage}>Pet Details</button>
                  </Disclosure.Button>

                  <Disclosure.Button
                    as="a"
                    onClick={handlePlanpage}
                    className="block px-4 py-2 text-base font-medium text-gray-700 bg-gray-100 hover:bg-amber-600 hover:bg-opacity-50 rounded-lg hover:text-gray-800"
                  >
                    <button onClick={handlePlanpage}>Plans</button>
                  </Disclosure.Button>

                  {hasSubscription && (
                    <Disclosure.Button
                      onClick={handleCardpage}
                      className="block px-4 py-2 text-base font-medium text-gray-700 bg-gray-100 hover:bg-amber-600 hover:bg-opacity-50 rounded-lg hover:text-gray-800 w-full text-left"
                    >
                      <button onClick={handleCardpage}>Payment cards</button>
                    </Disclosure.Button>
                  )}
                  <Disclosure.Button
                    as="a"
                    className="block px-4 py-2 text-base font-medium text-gray-700 bg-gray-100 hover:bg-amber-600 hover:bg-opacity-50 rounded-lg hover:text-gray-800"
                    href="https://carsonandbearpets.com/privacy-policy/"
                    target="_blank"
                  >
                    Privacy Policy
                  </Disclosure.Button>

                  <Disclosure.Button
                    as="a"
                    className="block px-4 py-2 text-base font-medium text-gray-700 bg-gray-100 hover:bg-amber-600 hover:bg-opacity-50 rounded-lg hover:text-gray-800"
                    href="https://carsonandbearpets.com/terms-and-conditions/"
                    target="_blank"
                  >
                    Terms &amp; Conditions
                  </Disclosure.Button>
                  <Disclosure.Button
                    as="a"
                    className="block px-4 py-2 text-base font-medium text-gray-700 bg-gray-100 hover:bg-amber-600 hover:bg-opacity-50 rounded-lg hover:text-gray-800"
                    href="https://help.carsonandbearpets.com/portal/en/newticket"
                    target="_blank" // Add this line to open in a new tab
                  >
                    Get Help
                  </Disclosure.Button>
                  {/* <Disclosure.Button
                    as="a"
                    className="block px-4 py-2 text-base font-medium text-gray-700 bg-gray-100 hover:bg-amber-600 hover:bg-opacity-50 rounded-lg hover:text-gray-800"
                  >
                    <a href="https://help.carsonandbearpets.com/portal/en/newticket">
                      Get Help
                    </a>
                  </Disclosure.Button> */}

                  <Disclosure.Button
                    onClick={logout}
                    as="a"
                    className="block px-4 py-2 text-base font-medium text-gray-700 bg-gray-100 hover:bg-amber-600 hover:bg-opacity-50 rounded-lg hover:text-gray-800"
                  >
                    <a href="/" onClick={logout}>
                      Logout
                    </a>
                  </Disclosure.Button>

                  {/* <Disclosure.Button
                    onClick={handleDelete}
                    as="a"
                    className="block px-4 py-2 text-base font-medium text-gray-700 bg-gray-100 hover:bg-amber-600 hover:bg-opacity-50 rounded-lg hover:text-gray-800"
                  >
                    <a href="/" onClick={handleDelete}>
                      Delete Account
                    </a>
                  </Disclosure.Button> */}
                </div>
              </div>
            </Disclosure.Panel>
          )}
          {!ctx.user && (
            <Disclosure.Panel className="lg:hidden">
              <div className="flex items-center justify-center mt-3">
                <div className="mx-auto mt-5 flex-row flex space-x-4">
                  <button
                    className="bg-[#448EBE] py-3 px-5 rounded-lg text-white hover:scale-95 w-auto cursor-pointer z-50 border-b-4 border-[#326a8f]"
                    onClick={() => ctx.showModal("login")}
                  >
                    Log In
                  </button>
                  <button
                    type="submit"
                    className="bg-[#D97706] py-3 px-5 rounded-lg text-white hover:scale-95 w-auto cursor-pointer z-50 border-b-4 border-[#9c5606]"
                    onClick={() => ctx.showModal("signup")}
                  >
                    Sign Up
                  </button>
                </div>
              </div>
            </Disclosure.Panel>
          )}
          <LoginModal
            open={ctx.modals.login || isOpenLogin} // Opening login modal if isOpen is true
            onClose={() => {
              ctx.hideModal("login");

              setSearchParams((params) => {
                const newParams = new URLSearchParams(params);
                newParams.delete("isOpenLogin");
                return newParams;
              });
            }}
          />
          <SignupModal
            open={ctx.modals.signup || isOpen}
            plansRoute={() => {
              navigate("/myplans");
            }}
            onClose={() => {
              ctx.hideModal("signup");

              setSearchParams((params) => {
                const newParams = new URLSearchParams(params);
                newParams.delete("isOpen");
                return newParams;
              });

              // window.location.reload();
            }}
            onlogin={() => {
              ctx.hideModal("signup");

              setSearchParams((params) => {
                const newParams = new URLSearchParams(params);
                newParams.delete("isOpen");
                return newParams;
              });
            }}
            isOpen={isOpen}
          />
          <ForgetPasswordModal
            open={ctx.modals.forget}
            onClose={() => ctx.hideModal("forget")}
          />
        </>
      )}
    </Disclosure>
  );
}
