import React from "react";
import Modal from "../../core/Modal/Modal";
import Form, { Field } from "rc-field-form";
import { MODAL_TYPES, useModals } from "../../context/modalContext";
import "./CancelSubscriptionModal.scss";
import { cancelSubscription, deletePet } from "../../services/api.service";
import { toast } from "react-toastify";

export default function CancelSubscriptionModal({ data }) {
  const [form] = Form.useForm();
  const [cancelled, setCancelled] = React.useState(false);

  const modalCtx = useModals();

  const closeModal = () => {
    modalCtx.hideModal(MODAL_TYPES.CANCEL_SUBSCRIPTION);
  };

  function formatErrorMessage(code) {
    const formattedMessage = code
      .replace(/_/g, " ")
      .toLowerCase()
      .replace(/(?:^|\s)\w/g, (match) => match.toUpperCase());

    return formattedMessage.replace("Cant", "Can't");
  }
  const cancelSubscriptionHandler = () => {
    const petId = data.petId;

    cancelSubscription(petId)
      .then((cancelSubscriptionRes) => {
        return deletePet(petId);
      })
      .then((deletePetRes) => {
        setCancelled(true);
        window.location.reload();
      })
      .catch((err) => {
        modalCtx.hideModal(MODAL_TYPES.CANCEL_SUBSCRIPTION);
        const errorMessage = formatErrorMessage(
          "You cannot cancel the last pet."
        );
        toast.error(errorMessage);
      });
  };

  return (
    <Modal open={true} onClose={closeModal} form={form} width={550}>
      <div className="p-3 mb-3">
        {!cancelled && (
          <Form className="auth-form" form={form}>
            <div>
              <div className="row pb-5">
                <div className="col-md-12 text-center fw-700">
                  <h3 className="fw-700">
                    Are you sure you want to cancel your subscription?
                  </h3>
                </div>
                {/* <p>{data.petId} </p> */}
              </div>
              <div className="align-items-center mt-4">
                <button
                  className="btn btn-theme w-100 rounded"
                  onClick={cancelSubscriptionHandler}
                >
                  Yes
                </button>
                <button
                  type="submit"
                  className="btn btn-theme-light w-100 mt-3"
                  onClick={closeModal}
                >
                  No
                </button>
              </div>
            </div>
          </Form>
        )}
        {cancelled && (
          <div className="px-5 text-center">
            <h3>Your subscription has been cancelled.</h3>
            <p>Please contact us if you have any questions.</p>
            <button
              className="btn btn-primary px-5 py-2 mt-4"
              onClick={closeModal}
            >
              Okay
            </button>
          </div>
        )}
      </div>
    </Modal>
  );
}
