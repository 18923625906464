import React from "react";
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
const NewFormAddCard = ({ clientId, clientSecret }) => {
  const stripe = useStripe();
  const elements = useElements();

  if (!clientId || !clientSecret) {
    return;
  }
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    const redirectUrl =
      window.location.origin +
      `/checkout/success/new?payment_intent=${clientId}&client_secret=${clientSecret}`;

    const result = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: redirectUrl,
      },
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)

      toast.error(result.error.code);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />

      <div className="px-2 flex">
        <button className="text-white p-3 font-bold w-full mt-3 mx-auto bg-amber-600 rounded-md justify-center items-center text-center flex hover:scale-95 hover:shadow-lg hover:shadow-orange-600 transition-transform ease-in-out duration-300">
          Submit card
        </button>
      </div>
    </form>
  );
};

export default NewFormAddCard;
