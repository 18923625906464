import React, { useState } from "react";
import { Logo } from "../Assets";
import { FaLock, FaEye, FaEyeSlash } from "react-icons/fa";
import { PiEyeClosedDuotone } from "react-icons/pi";
import Lottie from "react-lottie";

import animationData from "../../utils/dog.json";
import axios from "axios";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const ResetPassword = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const { token } = useParams();

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();

    const password = e.target.password.value;
    const confirmPassword = e.target.confirmPassword.value;

    setPasswordError("");
    setConfirmPasswordError("");

    if (!password || password.length < 8) {
      setPasswordError("Password must be at least 8 characters long.");
      return;
    }

    if (password !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match.");
      return;
    }

    const passwordResetToken = window.location.pathname.split("/").pop();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_SERVER}/users/reset-password`,
        {
          passwordResetToken,
          password,
        }
      );

      toast.success("Password reset successful");
    } catch (error) {
      toast.error("Password reset failed");
    }
  };

  return (
    <div className="flex flex-col lg:flex-row h-screen items-center justify-center scrollbar-hide">
      <div className="hidden lg:block w-1/2 bg-gradient-to-r from-amber-500 to-amber-400 h-screen flex items-center justify-center p-36">
        <Lottie
          options={{
            loop: true,
            autoplay: true,

            animationData: animationData,
            rendererSettings: {
              preserveAspectRatio: "xMidYMid slice",
            },
          }}
          height={400}
          width={400}
        />
      </div>
      <div className="w-full lg:w-1/2 bg-white p-8 lg:p-20 rounded-lg h-auto">
        <h2 className="text-3xl mb-6 font-semibold text-gray-800 text-center lg:text-left">
          Reset Password
        </h2>
        <form
          className="max-w-md mx-auto lg:mx-0"
          onSubmit={handleResetPassword}
        >
          <div className="mb-6">
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-600"
            >
              <i className="fas fa-lock mr-2 text-gray-400"></i>Enter Password
            </label>
            <div className="relative">
              <input
                type={passwordVisible ? "text" : "password"}
                placeholder="Enter new password"
                id="password"
                name="password"
                className="mt-1 p-2 w-full border rounded-md outline-none"
              />
              <span
                className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer"
                onClick={togglePasswordVisibility}
              >
                {passwordVisible ? (
                  <FaEye className="text-xl" />
                ) : (
                  <PiEyeClosedDuotone className="text-xl" />
                )}
              </span>
            </div>
            {passwordError && (
              <p className="text-red-500 text-xs mt-1">{passwordError}</p>
            )}
          </div>
          <div className="mb-6">
            <label
              htmlFor="confirmPassword"
              className="block text-sm font-medium text-gray-600"
            >
              <i className="fas fa-lock mr-2 text-gray-400"></i>Confirm Password
            </label>
            <div className="relative">
              <input
                type={confirmPasswordVisible ? "text" : "password"}
                id="confirmPassword"
                name="confirmPassword"
                placeholder="Confirm password"
                className="mt-1 p-2 w-full border rounded-md outline-none"
              />
              <span
                className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer"
                onClick={toggleConfirmPasswordVisibility}
              >
                {confirmPasswordVisible ? (
                  <FaEye className="text-xl" />
                ) : (
                  <PiEyeClosedDuotone className="text-xl" />
                )}
              </span>
            </div>
            {confirmPasswordError && (
              <p className="text-red-500 text-xs mt-1">
                {confirmPasswordError}
              </p>
            )}
          </div>
          <button
            type="submit"
            className="bg-amber-500 text-white p-2 rounded-md w-full hover:bg-amber-600"
          >
            Reset Password
          </button>
        </form>
        <div className="flex flex-col mt-4 text-center lg:text-left">
          <p className="text-gray-500 text-xs">
            Copyright © 2024 Carson&Bear. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
