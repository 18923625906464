import React from "react";
import "./Modal.scss";
import { CloseModalIcon } from "../../Assets";

export default function Modal({
  open,
  title,
  children,
  onClose,
  width = 500,
  closeIcon = true,
  type,
}) {
  if (!open) return null;

  return (
    <div className="modal " role="dialog">
      <div
        className={`modal-dialog animate__animated animate__bounceIn animate__fast ${
          type === "otp" ? "mt-32" : ""
        }`}
        role="document"
        style={{
          minWidth: `${width}px`,
        }}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{title}</h5>
          </div>
          {closeIcon && (
            <button type="button" className="close" onClick={onClose}>
              <CloseModalIcon />
            </button>
          )}
          <div className="modal-body">{children}</div>
        </div>
      </div>
    </div>
  );
}
