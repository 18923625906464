import React from "react";
import twodogs from "../../utils/widedogs.png";
import threedogs from "../../utils/3dogs.png";
// import "./twoplans.css";

const TwoPlans = () => {
  return (
    <div
      className="w-full p-4 justify-center items-center hidden md:hidden lg:block"
      style={{ userSelect: "none", pointerEvents: "none" }}
    >
      <img src={twodogs} className="mx-auto" />
      {/* <div className="w-1/2 px-10 flex flex-col">
        <h2 className="text-[#ff9a26] text-2xl md:text-3xl lg:text-5xl font-bold mb-10">
          Getting started is <br /> simple
        </h2>

        <div className="relative z-10 mb-5 flex w-60 text-[1rem] md:text-[1.3rem] lg:text-[1.6rem] text-black flex-col mt-2">
          Select a plan from below, enter your pup's details, and you're all
          set.
          <p className="text-[1rem] md:text-[1.3rem] lg:text-[1.6rem] text-black mt-2">
            Take the first step toward helping your dog live a longer, healthier
            life.
          </p>
        </div>

        <img
          src={twodogs}
          alt="twodogs"
          className="w-44 md:w-72 lg:w-96 absolute left-96  mt-20"
        />
      </div>

      <div className="h-[31rem] w-1 bg-[#ffa33a]"></div>

      <div className="w-1/2 px-10 flex flex-col relative">
        <h2 className="text-[#448EBE] text-2xl md:text-3xl lg:text-5xl font-bold">
          Adding more dogs? <br /> Easy!
        </h2>

        <div className="relative z-10 mb-5 flex text-[1rem] md:text-[1.3rem] lg:text-[1.6rem] text-black flex-col mt-2">
          <img src={threedogs} alt="threedogs" className="w-full  " />

          <p className="text-[1rem] md:text-[1.3rem] lg:text-[1.6rem] text-black  relative z-20">
            No repeat tech costs mean more savings for you - we guarantee we'll
            always fetch the lowest price possible for your pack.
          </p>
        </div>
      </div> */}
    </div>
  );
};

export default TwoPlans;
