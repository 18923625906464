import React, { useEffect } from "react";
import Modal from "../../core/Modal/Modal";
import { FormGroup, Input } from "../../ui/FormElements/FormElements";
import Form, { Field } from "rc-field-form";
import Select from "react-select";
import { MODAL_TYPES, useModals } from "../../context/modalContext";
import "./PetDeceaseModal.scss";
import { getDeceasedReasons, markAsDeceased } from "../../services/api.service";
import { useMutation, useQuery, useQueryClient } from "react-query";

export default function PetDeceaseModal({ data }) {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const [otherVisible, setOtherVisible] = React.useState(false);

  const modalCtx = useModals();

  const closeModal = () => {
    modalCtx.hideModal(MODAL_TYPES.PET_DECEASE);
  };

  const { data: reasons = [] } = useQuery("reasons", () =>
    getDeceasedReasons()
  );
  const updatePet = useMutation(({ id, body }) => markAsDeceased(id, body), {
    onSuccess: () => {
      queryClient.invalidateQueries("pets");
      closeModal();
    },
  });

  const handleSubmit = (values) => {
    updatePet.mutate({
      id: data.petId,
      body: {
        deceasedReason:
          values.reason === "Other" ? values.otherReason : values.reason,
      },
    });
  };

  return (
    <Modal
      open={true}
      title={"Please select a reason"}
      onClose={closeModal}
      form={form}
    >
      <div className="p-4 mb-2">
        <Form className="auth-form" form={form} onFinish={handleSubmit}>
          <div>
            <div className="row">
              <div className="col-md-12 mb-3">
                <Field name="reason">
                  <FormGroup>
                    <div>
                      <div className="select-wrap">
                        <Select
                          placeholder="Select Your Reason"
                          name="reason"
                          className="react-select-custom"
                          classNamePrefix="react-select-custom"
                          options={reasons.map((reason) => ({
                            value: reason,
                            label: reason,
                          }))}
                          onChange={(e) => {
                            form.setFieldsValue({
                              reason: e.value,
                            });

                            if (e.value === "Other") {
                              setOtherVisible(true);
                            } else {
                              setOtherVisible(false);
                            }
                          }}
                        />
                      </div>
                    </div>
                  </FormGroup>
                </Field>
                {otherVisible}
                {otherVisible && (
                  <FormGroup>
                    <Field name="otherReason">
                      <label htmlFor="reason">Please enter your reason</label>
                      <textarea
                        placeholder="Please enter your reason"
                        name="otherReason"
                        id="otherReason"
                        type="text"
                        className="form-control"
                        rows={5}
                        onChange={(e) => {
                          form.setFieldsValue({
                            otherReason: e.target.value,
                          });
                        }}
                      />
                    </Field>
                  </FormGroup>
                )}
              </div>
            </div>

            <div className="align-items-center mt-5">
              <button className="btn btn-theme w-100 rounded">Submit</button>
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
}
