import React, { useState, useEffect } from "react";
import { RadioGroup } from "@headlessui/react";
import { FaCheck } from "react-icons/fa";
import { FaPlus } from "react-icons/fa";
import { initSubscription } from "../../services/api.service";
import { Spin } from "antd";
import axios from "axios";
import { FaCcVisa } from "react-icons/fa";
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { loadStripe } from "@stripe/stripe-js";
import { Modal, Button } from "antd";
import LoadingScreenTrans from "../../../pages/AddNewPet/LoadingScreenTrans";
import LoadingFormLoader from "../../../pages/AddNewPet/LoadingFormLoader";
import NewFormAddCard from "./NewFormAddCard";
import { FaCcMastercard } from "react-icons/fa";
import { SiAmericanexpress } from "react-icons/si";
import { BsFillCreditCard2FrontFill } from "react-icons/bs";
import DontHaveSubscription from "../../../pages/Checkout/DontHaveSubscription";
import { motion, AnimatePresence } from "framer-motion";

// Test Mode
const stripePromise = loadStripe(
  "pk_test_51MvOEbGcnkPKGaUh0bexrc8MU4uAzDOwk43ksV972EEH4r8SGJjStxlHouTJaHYio5MWifq24ivcaWM1J0YZowqd009ddkR8eV"
);

// Live Mode
// const stripePromise = loadStripe(
//   "pk_live_51MvOEbGcnkPKGaUh3cIsv2Kv7FjqxygDjVgRhOvDIqWzmIFyVEUDboXl2X0Ez5rTzfeF7Amx9fHXdHWppF1sEUAp001S1t5Aui"
// );
const SelectCard = () => {
  const [selectedCard, setSelectedCard] = useState([]);

  const [intentError, setIntentError] = useState(null);
  const [intent, setIntent] = useState();
  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisibleTwo, setModalVisibleTwo] = useState(false);
  const [stripeNew, setStripeNew] = useState("");
  const [elementNew, setElementNew] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const [defaultCardId, setDefaultCardId] = useState(null);
  const [hasSubscription, setHasSubscription] = useState(true);
  const [modalCardDetails, setModalCardDetails] = useState(null);

  useEffect(() => {
    // Fetch selected card data here

    fetchData();
  }, []);
  useEffect(() => {
    // Scroll to the top of the component when it loads
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const fetchSubscriptionData = async () => {
      try {
        const subscriptionResponse = await axios.get("/users/subscription");
        const subscriptionData = subscriptionResponse.data;

        if (
          subscriptionResponse.status === 400 &&
          subscriptionData.messageCode === "CUSTOMER_HAS_NO_SUBSCRIPTION"
        ) {
          setHasSubscription(false);
        } else {
          setHasSubscription(true);
        }
      } catch (error) {
        // Handle error and set hasSubscription to false
        //         setHasSubscription(false);
      }
    };

    fetchSubscriptionData();
  }, []);
  const fetchData = async () => {
    setLoadingPage(true);
    try {
      const response = await axios.get("/users/stripe/get-payment-methods");
      const data = response.data.data;
      setSelectedCard(data);

      // Find the default card
      const defaultCard = data.find((card) => card.isDefault === true);
      if (defaultCard) {
        setDefaultCardId(defaultCard.id);
      }
      setLoadingPage(false);
    } catch (error) {
      //       // Handle error
      setLoadingPage(false);
    }
  };
  const handleUpdateIntent = () => {
    setLoading(true);
    initSubscription()
      .then((res) => {
        setIntent(res.data);
      })
      .catch((err) => setIntentError("error"))
      .finally(() => setLoading(false));
  };
  const handleAddCard = () => {
    handleUpdateIntent();
    setModalVisible(true);
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  const handleCardModalCancel = () => {
    setModalVisibleTwo(false);
    fetchData();
  };
  const colorSchemes = [
    { from: "from-orange-500", to: "to-red-700" },
    { from: "from-blue-300", to: "to-rose-400" },

    { from: "from-yellow-500", to: "to-green-400" },
    { from: "from-teal-400", to: "to-cyan-500" },
    { from: "from-cyan-400", to: "to-purple-500" },
    { from: "from-sky-500", to: "to-pink-600" },
    { from: "from-purple-400", to: "to-indigo-500" },

    { from: "from-amber-400", to: "to-lime-500" },
    { from: "from-pink-400", to: "to-blue-500" },
  ];

  const handleMarkAsDefault = async (paymentMethodId) => {
    try {
      await axios.put(
        `/users/stripe/make-default-payment-method/${paymentMethodId}`
      );

      toast.success("Payment method marked as default.");

      handleCardModalCancel();
      window.scrollTo(0, 0);
    } catch (error) {
      toast.error(error.message);
      handleCardModalCancel();
    }
  };
  const handleDeleteCard = async (paymentMethodId) => {
    try {
      await axios.delete(
        `/users/stripe/delete-payment-method/${paymentMethodId}`
      );

      toast.success("Payment Card deleted ");

      handleCardModalCancel();
      window.scrollTo(0, 0);
    } catch (error) {
      toast.error(error.message);
      handleCardModalCancel();
    }
  };
  const handleCardClick = (card) => {
    setModalCardDetails(card);
    setModalVisibleTwo(true);
  };

  return (
    <>
      {loadingPage ? (
        <LoadingScreenTrans />
      ) : hasSubscription ? (
        <div>
          <div className="flex">
            <h1 className="text-4xl mx-auto font-bold text-black mt-10 mb-10">
              Payment Cards
            </h1>
          </div>

          <div className="">
            {Array.isArray(selectedCard) ? (
              <RadioGroup
                value={selectedCard}
                onChange={setSelectedCard}
                className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-col-3 gap-4"
              >
                {selectedCard.map((card, index) => (
                  <RadioGroup.Option
                    key={card.id}
                    value={card}
                    checked={selectedCard === card}
                    className={`w-[20rem] lg:w-[28rem] h-64 rounded-xl bg-gradient-to-r   ${
                      colorSchemes[index % colorSchemes.length].from
                    } ${
                      colorSchemes[index % colorSchemes.length].to
                    } text-white shadow-md shadow-gray-400 transition-transform hover:scale-110 mb-5 mx-auto animate-flip `}
                    style={{ cursor: "pointer" }}
                    onClick={() => handleCardClick(card)}
                  >
                    {({ checked }) => (
                      <div className="px-10">
                        <div className="flex justify-between">
                          <div className="mt-8">
                            <span className="font-medium tracking-widest">
                              {card.card.display_brand === "visa" && (
                                <FaCcVisa className="text-5xl" />
                              )}
                              {card.card.display_brand === "mastercard" && (
                                <FaCcMastercard className="text-5xl" />
                              )}
                              {card.card.display_brand ===
                                "american_express" && (
                                <SiAmericanexpress className="text-5xl" />
                              )}
                              {[
                                "visa",
                                "mastercard",
                                "american_express",
                              ].indexOf(card.card.display_brand) === -1 && (
                                <BsFillCreditCard2FrontFill className="text-5xl" />
                              )}
                            </span>
                          </div>
                          {card.id === defaultCardId && (
                            <div className="text-white bg-lime-600 mt-10 rounded-full h-8 w-8 p-2 shadow-sm shadow-white">
                              <FaCheck />
                            </div>
                          )}
                          {!checked && card.id !== defaultCardId && (
                            <div className="text-white bg-gray-300 mt-10 rounded-full h-8 w-8 p-2 shadow-md"></div>
                          )}
                        </div>
                        <div className="pt-1 mt-4">
                          <span className="font-light">Card Number</span>
                          <br />
                          <span className="tracking-more-wider font-medium">
                            **** **** **** {card.card.last4}
                          </span>
                        </div>
                        <div className="pt-4 pr-6">
                          <div className="flex justify-between">
                            <div className="">
                              <span className="text-xs font-light">
                                Country
                              </span>
                              <br />
                              <span className="text-base font-medium tracking-widest">
                                {card.card.country}
                              </span>
                            </div>
                            <div className="">
                              <span className="text-xs font-light">Expiry</span>
                              <br />
                              <span className="text-base font-medium tracking-widest">
                                {card.card.exp_month}/ {card.card.exp_year}
                              </span>
                            </div>
                            <div className="">
                              <span className="text-xs font-light">CVV</span>
                              <br />
                              <span className="tracking-more-wider text-sm font-bold">
                                ***
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </RadioGroup.Option>
                ))}
              </RadioGroup>
            ) : (
              <div>{/* Render details of the selected card */}</div>
            )}
          </div>

          <div className="p-5 flex">
            <button
              onClick={handleAddCard}
              className="text-white p-3 w-full lg:w-56 mx-auto bg-amber-600 rounded-md justify-center items-center text-center flex hover:scale-95 hover:shadow-lg hover:shadow-orange-600 transition-transform ease-in-out duration-300"
            >
              <FaPlus className="text-white font-2xl mr-2" />
              Add new card
            </button>
          </div>
        </div>
      ) : (
        <DontHaveSubscription />
      )}

      <Modal
        title="Add New Card"
        visible={modalVisible}
        onCancel={handleCancel}
        footer={null}
        centered
        destroyOnClose
        width={700}
      >
        {loading ? (
          <div className="flex justify-center items-center h-64">
            <LoadingFormLoader />
          </div>
        ) : (
          <>
            {intent && intent.setupIntent && (
              <div>
                {intent && (
                  <div className="  ">
                    {intentError ? (
                      // Display an error message if there is an intent error
                      <div className="flex justify-center items-center text-center mt-20">
                        Something went wrong{" "}
                      </div>
                    ) : (
                      <Elements
                        stripe={stripePromise}
                        options={{
                          clientSecret: intent.setupIntent,
                        }}
                      >
                        <NewFormAddCard
                          clientId={intent.setupIntentId}
                          clientSecret={intent.setupIntent}
                        />
                      </Elements>
                    )}
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </Modal>
      <Modal
        visible={modalVisibleTwo}
        onCancel={handleCardModalCancel}
        footer={[
          <div className="w-full flex" key="modal-footer">
            <Button
              key="mark-default"
              className="bg-green-500 text-white w-1/2 h-10"
              onClick={() => handleMarkAsDefault(modalCardDetails.id)}
            >
              Mark as Default
            </Button>

            <Button
              key="delete"
              type="danger"
              className="bg-red-500 text-white w-1/2 h-10"
              onClick={() => handleDeleteCard(modalCardDetails.id)}
            >
              Delete Card
            </Button>
          </div>,
        ]}
      >
        {loading ? (
          <div className="flex justify-center items-center h-64">
            <LoadingFormLoader />
          </div>
        ) : (
          <>
            {modalCardDetails && (
              <div className="py-3 px-2">
                <div className="px-7 py-7 mb-3 bg-gradient-to-r from-orange-500 to-pink-500 rounded-xl shadow-md shadow-gray-400 text-white">
                  <div className="flex justify-between">
                    <div className="">
                      <span className="font-medium tracking-widest">
                        {modalCardDetails.card.display_brand === "visa" && (
                          <FaCcVisa className="text-5xl" />
                        )}
                        {modalCardDetails.card.display_brand ===
                          "mastercard" && (
                          <FaCcMastercard className="text-5xl" />
                        )}
                        {modalCardDetails.card.display_brand ===
                          "american_express" && (
                          <SiAmericanexpress className="text-5xl" />
                        )}
                        {["visa", "mastercard", "american_express"].indexOf(
                          modalCardDetails.card.display_brand
                        ) === -1 && (
                          <BsFillCreditCard2FrontFill className="text-5xl" />
                        )}
                      </span>
                    </div>
                    {/* Render default card indicator */}
                    {modalCardDetails.id === defaultCardId && (
                      <div className="text-white bg-lime-600 mt-10 rounded-full h-8 w-8 p-2 shadow-sm shadow-white">
                        <FaCheck />
                      </div>
                    )}
                  </div>
                  {/* Render other card details */}
                  <div className="pt-1 mt-4">
                    <span className="font-light">Card Number</span>
                    <br />
                    <span className="tracking-more-wider font-medium">
                      **** **** **** {modalCardDetails.card.last4}
                    </span>
                  </div>
                  <div className="pt-4 pr-6">
                    <div className="flex justify-between">
                      <div className="">
                        <span className="text-xs font-light">Country</span>
                        <br />
                        <span className="text-base font-medium tracking-widest">
                          {modalCardDetails.card.country}
                        </span>
                      </div>
                      <div className="">
                        <span className="text-xs font-light">Expiry</span>
                        <br />
                        <span className="text-base font-medium tracking-widest">
                          {modalCardDetails.card.exp_month}/{" "}
                          {modalCardDetails.card.exp_year}
                        </span>
                      </div>
                      <div className="">
                        <span className="text-xs font-light">CVV</span>
                        <br />
                        <span className="tracking-more-wider text-sm font-bold">
                          ***
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </Modal>
    </>
  );
};

export default SelectCard;
