const tiersOne = [
  {
    label: "small",
    weight: "(up to 20lbs)",
    id: "tier-starter",
    href: "#",
    featured: false,
    description: "All your essential business finances, taken care of.",
    price: { monthly: "$24-$65", annually: "$144" },
    mainFeatures: [
      "LifePromise™ Guarantee",
      "Daily Packets of Vet-Formulated SuperFood™  & Salmon Oil",
      "Customized, Breed-Specific Nutrition Plan",
      "Free Tele-Nutrition Appointment with Nutrition Clinic",
      "Complimentary Annual Wellness Visit with Your In-Network Veterinarian",
      "Tailscore Mobile App",
      "Live Kitchen Events",
      "Healthy Recipes and Kitchen Videos",
      "Free Wellness Academy Classes",
    ],
  },
  {
    label: "medium",
    weight: "(21 to 50lbs)",
    id: "tier-scale",
    href: "#",
    featured: true,
    description: "The best financial services for your thriving business.",
    price: { monthly: "$41-$100", annually: "$576" },
    mainFeatures: [
      "LifePromise™ Guarantee",
      "Daily Packets of Vet-Formulated SuperFood™  & Salmon Oil",
      "Customized, Breed-Specific Nutrition Plan",
      "Free Tele-Nutrition Appointment with Nutrition Clinic",
      "Complimentary Annual Wellness Visit with Your In-Network Veterinarian",
      "Tailscore Mobile App",
      "Live Kitchen Events",
      "Healthy Recipes and Kitchen Videos",
      "Free Wellness Academy Classes",
    ],
  },
  {
    label: "large",
    id: "tier-growth",
    weight: "(51lbs +)",
    href: "#",
    featured: false,
    description: "Convenient features to take your business to the next level.",
    price: { monthly: "$58-$135", annually: "$288" },
    mainFeatures: [
      "LifePromise™ Guarantee",
      "Daily Packets of Vet-Formulated SuperFood™  & Salmon Oil",
      "Customized, Breed-Specific Nutrition Plan",
      "Free Tele-Nutrition Appointment with Nutrition Clinic",
      "Complimentary Annual Wellness Visit with Your In-Network Veterinarian",
      "Tailscore Mobile App",
      "Live Kitchen Events",
      "Healthy Recipes and Kitchen Videos",
      "Free Wellness Academy Classes",
    ],
  },
];

const tiersTwo = [
  {
    label: "small",
    weight: "(up to 20lbs)",
    id: "tier-starter",
    href: "#",
    featured: false,
    description: "All your essential business finances, taken care of.",
    price: { monthly: "$24-$65", annually: "$144" },
    mainFeatures: [
      "LifePromise™ Guarantee",
      "Daily Packets of Vet-Formulated SuperFood™  & Salmon Oil",
      "Customized, Breed-Specific Nutrition Plan",
      "Free Tele-Nutrition Appointment with Nutrition Clinic",
      "Complimentary Annual Wellness Visit with Your In-Network Veterinarian",
      "Tailscore Mobile App",
      "Live Kitchen Events",
      "Healthy Recipes and Kitchen Videos",
      "Free Wellness Academy Classes",
    ],
  },
  {
    label: "medium",
    weight: "(21 to 50lbs)",
    id: "tier-scale",
    href: "#",
    featured: true,
    description: "The best financial services for your thriving business.",
    price: { monthly: "$41-$100", annually: "$576" },
    mainFeatures: [
      "LifePromise™ Guarantee",
      "Daily Packets of Vet-Formulated SuperFood™  & Salmon Oil",
      "Customized, Breed-Specific Nutrition Plan",
      "Free Tele-Nutrition Appointment with Nutrition Clinic",
      "Complimentary Annual Wellness Visit with Your In-Network Veterinarian",
      "Tailscore Mobile App",
      "Live Kitchen Events",
      "Healthy Recipes and Kitchen Videos",
      "Free Wellness Academy Classes",
    ],
  },
  {
    label: "large",
    id: "tier-growth",
    weight: "(51lbs +)",
    href: "#",
    featured: false,
    description: "Convenient features to take your business to the next level.",
    price: { monthly: "$58-$135", annually: "$288" },
    mainFeatures: [
      "LifePromise™ Guarantee",
      "Daily Packets of Vet-Formulated SuperFood™  & Salmon Oil",
      "Customized, Breed-Specific Nutrition Plan",
      "Free Tele-Nutrition Appointment with Nutrition Clinic",
      "Complimentary Annual Wellness Visit with Your In-Network Veterinarian",
      "Tailscore Mobile App",
      "Live Kitchen Events",
      "Healthy Recipes and Kitchen Videos",
      "Free Wellness Academy Classes",
    ],
  },
];

const tiersThree = [
  {
    label: "small",
    weight: "(up to 20lbs)",
    id: "tier-starter",
    href: "#",
    featured: false,
    description: "All your essential business finances, taken care of.",
    price: { monthly: "$24-$65", annually: "$144" },
    mainFeatures: [
      "LifePromise™ Guarantee",
      "Daily Packets of Vet-Formulated SuperFood™  & Salmon Oil",
      "Customized, Breed-Specific Nutrition Plan",
      "Free Tele-Nutrition Appointment with Nutrition Clinic",
      "Complimentary Annual Wellness Visit with Your In-Network Veterinarian",
      "Tailscore Mobile App",
      "Live Kitchen Events",
      "Healthy Recipes and Kitchen Videos",
      "Free Wellness Academy Classes",
    ],
  },
  {
    label: "medium",
    id: "tier-scale",
    weight: "(21 to 50lbs)",
    href: "#",
    featured: true,
    description: "The best financial services for your thriving business.",
    price: { monthly: "$41-$100", annually: "$576" },
    mainFeatures: [
      "LifePromise™ Guarantee",
      "Daily Packets of Vet-Formulated SuperFood™  & Salmon Oil",
      "Customized, Breed-Specific Nutrition Plan",
      "Free Tele-Nutrition Appointment with Nutrition Clinic",
      "Complimentary Annual Wellness Visit with Your In-Network Veterinarian",
      "Tailscore Mobile App",
      "Live Kitchen Events",
      "Healthy Recipes and Kitchen Videos",
      "Free Wellness Academy Classes",
    ],
  },
  {
    label: "large",
    id: "tier-growth",
    weight: "(51lbs +)",
    href: "#",
    featured: false,
    description: "Convenient features to take your business to the next level.",
    price: { monthly: "$58-$135", annually: "$288" },
    mainFeatures: [
      "LifePromise™ Guarantee",
      "Daily Packets of Vet-Formulated SuperFood™  & Salmon Oil",
      "Customized, Breed-Specific Nutrition Plan",
      "Free Tele-Nutrition Appointment with Nutrition Clinic",
      "Complimentary Annual Wellness Visit with Your In-Network Veterinarian",
      "Tailscore Mobile App",
      "Live Kitchen Events",
      "Healthy Recipes and Kitchen Videos",
      "Free Wellness Academy Classes",
    ],
  },
];
const plansAllFeature = [
  {
    mainFeatures: [
      "LifePromise™ Guarantee",
      "Daily Packets of Vet-Formulated SuperFood™  & Salmon Oil",
      "Customized, Breed-Specific Nutrition Plan",
      "Free Tele-Nutrition Appointment with Nutrition Clinic",
      "Complimentary Annual Wellness Visit with Your In-Network Veterinarian",
      "Tailscore Mobile App",
      "Live Kitchen Events",
      "Healthy Recipes and Kitchen Videos",
      "Free Wellness Academy Classes",
    ],
  },
];

export { tiersOne, tiersTwo, tiersThree, plansAllFeature };
