import React, { useState, useEffect } from "react";
import { MODAL_TYPES, useModals } from "../context/modalContext";
import EditProfileModal from "./EditProfileModal/EditProfileModal";
import ChangePasswordModal from "./ChangePasswordModal/ChangePasswordModal";
import MyAddressModal from "./AddAddressModal/AddAddressModal";
import SubscriptionComplete from "./SubscriptionComplete/SubscriptionComplete";
import CancelSubscriptionModal from "./CancelSubscriptionModal/CancelSubscriptionModal";
import PetDeceaseModal from "./PetDeceaseModal/PetDeceaseModal";

export default function ModalConfigWrapper() {
  const modalCtx = useModals();
  const [visible, setVisible] = useState(true);
  const handleVisible = () => {
    let url = window?.location.pathname;

    if (
      url === "/stripe-redirect-checkout" ||
      url === "/checkoutPayment/success"
    ) {
      setVisible(false);
    }
  };
  useEffect(() => {
    handleVisible();
  }, []);

  const checkIfModalActive = (modalType) => {
    return modalCtx.activeModals.find((m) => m.modalType === modalType);
  };

  //
  //   "modalCtx.activeModals",
  //   modalCtx.activeModals,
  //   checkIfModalActive(MODAL_TYPES.EDIT_PROFILE)
  // );

  const editProfileModalConfig = checkIfModalActive(MODAL_TYPES.EDIT_PROFILE);
  const changePasswordModalConfig = checkIfModalActive(
    MODAL_TYPES.CHANGE_PASSWORD
  );
  const myAddressModalConfig = checkIfModalActive(MODAL_TYPES.MY_ADDRESSES);
  const subscriptionModalConfig = checkIfModalActive(
    MODAL_TYPES.SUBSCRIPTION_CONFIRMED
  );
  const cancelSubscriptionModalConfig = checkIfModalActive(
    MODAL_TYPES.CANCEL_SUBSCRIPTION
  );
  const petDeceaseModalConfig = checkIfModalActive(MODAL_TYPES.PET_DECEASE);

  return true ? (
    <div>
      {editProfileModalConfig && (
        <EditProfileModal data={editProfileModalConfig.modalData} />
      )}
      {changePasswordModalConfig && (
        <ChangePasswordModal data={changePasswordModalConfig.modalData} />
      )}
      {myAddressModalConfig && (
        <MyAddressModal data={myAddressModalConfig.modalData} />
      )}
      {subscriptionModalConfig && <SubscriptionComplete />}
      {cancelSubscriptionModalConfig && (
        <CancelSubscriptionModal
          data={cancelSubscriptionModalConfig.modalData}
        />
      )}
      {petDeceaseModalConfig && (
        <PetDeceaseModal data={petDeceaseModalConfig.modalData} />
      )}
    </div>
  ) : (
    ""
  );
}
