import { Fragment, useRef, useState, useEffect } from "react";
import { Menu, Transition } from "@headlessui/react";
import { IoEllipsisVerticalOutline } from "react-icons/io5";
import { PiDogFill } from "react-icons/pi";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { FaCheckCircle, FaArrowLeft } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import bgImg from "../../utils/2709577_14710.jpg";
import LoadingScreenWhile from "../AddNewPet/LoadingScreenWhile";

const determinePrimaryPet = (pets) => {
  if (pets.length === 1) {
    return pets[0];
  }

  const sizeOrder = ["small", "medium", "large"];
  const sortedPets = pets.sort((a, b) => {
    const sizeComparison =
      sizeOrder.indexOf(a.planSize) - sizeOrder.indexOf(b.planSize);
    return sizeComparison !== 0
      ? sizeComparison
      : new Date(a.createdAt) - new Date(b.createdAt);
  });

  return sortedPets[0];
};

export default function MyPlansNew() {
  const contentRef = useRef(null);
  const [totalAmountInCents, setTotalAmountInCents] = useState(null);
  const [totalAmountInDollars, setTotalAmountInDollars] = useState(null);
  const [currentPlan, setCurrentPlan] = useState(null);
  const [subscriptionError, setSubscriptionError] = useState(null);
  const [userPets, setUserPets] = useState([]);
  const [petsWithPlan, setPetsWithPlan] = useState([]);
  const [totalPriceForAllPets, setTotalPriceForAllPets] = useState(0);
  const [primaryPet, setPrimaryPet] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const subscriptionResponse = await axios.get("/users/subscription");
        const subscriptionData = subscriptionResponse.data.data;
        setPetsWithPlan(subscriptionData.petsWithPlan);

        if (
          subscriptionData &&
          subscriptionData.items &&
          subscriptionData.items.data
        ) {
          const items = subscriptionData.items.data;
          const totalAmountInCents = items.reduce(
            (sum, item) => sum + item.plan.amount,
            0
          );
          setTotalAmountInCents(totalAmountInCents);

          const totalAmountInDollars = totalAmountInCents / 100;
          setTotalAmountInDollars(totalAmountInDollars);

          setCurrentPlan(subscriptionData);
        } else {
        }

        const userPetsResponse = await axios.get("/pet-profiles/user-pets");
        const userPetsData = userPetsResponse.data.data;

        // Filter out deceased or cancelled subscription pets
        const activePets = userPetsData.filter(
          (pet) => !pet.isDeceased && !pet.cancelledSubscription
        );

        setUserPets(activePets);
        setPrimaryPet(determinePrimaryPet(activePets));
      } catch (error) {
        setSubscriptionError(
          error.response?.status === 400 ? "No Subscription" : "Error"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const getPriceForPet = (petId) => {
    const matchingItem = petsWithPlan.find((item) => item.petId === petId);
    return matchingItem ? matchingItem.price : null;
  };

  const calculateTotalPriceForAllPets = () => {
    if (!petsWithPlan || petsWithPlan.length === 0) {
      return 0;
    }
    const totalPrice = petsWithPlan.reduce((sum, item) => sum + item.price, 0);
    setTotalPriceForAllPets(totalPrice);
  };

  useEffect(() => {
    calculateTotalPriceForAllPets();
  }, [petsWithPlan]);

  const downloadAsPdf = () => {
    html2canvas(contentRef.current).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "PNG", 10, 10, 180, 0);
      pdf.save("order_details.pdf");
    });
  };

  return (
    <div>
      {loading ? (
        <div className="text-center mt-20 h-screen w-full">
          <LoadingScreenWhile />
        </div>
      ) : subscriptionError ? (
        <div className="text-center text-black font-semibold text-2xl flex flex-col mt-40 bg-gray-50 p-10 rounded-xl shadow-md h-96 lg:w-[45rem] md:w-[35rem] sm:w-[30rem]">
          <div className="mt-10">
            You don't have any Subscription, please add a pet.
          </div>
          <div className="flex flex-col items-center justify-center mt-10">
            <p className="text-black text-sm font-thin lg:w-[33rem] sm:w-[25rem] text-left">
              Click the button below to add a new pet and purchase a
              subscription. Once you've added a pet and completed the
              subscription, you can return to this page to view your active
              subscriptions.
            </p>
          </div>
          <Link
            to="/pets"
            className="ml-2 bg-amber-500 text-white px-4 py-2 rounded mt-14 sm:mt-10"
          >
            + Add Pet
          </Link>
        </div>
      ) : (
        <div className="bg-white">
          <div className="py-10 sm:py-10">
            <div className="mx-auto max-w-7xl sm:px-2 lg:px-8">
              <div className="mx-auto max-w-2xl px-4 lg:max-w-4xl lg:px-0">
                <button className="p-2 rounded-full bg-[#D97706] mx-auto mb-5">
                  <Link to="/pets">
                    <FaArrowLeft className="text-xl text-white" />
                  </Link>
                </button>
                <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
                  Current Plan Details
                </h1>
                <p className="mt-2 text-sm text-gray-500">
                  Check the status of recent subscriptions, discover plan values
                  and duration.
                </p>
              </div>
            </div>
            {currentPlan && (
              <div className="mt-16" ref={contentRef}>
                <h2 className="sr-only">Current Plan Details</h2>
                <div className="mx-auto max-w-8xl sm:px-2 lg:px-8">
                  <div className="mx-auto max-w-2xl space-y-8 sm:px-4 lg:max-w-4xl lg:px-0">
                    <div
                      key={currentPlan.id}
                      className="border-b border-t border-gray-200 bg-white shadow-sm sm:rounded-lg sm:border"
                    >
                      <h3 className="sr-only">Plan details</h3>
                      <div className="flex items-center border-b border-gray-200 p-4 sm:grid sm:grid-cols-4 sm:gap-x-6 sm:p-6 bg-gradient-to-r from-blue-100 to-orange-100">
                        <dl className="grid flex-1 grid-cols-2 gap-x-6 text-sm sm:col-span-3 sm:grid-cols-3 lg:col-span-2">
                          <div>
                            <dt className="font-bold text-[#448EBE]">
                              Current Plan Value
                            </dt>
                            <dd className="mt-1 text-lime-600 font-bold text-xl">
                              ${totalPriceForAllPets}
                            </dd>
                          </div>
                          <div className="hidden sm:block">
                            <dt className="font-medium text-gray-900">
                              Renews on
                            </dt>
                            <dd className="mt-1 text-gray-500 font-bold">
                              <time
                                dateTime={moment
                                  .unix(currentPlan.current_period_end)
                                  .format("Do MMM")}
                              >
                                {moment
                                  .unix(currentPlan.current_period_end)
                                  .format("Do MMM")}
                              </time>
                            </dd>
                          </div>
                          <div>
                            <dt className="font-medium text-gray-900">
                              Duration
                            </dt>
                            <dd className="mt-1 font-medium text-gray-900">
                              <span>
                                {moment
                                  .unix(currentPlan.current_period_start)
                                  .format("Do MMM")}{" "}
                                -{" "}
                                {moment
                                  .unix(currentPlan.current_period_end)
                                  .format("Do MMM")}
                              </span>
                            </dd>
                          </div>
                        </dl>
                        <div className="hidden lg:col-span-2 lg:flex lg:items-center lg:justify-end lg:space-x-4">
                          <button
                            onClick={downloadAsPdf}
                            className="flex items-center justify-center rounded-md border border-gray-300 bg-white px-2.5 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          >
                            <span>Download details</span>
                          </button>
                        </div>
                      </div>
                      <h4 className="sr-only">Items</h4>
                      <ul role="list" className="divide-y divide-gray-200">
                        {primaryPet && !primaryPet.isPausedSubscription && (
                          <li key={primaryPet.id} className="p-4 sm:p-6">
                            <div className="flex items-center sm:items-start">
                              <div className="h-20 w-20 flex-shrink-0 overflow-hidden rounded-lg bg-gray-200 sm:h-40 sm:w-40">
                                {primaryPet.profilePicURL ? (
                                  <img
                                    src={primaryPet.profilePicURL}
                                    className="h-full w-full object-cover object-center"
                                    alt={`${primaryPet.petName}'s photo`}
                                  />
                                ) : (
                                  <PiDogFill className="h-full w-full text-gray-400" />
                                )}
                              </div>
                              <div className="ml-6 flex-1 text-sm">
                                <h5 className="text-xl text-[#000000] ">
                                  Primary Pet
                                </h5>
                                <div className="font-medium text-gray-900 sm:flex sm:justify-between">
                                  <h5 className="text-2xl text-[#448EBE]">
                                    {primaryPet.petName}
                                  </h5>
                                  <p className="mt-2 sm:mt-0">
                                    {primaryPet.planSize}
                                  </p>
                                </div>
                                <p className="mt-2 sm:mt-0 text-lime-600 text-xl font-bold">
                                  {getPriceForPet(primaryPet.id) !== null ? (
                                    `$${getPriceForPet(primaryPet.id)}`
                                  ) : (
                                    <span className="text-lg">
                                      Price not available
                                    </span>
                                  )}
                                </p>
                                <p className="mt-2 sm:mt-0">
                                  {primaryPet.gender.charAt(0).toUpperCase() +
                                    primaryPet.gender.slice(1)}
                                  , {Math.floor(primaryPet.age / 12)} years,{" "}
                                  {primaryPet.weight} lbs ,{" "}
                                  <span className="font-bold">
                                    {primaryPet.breed}
                                  </span>
                                </p>
                                <p className="hidden text-gray-500 sm:mt-2 sm:block">
                                  {primaryPet.Address.address},{" "}
                                  {primaryPet.Address.city},
                                  {primaryPet.Address.zipcode},
                                  {primaryPet.Address.country}
                                </p>
                              </div>
                            </div>
                            <div className="mt-6 sm:flex sm:justify-between">
                              <div className="flex ">
                                <FaCheckCircle
                                  className="text-xl text-green-500"
                                  aria-hidden="true"
                                />
                                <p className="ml-2 text-sm font-medium text-gray-500">
                                  Subscribed
                                </p>
                              </div>
                            </div>
                          </li>
                        )}
                        {userPets
                          .filter(
                            (pet) =>
                              pet.id !== primaryPet?.id &&
                              !pet.isPausedSubscription
                          )
                          .map((userPet) => (
                            <li key={userPet.id} className="p-4 sm:p-6">
                              <div className="flex items-center sm:items-start">
                                <div className="h-20 w-20 flex-shrink-0 overflow-hidden rounded-lg bg-gray-200 sm:h-40 sm:w-40">
                                  {userPet.profilePicURL ? (
                                    <img
                                      src={userPet.profilePicURL}
                                      className="h-full w-full object-cover object-center"
                                      alt={`${userPet.petName}'s photo`}
                                    />
                                  ) : (
                                    <PiDogFill className="h-full w-full text-gray-400" />
                                  )}
                                </div>
                                <div className="ml-6 flex-1 text-sm">
                                  <h5 className="text-xl text-[#000000] ">
                                    Add On Pet
                                  </h5>
                                  <div className="font-medium text-gray-900 sm:flex sm:justify-between">
                                    <h5 className="text-2xl text-[#448EBE]">
                                      {userPet.petName}
                                    </h5>
                                    <p className="mt-2 sm:mt-0">
                                      {userPet.planSize}
                                    </p>
                                  </div>
                                  <p className="mt-2 sm:mt-0 text-lime-600 text-xl font-bold">
                                    {getPriceForPet(userPet.id) !== null ? (
                                      `$${getPriceForPet(userPet.id)}`
                                    ) : (
                                      <span className="text-lg">
                                        Price not available
                                      </span>
                                    )}
                                  </p>
                                  <p className="mt-2 sm:mt-0">
                                    {userPet.gender.charAt(0).toUpperCase() +
                                      userPet.gender.slice(1)}
                                    , {Math.floor(userPet.age / 12)} years,{" "}
                                    {userPet.weight} lbs ,{" "}
                                    <span className="font-bold">
                                      {userPet.breed}
                                    </span>
                                  </p>
                                  <p className="hidden text-gray-500 sm:mt-2 sm:block">
                                    {userPet.Address.address},{" "}
                                    {userPet.Address.city},
                                    {userPet.Address.zipcode},
                                    {userPet.Address.country}
                                  </p>
                                </div>
                              </div>
                              <div className="mt-6 sm:flex sm:justify-between">
                                <div className="flex ">
                                  <FaCheckCircle
                                    className="text-xl text-green-500"
                                    aria-hidden="true"
                                  />
                                  <p className="ml-2 text-sm font-medium text-gray-500">
                                    Subscribed
                                  </p>
                                </div>
                              </div>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
