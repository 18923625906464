// PrivacyPolicyPage.js

import React from "react";
import dogBg from "../../utils/2709577_14710.jpg";
const PrivacyPolicyPage = () => {
  const containerStyle = {
    backgroundImage: `url(${dogBg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  return (
    <div style={containerStyle}>
      <div className="container mx-auto  p-8 bg-white shadow-lg rounded-lg max-w-6xl text-center bg-opacity-75">
        <h1 className="text-4xl font-bold mb-6 text-amber-700">
          Privacy Policy
        </h1>

        <p className="text-gray-800 mb-6 text-lg text-left">
          Welcome to Carson&Bear's Dog Wellness Program. This Privacy Policy
          outlines how we collect, use, and protect the information you provide
          to us.
        </p>

        <div className="border-t-2 border-amber-200 mt-8 pt-6">
          <h2 className="text-2xl font-bold mb-4 text-amber-700">
            Information We Collect
          </h2>
          <p className="text-gray-800 mb-6 text-lg text-left">
            We collect personal information such as your name, email address,
            and other relevant details when you register for our Dog Wellness
            Program. This information is essential for providing you with a
            personalized and effective experience.
          </p>
        </div>

        <div className="border-t-2 border-amber-200 mt-8 pt-6">
          <h2 className="text-2xl font-bold mb-4 text-amber-700">
            How We Use Your Information
          </h2>
          <p className="text-gray-800 mb-6 text-lg text-left">
            We use the information collected to provide you with access to our
            Dog Wellness Program, communicate with you about your dog's
            wellness, and improve our services. Your data helps us understand
            your preferences and tailor our offerings to meet your needs.
          </p>
        </div>

        <div className="border-t-2 border-amber-200 mt-8 pt-6">
          <h2 className="text-2xl font-bold mb-4 text-amber-700">
            Protection of Your Information
          </h2>
          <p className="text-gray-800 mb-6 text-lg text-left">
            We take the security of your personal information seriously. Our
            website and databases are protected with industry-standard security
            measures to prevent unauthorized access or disclosure of your data.
          </p>
        </div>

        <div className="border-t-2 border-amber-200 mt-8 pt-6">
          <h2 className="text-2xl font-bold mb-4 text-amber-700">
            Third-Party Disclosure
          </h2>
          <p className="text-gray-800 mb-6 text-lg text-left">
            We respect your privacy, and we do not sell, trade, or otherwise
            transfer your personal information to third parties without your
            consent.
          </p>
        </div>

        <div className="border-t-2 border-amber-200 mt-8 pt-6">
          <h2 className="text-2xl font-bold mb-4 text-amber-700">Cookies</h2>
          <p className="text-gray-800 mb-6 text-lg text-left">
            Our website may use cookies to enhance your browsing experience. You
            can choose to disable cookies in your browser settings, but this may
            affect the functionality of our Dog Wellness Program.
          </p>
        </div>

        <div className="border-t-2 border-amber-200 mt-8 pt-6">
          <h2 className="text-2xl font-bold mb-4 text-amber-700">
            Data Retention
          </h2>
          <p className="text-gray-800 mb-6 text-lg text-left">
            We retain your personal information for as long as necessary to
            fulfill the purposes outlined in this Privacy Policy or as required
            by law.
          </p>
        </div>

        <div className="border-t-2 border-amber-200 mt-8 pt-6">
          <h2 className="text-2xl font-bold mb-4 text-amber-700">Contact Us</h2>
          <p className="text-gray-800 mb-6 text-lg text-left">
            If you have any questions or concerns regarding our Privacy Policy,
            please contact us at{" "}
            <a
              href="mailto:support@carsonandbear.com"
              className="text-amber-700 underline"
            >
              support@carsonandbear.com
            </a>
            .
          </p>
        </div>

        <p className="text-gray-600 mt-8 text-sm">
          This Privacy Policy was last updated on January 15, 2024.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicyPage;
