import React, { useEffect, useState } from "react";
import Page from "../../components/core/Page/Page";
import { loadStripe } from "@stripe/stripe-js";
import { GiTicket } from "react-icons/gi";
import { CiDiscount1 } from "react-icons/ci";
import { PiDogFill } from "react-icons/pi";
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { initSubscription } from "../../components/services/api.service";
import { toast } from "react-toastify";
import axios from "axios";
import checkoutBg from "../../utils/checkoutng.jpg";
import { useNavigate } from "react-router";
import { Disclosure } from "@headlessui/react";
import LoadingScreenWhile from "../AddNewPet/LoadingScreenWhile";
import TaxFail from "./TaxFail";
import { FaAngleDown } from "react-icons/fa6";
import { FaAngleUp } from "react-icons/fa6";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  setShouldTriggerAction,
  setDiscountTotalAmount,
} from "../../store/slice";
import {
  selectShouldTriggerAction,
  selectDiscountTotalAmount,
} from "../../store/slice";
import { MdCancel } from "react-icons/md";
import StepsCheckOut from "../../components/StepsCheckOut";
import { Spin } from "antd"; // Import Ant Design Spin component
// Test Mode
const stripePromise = loadStripe(
  "pk_test_51MvOEbGcnkPKGaUh0bexrc8MU4uAzDOwk43ksV972EEH4r8SGJjStxlHouTJaHYio5MWifq24ivcaWM1J0YZowqd009ddkR8eV"
);

// Live Mode
// const stripePromise = loadStripe(
//   "pk_live_51MvOEbGcnkPKGaUh3cIsv2Kv7FjqxygDjVgRhOvDIqWzmIFyVEUDboXl2X0Ez5rTzfeF7Amx9fHXdHWppF1sEUAp001S1t5Aui"
// );

export const CheckoutForm = ({
  clientId,
  clientSecret,
  totalAmount,
  taxAmount,
  // finalTotal,
  totalTaxAmount,
  intentError,
  taxPercentage,
  handleFetchTax,
  userpets,
}) => {
  const [promoCode, setPromoCode] = useState("");
  const [promoCodes, setPromoCodes] = useState("");
  const [appliedDiscount, setAppliedDiscount] = useState(0);
  const [subTotal, setSubTotal] = useState(totalAmount);
  //
  const [couponData, setCouponData] = useState(null);
  //
  //
  const dispatch = useDispatch();
  const shouldTriggerAction = useSelector(selectShouldTriggerAction);
  const discountTotalAmount = useSelector(selectDiscountTotalAmount);

  const handleApplyPromoCode = async () => {
    if (promoCode) {
      try {
        const response = await axios.post(
          "/users/subscription/validate-coupon",
          {
            coupon: promoCode,
          }
        );

        if (response.data.success) {
          let newAppliedDiscount;

          if (response.data.data.amount_off !== null) {
            newAppliedDiscount = response.data.data.amount_off / 100;
          } else if (response.data.data.percent_off !== null) {
            newAppliedDiscount =
              (totalAmount * response.data.data.percent_off) / 100;
          }

          setAppliedDiscount(newAppliedDiscount);
          localStorage.setItem("couponDiscountAmount", newAppliedDiscount);

          dispatch(setDiscountTotalAmount(newAppliedDiscount));
          dispatch(setShouldTriggerAction(true));

          const newSubtotal = totalAmount - newAppliedDiscount;
          setSubTotal(newSubtotal);

          setCouponData(response.data.data);
          if (response.data.data.id) {
            localStorage.setItem("finalPromocode", response.data.data.id);
          }

          toast.success("Promo code applied successfully!");
          // window.location.reload();
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error("An error occurred while applying the promo code.");
      }
    }
  };
  const handeRemovePromoCode = () => {
    setPromoCode("");
    setAppliedDiscount(0);
    const newSubtotal = totalAmount;
    setSubTotal(newSubtotal);
    localStorage.removeItem("couponDiscountAmount");
    localStorage.removeItem("finalPromocode");
    dispatch(setDiscountTotalAmount(0));
    dispatch(setShouldTriggerAction(true));
    window.location.reload();
    // Ensure tax data is updated when promo code is removed
  };

  const stripe = useStripe();
  const elements = useElements();

  if (!clientId || !clientSecret) {
    return;
  }
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    const redirectUrl =
      window.location.origin +
      `/checkout/success?payment_intent=${clientId}&client_secret=${clientSecret}`;

    const result = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: redirectUrl,
      },
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)

      toast.error(result.error.code);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />

      <div className="w-full p-4 bg-zinc-50 border rounded-lg border-yellow-200 mt-8 shadow-md">
        <h2 className="text-xl font-semibold mb-4">Order Summary</h2>

        <div className="flex flex-col mb-2 relative">
          <label
            for="promoCode"
            className="text-gray-600 text-md font-semibold"
          >
            Promo Code:
          </label>
          <div className="flex items-center ">
            <input
              type="text"
              placeholder="Enter your promo code"
              id="promoCode"
              value={promoCode}
              onChange={(e) => setPromoCode(e.target.value)}
              className="border rounded-md p-2 outline-none w-full"
            />
            {discountTotalAmount > 0 && (
              <MdCancel
                onClick={handeRemovePromoCode}
                className="absolute bottom-2 text-2xl right-36 text-red-400 hover:text-red-600 hover:scale-105 transition-transform duration-200 ease-in-out cursor-pointer"
              />
            )}
            <button
              type="button"
              onClick={handleApplyPromoCode}
              className="flex items-center px-4 py-2 bg-amber-500 text-white rounded-md ml-4 hover:bg-amber-400 transform transition-all duration-300 ease-in-out shadow-md focus:ring focus:ring-amber-500 active:ring active:ring-amber-300"
            >
              <GiTicket className="mr-2 font-bold text-lg" />
              Apply
            </button>
          </div>
        </div>

        <div className="flex justify-between mb-2">
          <div className="text-gray-600 text-md font-semibold">Plan value:</div>
          <div className="text-green-600 font-semibold text-md">
            ${totalAmount.toFixed(2)}
          </div>
        </div>

        {appliedDiscount !== 0 && (
          <div className="flex justify-between mb-2">
            <div className="text-gray-600 text-md font-semibold">
              Applied discount:
            </div>
            <div className="text-red-600 font-semibold text-md">
              - ${appliedDiscount.toFixed(2)}
            </div>
          </div>
        )}

        <div className="flex justify-between mb-2">
          <div className="text-gray-600 text-md font-semibold">Subtotal:</div>
          <div className="text-green-600 font-semibold text-lg">
            ${subTotal.toFixed(2)}
          </div>
        </div>

        <div className="flex justify-between mb-2">
          <div className="text-gray-600 text-md font-semibold">
            Total Tax Amount:{" "}
            {/* <span className="text-gray-500 text-sm font-light">
              rate: {taxPercentage.toFixed(2)}
            </span> */}
          </div>
          <div className="text-green-600 font-semibold text-md">
            + ${(totalTaxAmount / 100).toFixed(2)}
          </div>
        </div>

        <hr className="my-4" />

        <div className="flex justify-between">
          <p className="text-md font-semibold">Total Amount:</p>
          <p className="text-md text-green-600 font-semibold">
            ${(subTotal + totalTaxAmount / 100).toFixed(2)}
          </p>
        </div>
      </div>

      <button className="bg-amber-500 w-full mt-8 shadow-md text-white font-semibold px-4 py-3 rounded-lg hover:bg-amber-600 focus:ring focus:ring-amber-500 active:ring active:ring-amber-300">
        Make Payment
      </button>
    </form>
  );
};

export default function Checkout() {
  const navigate = useNavigate();
  const [intent, setIntent] = useState();
  const [petData, setPetData] = useState([]);
  const [mergedData, setMergedData] = useState([]);

  const [subscriptionData, setSubscriptionData] = useState(null);
  const [taxData, setTaxData] = useState([]);

  const [taxRate, setTaxRate] = useState("");
  const [totalTaxAmount, setTotalTaxAmount] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(0);
  // const [taxPercentage, setTaxPercentage] = useState(0);

  //
  const [intentError, setIntenterror] = useState(null);
  const shouldTriggerAction = useSelector(selectShouldTriggerAction);
  const discountTotalAmount = useSelector(selectDiscountTotalAmount);

  const [loading, setLoading] = useState(false); // Add loading state

  // useEffect(() => {
  //   // Function to redirect to /pets when navigating away
  //   const handleNavigateAway = () => {
  //     navigate("/pets");
  //   };

  //   // Add an event listener for the beforeunload event (page refresh or close)
  //   window.addEventListener("beforeunload", handleNavigateAway);

  //   // Cleanup function to handle back navigation
  //   return () => {
  //     handleNavigateAway();
  //     window.removeEventListener("beforeunload", handleNavigateAway);
  //   };
  // }, [navigate]);

  useEffect(() => {
    if (shouldTriggerAction) {
      handleFetchTax();
      // handleUpdateIntent();
    }
  }, [shouldTriggerAction]);
  const userpets = () => {
    setLoading(true);
    axios
      .get("/pet-profiles/user-pets")
      .then((response) => {
        setPetData(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    axios
      .get("/pet-profiles/user-pets")
      .then((response) => {
        setPetData(response.data.data);
      })
      .catch((error) => {});
  }, []);

  const handleFetchTax = () => {
    setLoading(true); // Set loading to true before API call
    // Get subscription type from localStorage
    const subscriptionType = localStorage.getItem("subscriptionType");

    // Determine API endpoint based on subscription type
    let apiUrl =
      subscriptionType === "v1"
        ? "/users/subscription/tax"
        : "/users/subscription/tax-v2";

    if (shouldTriggerAction) {
      apiUrl += `?couponDiscountAmount=${discountTotalAmount}`;
    }

    axios
      .get(apiUrl)
      .then((res) => {
        setTaxData(res.data.data);
      })
      .catch((err) => {
        navigate("/taxFail");
      })
      .finally(() => {
        setLoading(false); // Set loading to false after API call
      });
  };
  const mergeData = () => {
    setLoading(true); // Set loading to true before merging data
    const merged = [];
    let totalTax = 0;

    petData.forEach((pet) => {
      const taxInfo = taxData.find((tax) => tax.petId === pet.id);
      if (taxInfo) {
        const isPrimary = taxInfo.isPrimary; // Check if the pet is primary
        merged.push({ ...pet, ...taxInfo, isPrimary });
        totalTax += taxInfo.taxAmount;
      }
    });

    setMergedData(merged);
    setTotalTaxAmount(totalTax);
    setLoading(false); // Set loading to false after merging data
  };

  useEffect(() => {
    if (taxData.length > 0 && petData.length > 0) {
      mergeData();
    }
  }, [taxData, petData]);

  const handleUpdateSubscription = () => {
    // Get subscription type from localStorage
    const subscriptionType = localStorage.getItem("subscriptionType");

    // Determine API endpoint based on subscription type
    const apiUrl =
      subscriptionType === "v1"
        ? "/users/subscription/estimate"
        : "/users/subscription/estimate-v2";

    axios.get(apiUrl).then((res) => {
      setSubscriptionData(res.data.data);
    });
  };

  const handleUpdateIntent = () => {
    initSubscription()
      .then((res) => {
        setIntent(res.data);
      })
      .catch((err) => setIntenterror("error"));
  };

  useEffect(() => {
    handleUpdateIntent();
  }, []);

  useEffect(() => {
    // handleUpdateIntent();
    handleUpdateSubscription();
    handleFetchTax();
    mergeData();
  }, [shouldTriggerAction]);

  const totalAmount = subscriptionData?.total / 100 || 0;
  const taxAmount = taxData || 0;
  const taxPercentage = taxRate || 0;

  // const finalTotal = totalAmount + taxAmount;
  if (!intent) {
    return <LoadingScreenWhile />;
  }
  return (
    <>
      <StepsCheckOut />
      <main className="lg:flex lg:min-h-full lg:flex-row-reverse lg:overflow-hidden ">
        <h1 className="sr-only">Checkout</h1>

        {loading ? ( // Conditionally render loader
          <div className="flex justify-center items-center h-full">
            <Spin size="large" /> {/* Ant Design loader */}
          </div>
        ) : (
          <>
            {/* Mobile order summary */}
            <section
              aria-labelledby="order-heading"
              className="bg-gradient-to-r bg-gray-100 px-4 py-6 sm:px-6 lg:hidden"
            >
              <Disclosure as="div" className="mx-auto max-w-lg">
                {({ open }) => (
                  <>
                    <div className="flex items-center justify-between">
                      <h2
                        id="order-heading"
                        className="text-lg font-medium text-gray-900"
                      >
                        Your Order
                      </h2>
                      <Disclosure.Button className="font-medium text-black hover:text-amber-900">
                        {open ? (
                          <span className="flex justify-center items-center space-x-3">
                            Hide full summary
                            <FaAngleUp className="text-amber-700 text-2xl animate-pulse" />
                          </span>
                        ) : (
                          <span className="flex justify-center items-center space-x-3">
                            Show full summary
                            <FaAngleDown className="text-amber-700 animate-pulse text-2xl" />
                          </span>
                        )}
                      </Disclosure.Button>
                    </div>

                    <Disclosure.Panel>
                      <ul
                        role="list"
                        className="divide-y divide-gray-900 border-b border-gray-200"
                      >
                        {mergedData
                          .sort((a, b) =>
                            b.isPrimary === a.isPrimary
                              ? 0
                              : b.isPrimary
                              ? 1
                              : -1
                          ) // Sort so primary pets are at the top
                          .map((pet) => (
                            <li
                              key={pet.petId}
                              className="flex flex-col space-x-6 py-6"
                            >
                              <div className="text-2xl text-black tracking-tighter w-full p-3 ml-1 font-bold">
                                {pet.isPrimary ? "First Dog" : "Pack Pal"}
                              </div>
                              {pet.profilePicURL ? (
                                <img
                                  src={pet.profilePicURL}
                                  alt={pet.petName}
                                  className="h-20 w-20 flex-none rounded-md bg-gray-200 object-cover object-center"
                                />
                              ) : (
                                <div className="h-20 w-20 flex-none rounded-md bg-gray-200 flex items-center justify-center">
                                  <PiDogFill className="h-20 w-20 text-amber-900" />
                                </div>
                              )}
                              <div className="flex flex-col justify-between space-y-4">
                                <div className="space-y-1 text-sm font-medium">
                                  <h3 className="text-gray-900">
                                    {pet.petName}
                                  </h3>
                                  <p className="text-gray-900">{pet.breed}</p>
                                  <p className="text-gray-500">
                                    {pet.weight}Lbs, {pet.gender}
                                  </p>

                                  <table className="w-full bg-white/30 p-3">
                                    <tbody>
                                      <tr>
                                        <td className="border border-gray-900 p-2 text-blue-600">
                                          Price
                                        </td>
                                        <td className="border border-gray-900 p-2">
                                          ${(pet.price / 100).toFixed(2)}
                                        </td>
                                      </tr>
                                      {discountTotalAmount > 0 && (
                                        <tr>
                                          <td className="border border-gray-900 p-2 text-green-600">
                                            Price After Discount
                                          </td>
                                          <td className="border border-gray-900 p-2 text-green-600">
                                            {(
                                              pet.priceAfterDiscount / 100
                                            ).toFixed(2)}
                                          </td>
                                        </tr>
                                      )}
                                      <tr>
                                        <td className="border border-gray-900 p-2 text-red-600">
                                          Tax Amount
                                        </td>
                                        <td className="border border-gray-900 p-2">
                                          ${(pet.taxAmount / 100).toFixed(2)}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="border border-gray-900 p-2 text-indigo-600">
                                          Total Amount
                                        </td>
                                        <td className="border border-gray-900 p-2">
                                          $
                                          {(
                                            pet.priceIncludingTax / 100
                                          ).toFixed(2)}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </li>
                          ))}
                      </ul>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </section>

            {/* Order summary */}

            <section
              aria-labelledby="summary-heading"
              className="hidden w-full max-w-md flex-col bg-gray-100 lg:flex h-[105vh] mt-1"
            >
              <h2 id="summary-heading" className="sr-only">
                Order summary
              </h2>

              <ul
                role="list"
                className="flex-auto divide-y divide-gray-900 overflow-y-auto px-6"
                style={{ marginRight: "-17px" }} // Compensate for scrollbar width
              >
                {mergedData
                  .sort((a, b) =>
                    b.isPrimary === a.isPrimary ? 0 : b.isPrimary ? 1 : -1
                  )
                  .map((pet) => (
                    <li
                      key={pet.petId}
                      className="flex flex-col space-x-6 py-6"
                    >
                      <div className="text-2xl text-black tracking-tighter w-full p-3 ml-1 font-bold">
                        {pet.isPrimary ? "First Dog" : "Pack Pal"}
                      </div>
                      {pet.profilePicURL ? (
                        <img
                          src={pet.profilePicURL}
                          alt={pet.petName}
                          className="h-20 w-20 flex-none rounded-md bg-gray-200 object-cover object-center"
                        />
                      ) : (
                        <div className="h-20 w-20 flex-none rounded-md bg-gray-50 flex items-center justify-center">
                          <PiDogFill className="h-20 w-20 text-amber-900" />
                        </div>
                      )}
                      <div className="flex flex-col justify-between space-y-4 mt-1">
                        <div className="space-y-1 text-sm font-medium">
                          <h3 className="text-gray-900">{pet.petName}</h3>
                          <p className="text-gray-900">{pet.breed}</p>
                          <p className="text-gray-500">
                            {pet.weight}Lbs, {pet.gender}
                          </p>

                          <table className="border-collapse border border-gray-900 w-full bg-white/30 p-3 rounded-lg">
                            <tbody>
                              <tr>
                                <td className="border border-gray-900 p-2 text-blue-600">
                                  Price
                                </td>
                                <td className="border border-gray-900 p-2">
                                  ${(pet.price / 100).toFixed(2)}
                                </td>
                              </tr>
                              {discountTotalAmount > 0 && (
                                <tr>
                                  <td className="border border-gray-900 p-2 text-green-600">
                                    Price After Discount
                                  </td>
                                  <td className="border border-gray-900 p-2 text-green-600">
                                    {(pet.priceAfterDiscount / 100).toFixed(2)}
                                  </td>
                                </tr>
                              )}
                              <tr>
                                <td className="border border-gray-900 p-2 text-red-600">
                                  Tax Amount
                                </td>
                                <td className="border border-gray-900 p-2">
                                  ${(pet.taxAmount / 100).toFixed(2)}
                                </td>
                              </tr>
                              <tr>
                                <td className="border border-gray-900 p-2 text-indigo-600">
                                  Total Amount
                                </td>
                                <td className="border border-gray-900 p-2">
                                  ${(pet.priceIncludingTax / 100).toFixed(2)}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </li>
                  ))}
              </ul>
            </section>
          </>
        )}

        {intent && intent.setupIntent && (
          <div className="w-full ">
            <div className="w-full">
              <div className="w-full">
                {intent && (
                  <div className="px-5 lg:px-40 py-5 shadow-sm mb-10  ">
                    {intentError ? (
                      // Display an error message if there is an intent error
                      <TaxFail />
                    ) : (
                      <Elements
                        stripe={stripePromise}
                        options={{
                          clientSecret: intent.setupIntent,
                        }}
                      >
                        <CheckoutForm
                          intentError={intentError}
                          clientId={intent.setupIntentId}
                          clientSecret={intent.setupIntent}
                          totalAmount={totalAmount}
                          userpets={userpets}
                          taxAmount={taxAmount}
                          handleFetchTax={handleFetchTax}
                          taxPercentage={taxPercentage}
                          totalTaxAmount={totalTaxAmount}
                          // finalTotal={finalTotal}
                        />
                      </Elements>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </main>
    </>
  );
}
