import React, { useState, useEffect } from "react";
import "./CreateAccount.scss";
import { Input, Form, message, Checkbox } from "antd";
import { toast } from "react-toastify";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { signupApi } from "../../services/api.service";
import { signupApiNew } from "../../services/api.service";
import { setAccessToken } from "../../services/localstorage";
import { useAuth } from "../../context/authContext";
import { useDispatch } from "react-redux";
import { setEmailVerified } from "../../../store/authSlice";
import { GoPerson } from "react-icons/go";
import { AiTwotoneMail } from "react-icons/ai";
import { RiLockPasswordLine } from "react-icons/ri";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

export default function CreateAccount({ onClose }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ctx = useAuth();
  const [form] = Form.useForm();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [otpModal, setOtpModal] = useState(false);
  const [nominationData, setNominationData] = useState(null);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    const nominationDataFromStorage = localStorage.getItem("Nomination");
    if (nominationDataFromStorage) {
      const parsedData = JSON.parse(nominationDataFromStorage);
      setNominationData(parsedData);
      if (parsedData.petOwnerEmail) {
        form.setFieldsValue({
          email: parsedData.petOwnerEmail,
        });
      }
    }
  }, [form]);

  const togglePasswordVisibility = (field) => {
    if (field === "password") {
      setShowPassword(!showPassword);
    } else if (field === "confirmPassword") {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };

  const handleLoginClick = () => {
    searchParams.delete("isOpen");
    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
    ctx.showModal("login");
  };

  const handleSubmit = async (values) => {
    if (values.password !== values.confirmPassword) {
      toast.error("Password and Confirm Password do not match", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    const payload = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email.toLowerCase(),
      phone: `+${values.phone}`,
      password: values.password,
      deviceToken: "1234567890",
      deviceType: "web",
    };

    if (nominationData) {
      payload.invitedByVet = nominationData.vspId;
    }

    try {
      const res = await signupApiNew(payload);
      const { data } = res;
      const token = data["api-token"];
      setAccessToken(token);
      ctx.updateUser(data);
      onClose();
      dispatch(setEmailVerified(data.isEmailVerified));
      setOtpModal(true);

      toast.success("Account created successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      localStorage.removeItem("Nomination");
      window.location.reload();
    } catch (err) {
      onClose();
      if (err.message === "Email already used") {
        toast.error("Account already created with this email", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        message.error(err.message);
      }
    }
  };

  return (
    <>
      <h4 className="mb-3">Continue with email</h4>
      <Form form={form} onFinish={handleSubmit}>
        <Form.Item
          name="firstName"
          rules={[
            {
              required: true,
              message: "Please enter your first name",
            },
            {
              min: 2,
              max: 15,
              message: "First name must be between 2 and 15 characters",
            },
          ]}
        >
          <Input
            placeholder="First Name"
            prefix={<GoPerson className="text-3xl text-amber-600" />}
          />
        </Form.Item>

        <Form.Item
          name="lastName"
          rules={[
            {
              required: true,
              message: "Please enter your last name",
            },
            {
              min: 2,
              max: 15,
              message: "Last name must be between 2 and 15 characters",
            },
          ]}
        >
          <Input
            placeholder="Last Name"
            prefix={<GoPerson className="text-3xl text-amber-600" />}
          />
        </Form.Item>

        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: "Please enter your email",
            },
            {
              type: "email",
              message: "Please enter a valid email address",
            },
          ]}
        >
          <Input
            placeholder="Email Address"
            prefix={<AiTwotoneMail className="text-3xl text-amber-600" />}
            disabled={nominationData && nominationData.petOwnerEmail}
          />
        </Form.Item>

        <Form.Item
          name="phone"
          rules={[
            {
              required: true,
              message: "Please enter your phone number",
            },
          ]}
        >
          <PhoneInput
            country={"us"}
            value=""
            placeholder="Phone"
            onChange={(phone) => form.setFieldsValue({ phone })}
            inputStyle={{ width: "100%" }}
            containerClass="phone-input-container"
            inputClass="phone-input"
          />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "Please enter your password",
            },
          ]}
        >
          <Input.Password
            placeholder="Password"
            prefix={<RiLockPasswordLine className="text-3xl text-amber-600" />}
            iconRender={(visible) => (visible ? <FaEye /> : <FaEyeSlash />)}
          />
        </Form.Item>

        <Form.Item
          name="confirmPassword"
          dependencies={["password"]}
          rules={[
            {
              required: true,
              message: "Please confirm your password",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("The two passwords that you entered do not match!")
                );
              },
            }),
          ]}
        >
          <Input.Password
            placeholder="Confirm Password"
            iconRender={(visible) => (visible ? <FaEye /> : <FaEyeSlash />)}
            prefix={<RiLockPasswordLine className="text-3xl text-amber-600" />}
          />
        </Form.Item>

        <Checkbox>I agree to receive text messages from Carson&Bear™.</Checkbox>

        <Form.Item>
          <div className="flex flex-col justify-center text-center mt-4">
            <button
              type="primary"
              htmlType="submit"
              className="bg-amber-500 mb-3 py-3 rounded-lg hover:scale-95 transition-transform duration-300 ease-in-out"
            >
              Sign Up
            </button>
            <span className="text-lg font-semibold">
              Already have an account?{" "}
              <Link to="#" onClick={handleLoginClick} className="text-lime-500">
                Log In
              </Link>
            </span>
          </div>
        </Form.Item>
      </Form>
      {/* Uncomment to enable OTP Modal */}
      {/* <Modal open={otpModal} closeIcon={false}>
        <div className="py-2 text-center">
          <p className="text-center text-xl font-semibold">
            Signup OTP sent to email, please verify your OTP
          </p>
        </div>
      </Modal> */}
    </>
  );
}
