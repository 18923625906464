export const getAddressLine = (addressObject) => {
  if (!addressObject) {
    return ""; // or any default value or handle it appropriately
  }

  const { title, address, city, state, zipcode } = addressObject;

  // Check if essential address properties are present
  if (address && city && state && zipcode) {
    if (title !== null) {
      return `${title}: ${address}, ${city}, ${state} ${zipcode}`;
    } else {
      return `${address}, ${city}, ${state} ${zipcode}`;
    }
  } else {
    return `${title}: ${address} , ${city}, ${state}, ${zipcode}`;
  }
};
