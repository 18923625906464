import React, { useState } from "react";
import { DeleteIcon, MyAddressEditIcon } from "../../../Assets";
import "./SingleAddress.scss";
import { Tag } from "antd";
import axios from "axios";
import Modal from "../../../core/Modal/Modal";
import { HiDotsVertical } from "react-icons/hi";
import { toast } from "react-toastify";
import { motion, AnimatePresence } from "framer-motion";
import { MdEditLocationAlt } from "react-icons/md";
import { AiTwotoneDelete } from "react-icons/ai";

export default function SingleAddress({
  id,
  title,
  address,
  onUpdate,
  onDelete,
  selectable,
  allowEdit = true,
  checked,
  onCheckedUpdate,
  city,
  state,
  country,
  zipcode,
  isDefault,
  isBillingAddress,
}) {
  const [deleteModal, setDeleteModal] = useState(false);
  const [optionsMenuVisible, setOptionsMenuVisible] = useState(false);
  const [isTooltipVisible, setTooltipVisible] = useState(false);
  const [isDeleteTooltipVisible, setDeleteTooltipVisible] = useState(false);
  const handleDeleteHandler = () => {
    setDeleteModal(true);
  };

  const toggleOptionsMenu = () => {
    localStorage.setItem("editAddressId", id);
    setOptionsMenuVisible(!optionsMenuVisible);
  };

  const markAsDefault = async () => {
    try {
      await axios.put(`/users/addresses/markDefault/${id}`);

      toast.success("Address set as default successfully!");
      window.location.reload();
    } catch (error) {
      toast.error("Failed to set as default address.");
    } finally {
      setOptionsMenuVisible(false);
    }
  };

  const markAsBilling = async () => {
    try {
      await axios.put(`/users/addresses/markAsBillingAddress/${id}`);

      toast.success("Address set as billing successfully!");
      window.location.reload();
    } catch (error) {
      toast.error("Failed to set as billing address.");
    } finally {
      setOptionsMenuVisible(false);
    }
  };

  return (
    <div className=" bg-slate-50 p-3 rounded-xl shadow-md border-2 border-amber-100 w-9/12 ">
      <div className="lg:h-[15rem] md:h-[25rem] ">
        <div className="col-md-12">
          <div className=" flex flex-col ">
            <div className="flex mb-4">
              <div className="">
                {isDefault && (
                  <Tag className="p-1 rounded-lg" color="green">
                    Default Address
                  </Tag>
                )}
                {isBillingAddress && (
                  <Tag className="p-1 rounded-lg" color="pink">
                    Billing Address
                  </Tag>
                )}
              </div>

              {allowEdit && (
                <button className="ml-auto" onClick={toggleOptionsMenu}>
                  <HiDotsVertical className="text-2xl" />
                </button>
              )}
            </div>

            <div className="ml-auto relative">
              {optionsMenuVisible && (
                <motion.div
                  initial={{ y: -20, opacity: 0 }}
                  animate={{ y: -10, opacity: 1 }}
                  exit={{ y: -10, opacity: 0 }}
                  transition={{ duration: 0.3 }}
                  className={`absolute right-0 mt-2 z-50  ${id}`}
                >
                  <div className=" flex flex-col text-center">
                    <Tag
                      color="Gray"
                      className="w-36 lg:w-44 p-1  cursor-pointer font-bold  hover:scale-105 transition-transform  duration-300 text-center"
                      onClick={markAsDefault}
                    >
                      Set as Default Address
                    </Tag>
                    <Tag
                      color="Gray"
                      className="w-36 lg:w-44 p-1 mt-1 font-bold cursor-pointer hover:scale-105 transition-transform duration-300 text-center"
                      onClick={markAsBilling}
                    >
                      Set as Billing Address
                    </Tag>
                  </div>
                </motion.div>
              )}
            </div>
            <div className="flex flex-col">
              <label className="text-xl font-bold text-gray-700 ">
                <span>{title}</span>
              </label>
              <p className="text-gray-600 italic">{address}</p>

              <p>
                {country} <span>{zipcode}</span>
              </p>

              {selectable && (
                <div className="round">
                  <input
                    type="checkbox"
                    id={`checkbox_${id}`}
                    checked={checked}
                    onChange={onCheckedUpdate}
                    name="checkbox"
                    value={id}
                  />
                  <label htmlFor={`checkbox_${id}`}></label>
                </div>
              )}
            </div>
          </div>
        </div>
        {allowEdit && (
          <div className="col-md-12">
            <div className="space-x-2">
              <div className="inline-block relative">
                {isTooltipVisible && (
                  <div className="absolute bg-gray-600 text-white p-1 rounded-md bottom-12  w-24 text-sm">
                    Edit Address
                  </div>
                )}
                <button
                  className="text-2xl p-2 rounded-full bg-indigo-500 shadow-sm"
                  onClick={onUpdate}
                  onMouseEnter={() => setTooltipVisible(true)}
                  onMouseLeave={() => setTooltipVisible(false)}
                >
                  <MdEditLocationAlt className="text-white" />
                </button>
              </div>
              {!isDefault && (
                <div className="inline-block relative">
                  {isDeleteTooltipVisible && (
                    <div className="absolute bg-red-200 text-black p-1 rounded-md bottom-12  w-28 text-sm">
                      Delete Address
                    </div>
                  )}
                  <button
                    className="text-2xl p-2 rounded-full bg-red-400 shadow-sm"
                    onClick={handleDeleteHandler}
                    onMouseEnter={() => setDeleteTooltipVisible(true)}
                    onMouseLeave={() => setDeleteTooltipVisible(false)}
                  >
                    <AiTwotoneDelete className="text-red-100" />
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <Modal
        title={"Delete Address?"}
        open={deleteModal}
        onClose={() => setDeleteModal(false)}
      >
        <div className="px-4 text-center">
          <p>This address will be deleted permanently. Are you sure?</p>
          <div className="d-flex justify-content-center mt-4 mb-3">
            <button
              className="btn btn-outline-primary me-2"
              onClick={() => setDeleteModal(false)}
            >
              Cancel
            </button>
            <button className="btn btn-primary" onClick={() => onDelete(id)}>
              Delete
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
