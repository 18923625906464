import Form, { Field } from "rc-field-form";
import React, { useEffect, useState } from "react";
import { Input } from "../../ui/FormElements/FormElements";
import { EmailAddressIcon } from "../../Assets";
import { forgotPasswordApi } from "../../services/api.service";
import "./ForgetPassword.scss";
import { toast } from "react-toastify";

export default function ForgetPassword({ onClose }) {
  const [form] = Form.useForm();
  const [stage, setStage] = useState(0);
  const [email, setEmail] = useState(null);
  const handleSubmit = (values) => {
    forgotPasswordApi({
      email: values.email,
    })
      .then((res) => {
        setEmail(values.email);
        setStage(1);
      })
      .catch((res) => {
        toast.error("Email is not registered with us.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  if (stage === 1) {
    return (
      <div className="password-reset-sent text-center">
        <div className="img-wrapper">
          <img src="/images/forget-password-img.png" alt="Forget Password" />
        </div>
        <div className="message">
          We have shared a password reset link on {email}
        </div>
        <button
          className="btn btn-primary w-100 py-3 fs-5 mt-4"
          onClick={onClose}
        >
          Okay
        </button>
      </div>
    );
  }

  return (
    <Form className="auth-form" onFinish={handleSubmit} form={form}>
      {(values, form) => {
        return (
          <div>
            <h4 className="mb-3">Forget your password?</h4>
            <Field
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your email!",
                },
              ]}
            >
              <Input
                placeholder="Email Address"
                error={form.getFieldError("email")[0]}
                icon={<EmailAddressIcon />}
                className="outline-none"
              />
            </Field>
            <button
              className="btn btn-primary w-100 py-3 fs-5 mt-4"
              type="submit"
            >
              Send Password Reset Link
            </button>
          </div>
        );
      }}
    </Form>
  );
}
