import { createContext, useState, useContext } from "react";

const ModalContext = createContext();

export const MODAL_TYPES = {
  EDIT_PROFILE: "EDIT_PROFILE",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  MY_ADDRESSES: "MY_ADDRESSES",
  ADDRESSES_LIST: "ADDRESSES_LIST",
  MARK_PET_AS_DECEASED: "MARK_PET_AS_DECEASED",
  SUBSCRIPTION_CONFIRMED: "SUBSCRIPTION_CONFIRMED",
  CANCEL_SUBSCRIPTION: "CANCEL_SUBSCRIPTION",
  PET_DECEASE: "PET_DECEASE",
};

export const ModalProvider = ({ children }) => {
  const [modalConfig, setModalConfig] = useState({
    activeModals: [
      {
        modalType: "login",
        modalData: null,
      },
    ],
  });

  const showModal = ({ modalType, modalData }) => {
    const updatedModalConfig = {
      ...modalConfig,
      activeModals: [
        ...modalConfig.activeModals,
        {
          modalType,
          modalData,
        },
      ],
    };

    setModalConfig(updatedModalConfig);
  };

  const hideModal = (modalType) => {
    const updatedModalConfig = {
      ...modalConfig,
      activeModals: modalConfig.activeModals.filter(
        (modal) => modal.modalType !== modalType
      ),
    };
    setModalConfig(updatedModalConfig);
  };

  return (
    <ModalContext.Provider
      value={{ activeModals: modalConfig.activeModals, showModal, hideModal }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export const useModals = () => useContext(ModalContext);
