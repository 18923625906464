import React, { useState } from "react";
import { DeleteIcon, MyAddressEditIcon } from "../../../Assets";
import { Avatar, Card, Tag } from "antd";
import {
  EditOutlined,
  EllipsisOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { MdEditLocationAlt, MdDelete } from "react-icons/md";
import { motion, AnimatePresence } from "framer-motion";
import { BsThreeDots } from "react-icons/bs";
import "./SingleAddress.scss";
import axios from "axios";
import Modal from "../../../core/Modal/Modal";
import { HiDotsVertical } from "react-icons/hi";
import { toast } from "react-toastify";
const { Meta } = Card;
import { CiBookmarkCheck, CiBookmarkRemove } from "react-icons/ci";
import { FaCheckCircle } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import { MdModeEdit } from "react-icons/md";

const SingleAddressModernBox = ({
  id,
  title,
  address,
  onUpdate,
  onDelete,
  country,
  zipcode,
  selectable,
  allowEdit = true,
  checked,
  onCheckedUpdate,
  isDefault,
  isBillingAddress,
  isVerified,
}) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [optionsMenuVisible, setOptionsMenuVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleDeleteHandler = () => {
    setDeleteModal(true);
  };

  const toggleOptionsMenu = () => {
    setOptionsMenuVisible(!optionsMenuVisible);
  };

  const markAsDefault = async () => {
    try {
      await axios.put(`/users/addresses/markDefault/${id}`);

      toast.success("Address set as default successfully!");
      window.location.reload();
    } catch (error) {
      toast.error("Failed to set as default address.");
    } finally {
      setOptionsMenuVisible(false);
    }
  };

  const markAsBilling = async () => {
    try {
      await axios.put(`/users/addresses/markAsBillingAddress/${id}`);
      toast.success("Address set as billing successfully!");
      window.location.reload();
    } catch (error) {
      toast.error("Failed to set as billing address.");
    } finally {
      setOptionsMenuVisible(false);
    }
  };
  const onDeleteAddress = async () => {
    try {
      setLoading(true);
      // Make DELETE request to delete the address
      await axios.delete(`/users/addresses/${id}`);
      // Address successfully deleted
      toast.success("Address deleted successfully");

      setDeleteModal(false); // Close the modal
      window.location.reload();
    } catch (error) {
      // Error occurred while deleting the address
      toast.error(error.message);
      setDeleteModal(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-row ">
      <Card
        className="bg-gradient-to-r from-blue-100 to-orange-100 "
        style={{
          width: 400,
        }}
        actions={[
          isVerified ? (
            <div className="flex justify-center text-center items-center text-lime-600 font-bold z-50 ">
              <FaCheckCircle className="text-lime-600 text-2xl mr-2" />
              Verified
            </div>
          ) : (
            <div className="flex justify-center text-center items-center text-red-600 font-bold z-50 ">
              <MdCancel className="text-red-600 text-2xl mr-2" />
              Unverified
            </div>
          ),
          allowEdit && !isBillingAddress && (
            <MdModeEdit
              className="justify-center items-center text-center text-xl mx-auto"
              onClick={onUpdate}
            />
          ),
          !isDefault && (
            <MdDelete
              className="justify-center items-center text-center text-xl mx-auto"
              onClick={handleDeleteHandler}
            />
          ),
          <BsThreeDots
            className="justify-center items-center text-center text-xl mx-auto"
            onClick={toggleOptionsMenu}
          />,
        ]}
      >
        <Meta title={title} description={address} />
        <p className="mt-2">
          {country} <span>{zipcode}</span>
        </p>
        <div className="flex">
          {isDefault && (
            <Tag className="p-0.5 " color="Green">
              Default Address
            </Tag>
          )}
          {isBillingAddress && (
            <Tag className="p-0.5 " color="#920D78 ">
              Billing Address
            </Tag>
          )}
        </div>
      </Card>
      <div className="ml-auto relative">
        {optionsMenuVisible && (
          <motion.div
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: -10, opacity: 1 }}
            exit={{ y: -10, opacity: 0 }}
            transition={{ duration: 0.3 }}
            className={`absolute right-0 mt-2 z-50  ${id}`}
          >
            <div className=" flex flex-col text-center">
              <Tag
                color="Gray"
                className="w-36 lg:w-44 p-1  cursor-pointer font-bold  hover:scale-105 transition-transform  duration-300 text-center"
                onClick={markAsDefault}
              >
                Set as Default Address
              </Tag>
              <Tag
                color="Gray"
                className="w-36 lg:w-44 p-1 mt-1 font-bold cursor-pointer hover:scale-105 transition-transform duration-300 text-center"
                onClick={markAsBilling}
              >
                Set as Billing Address
              </Tag>
            </div>
          </motion.div>
        )}
      </div>
      <Modal
        title={"Delete Address?"}
        open={deleteModal}
        onClose={() => setDeleteModal(false)}
      >
        <div className="px-4 text-center">
          <p>This address will be deleted permanently. Are you sure?</p>
          <div className="d-flex justify-content-center mt-4 mb-3">
            <button
              className="btn btn-outline-primary me-2"
              onClick={() => setDeleteModal(false)}
            >
              Cancel
            </button>
            <button className="btn btn-primary" onClick={onDeleteAddress}>
              {loading ? "Deleting..." : "Delete"}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SingleAddressModernBox;
