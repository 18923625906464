import axios from "axios";
import { clearAccessToken, getAccessToken } from "./localstorage";

axios.defaults.baseURL =
  process.env.REACT_APP_API_SERVER || "https://stg-api.carsonandbearpets.com";

axios.interceptors.request.use(
  (config) => {
    try {
      config.headers = {
        "access-token": `${getAccessToken()}`,
      };
    } catch (error) {
      //
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    //
    if (error.response) {
      const response = error.response;
      const statusCode = error.statusCode;
      const status = response.status;

      if (status === 403) {
        //
        clearAccessToken();
        // clearOrganizationAccessToken();
        window.location.href = "/";
      }
      if (status >= 400) {
        throw response.data;
      }
    }
  }
);

export default axios;
