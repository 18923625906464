import React from "react";
import Modal from "../../core/Modal/Modal";
import { FormGroup, Input } from "../../ui/FormElements/FormElements";
import Form, { Field } from "rc-field-form";
import { EditIcon, EmailAddressIcon, PasswordIcon } from "../../Assets";
import Avatar from "react-avatar";
import { MODAL_TYPES, useModals } from "../../context/modalContext";
import { useAuth } from "../../context/authContext";
import Select from "react-select";
import { updateAccount } from "../../services/api.service";
import "./EditProfileModal.scss";
import axios from "../../services/axios";
import { toast } from "react-toastify";

const GenderValues = [
  {
    value: "male",
    label: "Male",
  },
  {
    value: "female",
    label: "Female",
  },
];

export default function EditProfileModal() {
  const [form] = Form.useForm();
  const modalCtx = useModals();
  const { user, updateUser } = useAuth();

  const handleSubmit = (values) => {
    const update = {
      firstName: values.firstName,
      lastName: values.lastName,
    };
    if (values.dob) {
      update.dob = values.dob;
    }
    if (values.gender) {
      update.gender = values.gender;
    }
    updateAccount(update).then((res) => {
      const user = res.data;
      updateUser(user);
      toast.success("Profile updated successfully");
      closeModal();
    });
  };

  const closeModal = () => {
    modalCtx.hideModal(MODAL_TYPES.EDIT_PROFILE);
  };

  const showChangePasswordModal = () => {
    modalCtx.showModal({
      modalType: MODAL_TYPES.CHANGE_PASSWORD,
      modalData: {},
    });
  };

  const handleFileUpload = (e) => {
    const files = e.target.files;
    const file = files[0];
    const data = new FormData();
    data.append(`image`, file, file.name);

    axios
      .post("/file-upload", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(({ data: res }) => {
        const data = res.data;
        updateAccount({
          profilePicURL: data.location,
        }).then((res) => {
          const user = res.data;
          updateUser(user);
        });
      });
  };

  return (
    <Modal open={true} title={"Edit Profile"} onClose={closeModal} width="1200">
      <div className="p-4">
        <Form
          className="create-form"
          onFinish={handleSubmit}
          form={form}
          initialValues={{
            firstName: user?.firstName,
            lastName: user?.lastName,
            email: user?.email,
            dob: user?.dob,
            gender: user?.gender,
          }}
        >
          {(values, form) => {
            return (
              <div>
                <div className="row pb-5">
                  <div className="col-md-12 text-center">
                    <div>
                      <div className="d-block">
                        <Avatar
                          name={user.firstName + " " + user.lastName}
                          size="100"
                          round={true}
                          src={user.profilePicURL}
                          textSizeRatio={1}
                        />
                      </div>
                      <div className="btn btn-text d-inline-flex gap-2 file-upload-trigger">
                        <span className="fw-bold">Edit Photo</span>
                        <EditIcon />
                        <input
                          type="file"
                          className="file-upload"
                          accept="image/png, image/jpeg"
                          onChange={handleFileUpload}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <FormGroup>
                      <label htmlFor="firstName">First Name</label>
                      <Field
                        name="firstName"
                        rules={[
                          {
                            required: true,
                            message: "Please input your firstName!",
                          },
                        ]}
                      >
                        <Input
                          value={user?.firstName}
                          error={form.getFieldError("firstName")[0]}
                        />
                      </Field>
                    </FormGroup>
                  </div>
                  <div className="col-md-6">
                    <FormGroup>
                      <label htmlFor="lastName">Last Name</label>
                      <Field
                        name="lastName"
                        rules={[
                          {
                            required: true,
                            message: "Please input your last name!",
                          },
                        ]}
                      >
                        <Input
                          value={user?.firstName}
                          error={form.getFieldError("lastName")[0]}
                        />
                      </Field>
                    </FormGroup>
                  </div>
                  <div className="col-md-12">
                    <Field
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Please input your email!",
                        },
                      ]}
                    >
                      <FormGroup>
                        <div>
                          <label htmlFor="email">Email</label>
                          <Input
                            value={user?.email}
                            error={form.getFieldError("email")[0]}
                            disabled={true}
                          />
                        </div>
                      </FormGroup>
                    </Field>
                  </div>

                  <div className="col-md-6">
                    <FormGroup>
                      <div>
                        <label htmlFor="dob">Birthdate</label>
                        <Field name="dob">
                          <Input value={user?.dob || ""} type="date" />
                        </Field>
                      </div>
                    </FormGroup>
                  </div>
                  <div className="col-md-6">
                    <Field name="gender">
                      <FormGroup>
                        <div>
                          <label htmlFor="gender">Gender</label>
                          <div className="select-wrap">
                            <Select
                              name="gender"
                              className="react-select-custom"
                              classNamePrefix="react-select-custom"
                              options={GenderValues}
                              value={
                                GenderValues.find(
                                  (v) =>
                                    v.value === form.getFieldValue("gender")
                                ) || ""
                              }
                              onChange={({ value }) => {
                                form.setFieldValue("gender", value);
                              }}
                            />
                          </div>
                        </div>
                      </FormGroup>
                    </Field>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <button
                    className="btn btn-text p-0 fw-medium text-green"
                    onClick={showChangePasswordModal}
                    type="button"
                  >
                    Change Password
                  </button>
                  <button className="btn btn-theme" type="submit">
                    Update
                  </button>
                </div>
              </div>
            );
          }}
        </Form>
      </div>
    </Modal>
  );
}
