import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./authSlice";
import suggestedVetReducer from "./suggestedVetSlice";
import triggerReducer, {
  selectShouldTriggerAction,
  selectDiscountTotalAmount,
} from "./slice";

const store = configureStore({
  reducer: {
    auth: authReducer,
    suggestedVet: suggestedVetReducer,
    trigger: triggerReducer,
  },
});

export { selectShouldTriggerAction, selectDiscountTotalAmount };
export default store;
