import React, { useEffect, useState } from "react";
import Page from "../../components/core/Page/Page";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { initSubscription } from "../../components/services/api.service";
import { useLocation } from "react-router-dom";

// Test mode
const stripePromise = loadStripe(
  "pk_test_51MvOEbGcnkPKGaUh0bexrc8MU4uAzDOwk43ksV972EEH4r8SGJjStxlHouTJaHYio5MWifq24ivcaWM1J0YZowqd009ddkR8eV"
);

// Live Mode
// const stripePromise = loadStripe(
//   "pk_live_51MvOEbGcnkPKGaUh3cIsv2Kv7FjqxygDjVgRhOvDIqWzmIFyVEUDboXl2X0Ez5rTzfeF7Amx9fHXdHWppF1sEUAp001S1t5Aui"
// );

const CheckoutTestForm = ({ clientId, clientSecret, amount }) => {
  const stripe = useStripe();
  const elements = useElements();

  if (!clientId || !clientSecret) {
    return;
  }
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    const redirectUrl =
      window.location.origin +
      `/checkoutPayment/success?payment_intent=${clientId}&client_secret=${clientSecret}`;

    const result = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: redirectUrl,
      },
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)

      alert(result.error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <button className="btn btn-theme w-100 mt-4">
        Make Payment : {"$" + amount}
      </button>
    </form>
  );
};

export default function CheckoutTest() {
  const location = useLocation();
  const [intent, setIntent] = useState();

  const handleStripe = () => {
    const searchParams = new URLSearchParams(location.search);

    // Extracting specific parameters from the URL
    const setupIntentId = searchParams.get("setupIntentId");

    const setupIntent = searchParams.get("setupIntent");
    const ephemeralKey = searchParams.get("ephemeralKey");
    const user = searchParams.get("user");
    const amount = searchParams.get("amount");

    setIntent({
      ...intent,
      //   setupIntentId: "seti_1ORrqaGcnkPKGaUhuTPA4dB5",
      setupIntentId: setupIntentId,
      //   setupIntent: "seti_1ORrqaGcnkPKGaUhuTPA4dB5_secret_PGOdEZUcK15JysALAsQ2uu4ekkYzNJr",
      setupIntent: setupIntent,
      ephemeralKey: ephemeralKey,
      user: user,
      amount: amount,
    });
  };

  useEffect(() => {
    handleStripe();
  }, []);

  //   const handleUpdateIntent = () => {
  //     initSubscription()
  //       .then((res) => {
  //
  //         setIntent(res.data);
  //       })
  //       .catch((err) =>
  //   };

  //   useEffect(() => {
  //     handleUpdateIntent();
  //   }, []);

  if (!intent) {
    return "Loading...";
  }

  return (
    <div title="Checkout">
      {intent && intent.setupIntent && (
        <div className="container mt-5">
          <div className="row">
            <div className="col-md-6 mx-auto">
              {intent && (
                <div className="card border p-5">
                  <Elements
                    stripe={stripePromise}
                    options={{
                      clientSecret: intent.setupIntent,
                    }}
                  >
                    <CheckoutTestForm
                      clientId={intent.setupIntentId}
                      clientSecret={intent.setupIntent}
                      amount={intent?.amount}
                    />
                  </Elements>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
