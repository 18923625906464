import React from "react";
import Modal from "../../core/Modal/Modal";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useQuery } from "react-query";
import { getPets } from "../../../components/services/api.service";
import { useAuth } from "../../../components/context/authContext";
import { toast } from "react-toastify";
export default function SubscriptionComplete({ subscriptionId }) {
  const navigate = useNavigate();
  const continueClicked = () => {
    window.location.href = "/pets";
  };
  const { data: pets = [] } = useQuery("pets", getPets);
  const { subscription } = useAuth();

  const getFilteredPets = (pets) => {
    return [
      ...pets.filter(
        (pet) =>
          !pet.isDeceased &&
          !pet.cancelledSubscription &&
          !pet.isPausedSubscription
      ),
    ];
  };

  return (
    <Modal open={true} width={800} onClose={continueClicked}>
      <div className="text-center">
        <h3> Your subscription is now active.</h3>
        <div className="py-5">
          <img src="/images/dogs-in-heart.png" alt="Dogs" className="ml-56" />

          {/* <img src="/images/thank-you-note.png" className="mt-4" alt="" /> */}
          <p className="fs-3 mt-3">
            <div>
              {getFilteredPets(pets).map((pet, index, array) => (
                <span key={pet.id} style={{ color: "#E68F00" }}>
                  {pet.petName}
                  {index < array.length - 1 ? ", " : ", "}
                </span>
              ))}
              <span>thank you for joining the pack!</span>
            </div>
          </p>
        </div>
        <div className="px-4">
          <div className="border-top mb-5"></div>
        </div>
        <button
          className="w-100 btn btn-primary py-3 fs-5"
          onClick={continueClicked}
        >
          Continue
        </button>
      </div>
    </Modal>
  );
}
