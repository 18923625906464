import React, { useState } from "react";
import { DeleteIcon, MyAddressEditIcon } from "../../../Assets";
import "./SingleAddress.scss";
import axios from "axios";
import Modal from "../../../core/Modal/Modal";
import { HiDotsVertical } from "react-icons/hi";
import { toast } from "react-toastify";

const SingleAddressPet = ({
  id,
  title,
  address,
  onUpdate,
  onDelete,
  selectable,
  allowEdit = true,
  checked,
  onCheckedUpdate,
  isDefault,
  isBillingAddress,
}) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [optionsMenuVisible, setOptionsMenuVisible] = useState(false);

  const handleDeleteHandler = () => {
    setDeleteModal(true);
  };

  const toggleOptionsMenu = () => {
    setOptionsMenuVisible(!optionsMenuVisible);
  };

  const markAsDefault = async () => {
    try {
      await axios.put(`/users/addresses/markDefault/${id}`);

      toast.success("Address set as default successfully!");
      window.location.reload();
    } catch (error) {
      toast.error("Failed to set as default address.");
    } finally {
      setOptionsMenuVisible(false);
    }
  };

  const markAsBilling = async () => {
    try {
      await axios.put(`/users/addresses/markAsBillingAddress/${id}`);

      toast.success("Address set as billing successfully!");
      window.location.reload();
    } catch (error) {
      toast.error("Failed to set as billing address.");
    } finally {
      setOptionsMenuVisible(false);
    }
  };

  return (
    <div className="single-address single-address-component">
      <div className="row bg-light py-4 px-3 m-0 relative">
        <div className="col-md-12">
          <div className=" flex flex-col single-address-component">
            <div className="ml-auto relative">
              {allowEdit && (
                <button className="ml-auto" onClick={toggleOptionsMenu}>
                  <HiDotsVertical className="text-2xl" />
                </button>
              )}

              {optionsMenuVisible && (
                <div
                  className={`absolute right-0 mt-2 z-50 bg-amber-200 border w-44 rounded-md shadow-md ${id}`}
                >
                  <button
                    className="block px-2 py-2 text-sm text-gray-700 hover:bg-amber-300 font-semibold w-full"
                    onClick={markAsDefault}
                  >
                    Set as Default Address
                  </button>
                  <button
                    className="block px-2 py-2 text-sm text-gray-700 hover:bg-amber-300 font-semibold w-full"
                    onClick={markAsBilling}
                  >
                    Set as Billing Address
                  </button>
                </div>
              )}
            </div>
            <div className="d-flex justify-content-between">
              <label className="fs-5 fw-medium mb-2 flex justify-between">
                <span>{title}</span>
              </label>

              <div className="ml-auto absolute top-16 right-10">
                {isDefault && (
                  <span className="p-2 bg-amber-600 text-amber-700 rounded-lg bg-opacity-25 border-2 border-amber-500 italic text-sm mr-2">
                    Default Address
                  </span>
                )}
                {isBillingAddress && (
                  <span className="p-2 bg-green-600 text-green-700 rounded-lg bg-opacity-25 border-2 border-green-500 italic text-sm">
                    Billing Address
                  </span>
                )}
              </div>
              {selectable && (
                <div className="round">
                  <input
                    type="checkbox"
                    id={`checkbox_${id}`}
                    checked={checked}
                    onChange={onCheckedUpdate}
                    name="checkbox"
                    value={id}
                  />
                  <label htmlFor={`checkbox_${id}`}></label>
                </div>
              )}
            </div>
            <p className="p-0 mb-4">{address}</p>
          </div>
        </div>
        {allowEdit && (
          <div className="col-md-12">
            <div className="d-flex justify-content-start align-items-center gap-2">
              <button
                className="my-address-icon icon-container border-0"
                onClick={onUpdate}
              >
                <MyAddressEditIcon />
              </button>
              {!isDefault && (
                <button
                  className="mx-2 icon-container border-0"
                  onClick={handleDeleteHandler}
                >
                  <DeleteIcon />
                </button>
              )}
            </div>
          </div>
        )}
      </div>
      <Modal
        title={"Delete Address?"}
        open={deleteModal}
        onClose={() => setDeleteModal(false)}
      >
        <div className="px-4 text-center">
          <p>This address will be deleted permanently. Are you sure?</p>
          <div className="d-flex justify-content-center mt-4 mb-3">
            <button
              className="btn btn-outline-primary me-2"
              onClick={() => setDeleteModal(false)}
            >
              Cancel
            </button>
            <button className="btn btn-primary" onClick={() => onDelete(id)}>
              Delete
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SingleAddressPet;
