// suggestedVetSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  suggestedVetData: {},
};

const suggestedVetSlice = createSlice({
  name: "suggestedVet",
  initialState,
  reducers: {
    updateFormData(state, action) {
      state.suggestedVetData = action.payload;
    },
  },
});

export const { updateFormData } = suggestedVetSlice.actions;

export const selectSuggestedVetData = (state) =>
  state.suggestedVet.suggestedVetData;

export default suggestedVetSlice.reducer;
