import React, { useEffect, useState } from "react";
import { ThreeDotIcon } from "../../Assets";
import "./SinglePet.scss";
import { Link } from "react-router-dom";
import { MdCancel } from "react-icons/md";
import { Modal, Button, Spin } from "antd";
import Avatar from "react-avatar";
import { PiDogFill } from "react-icons/pi";
import { MODAL_TYPES, useModals } from "../../context/modalContext";
import Axios from "axios";
import classNames from "classnames";
import { Menu, Transition } from "@headlessui/react";
import { IoEllipsisHorizontalSharp } from "react-icons/io5";
import { Tag } from "antd";
import { toast } from "react-toastify";
import LoadingScreenTrans from "../../../pages/AddNewPet/LoadingScreenTrans";
import Petmodal from "./Petmodal";

export const MoreOptionsDropdown = ({
  petId,
  petPlan,
  isPausedSubscription,
  petAddress,
  multiplePets,
  activePetPresent,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [pauseModal, setPauseModal] = useState(false);
  const modalCtx = useModals();

  const [open, setOpen] = useState(false);

  const optionClicked = (opt) => async () => {
    setOpen(false);
    setIsLoading(true);
    if (opt === "deceased") {
      modalCtx.showModal({
        modalType: MODAL_TYPES.PET_DECEASE,
        modalData: {
          petId: petId,
        },
      });
    }
    // if (opt === "pause" && multiplePets === false) {
    //   toast.error("You cannot pause the last pet.");
    //   setIsLoading(false);
    //   return;
    // }
    if (opt === "pause") {
      try {
        const response = await Axios.put(`/users/subscription/pause/${petId}`);
        if (response.status === 200) {
          toast.success("Subscription paused successfully");
          window.location.reload();
        } else {
          toast.error("Failed to pause subscription");
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          toast.error(error.message || "Failed to pause subscription");
        } else {
          toast.error(error.message);
        }
      }
      // setPauseModal(true);
    }

    if (opt === "resume") {
      try {
        const response = await Axios.put(`/users/subscription/resume/${petId}`);
        if (response.status === 200) {
          toast.success("Subscription resume successfully");
          window.location.reload();
        } else {
          toast.error("Failed to resume subscription");
        }
      } catch (error) {
        toast.error("Failed to resume subscription");
      }
    }

    if (opt === "cancel") {
      modalCtx.showModal({
        modalType: MODAL_TYPES.CANCEL_SUBSCRIPTION,
        modalData: {
          petId: petId,
        },
      });
    }

    if (opt === "delete") {
      handleDeletePet();
    }
    setIsLoading(false);
  };

  const handleOutSideClick = (e) => {
    if (e.target.closest(".more-options-dropdown .btn")) {
      return;
    }
    setOpen(false);
  };

  const handleEditPetClick = () => {
    localStorage.setItem("editPetId", petId);
    localStorage.setItem("editPetPlan", petPlan);
    localStorage.setItem("petAddress", petAddress);
    // window.location.reload();
  };

  const handleDeletePet = async () => {
    try {
      const response = await Axios.delete(`/pet-profiles/${petId}`);
      if (response.status === 200) {
        toast.success("Pet deleted successfully");
        window.location.reload();
      } else {
        toast.error("Failed to delete pet");
      }
    } catch (error) {}
  };

  return (
    <>
      <Modal
        visible={pauseModal}
        onCancel={() => setPauseModal(false)}
        footer={null}
      >
        <div className="mt-10">
          <div className="mt-3 text-center sm:mt-5">
            <div className="mt-2">
              <p className="text-xl font-bold italic text-gray-600">
                To pause the subscription, please contact customer support at
              </p>
            </div>
            {/* Underlined the email address and made it a mailto: link */}
            <div>
              <span>mailto: </span>
              <a
                href="mailto:customersupport@carsonandbear.com"
                className="text-base font-semibold leading-6 text-gray-900 underline"
              >
                customersupport@carsonandbear.com
              </a>
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-6">
          <button
            type="button"
            className="inline-flex w-full justify-center rounded-md bg-[#448EBE] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#7bbce8] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={() => setPauseModal(false)}
          >
            Close
          </button>
        </div>
      </Modal>

      {isLoading && <Spin />}
      {!isLoading && (
        <div className="more-options-dropdown relative z-40">
          <div className="trigger">
            <button className="btn btn-text" onClick={() => setOpen(!open)}>
              <ThreeDotIcon />
            </button>
          </div>
          {open && (
            <div className="w-56 lg:w-64 justify-center text-center bg-amber-100 right-6 rounded-lg shadow-lg z-50 absolute">
              <ul className="list-unstyled">
                <li>
                  <Link to={"/pets/add"}>
                    <button
                      className="btn btn-text"
                      onClick={handleEditPetClick}
                    >
                      View / Edit pet
                    </button>
                  </Link>
                </li>

                <li>
                  {isPausedSubscription && activePetPresent ? (
                    <button
                      className="btn btn-text"
                      onClick={optionClicked("resume")}
                    >
                      Resume Subscription
                    </button>
                  ) : (
                    <button
                      className="btn btn-text"
                      onClick={optionClicked("pause")}
                    >
                      Pause Subscription
                    </button>
                  )}
                </li>
                <li>
                  <button
                    className="btn btn-text"
                    onClick={optionClicked("cancel")}
                  >
                    Cancel Subscription
                  </button>
                </li>
                <li>
                  <button
                    className="btn btn-text"
                    onClick={optionClicked("deceased")}
                  >
                    Mark as Deceased
                  </button>
                </li>
                {/* <li>
              <button
                className="btn btn-text"
                onClick={optionClicked("delete")}
              >
                Delete pet
              </button>
            </li> */}
              </ul>
            </div>
          )}
        </div>
      )}
    </>
  );
};
const MoreOptionsDropdownThree = ({
  petId,
  petPlan,
  isPausedSubscription,
  petAddress,
  multiplePets,
  activePetPresent,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [pauseModal, setPauseModal] = useState(false);
  const modalCtx = useModals();

  const [open, setOpen] = useState(false);

  const optionClicked = (opt) => async () => {
    setOpen(false);
    setIsLoading(true);
    if (opt === "deceased") {
      modalCtx.showModal({
        modalType: MODAL_TYPES.PET_DECEASE,
        modalData: {
          petId: petId,
        },
      });
    }
    // if (opt === "pause" && multiplePets === false) {
    //   toast.error("You cannot pause the last pet.");
    //   setIsLoading(false);
    //   return;
    // }
    if (opt === "pause") {
      try {
        const response = await Axios.put(`/users/subscription/pause/${petId}`);
        if (response.status === 200) {
          toast.success("Subscription paused successfully");
          window.location.reload();
        } else {
          toast.error("Failed to pause subscription");
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          toast.error(error.message || "Failed to pause subscription");
        } else {
          toast.error(error.message);
        }
      }
      // setPauseModal(true);
    }

    if (opt === "resume") {
      try {
        const response = await Axios.put(`/users/subscription/resume/${petId}`);
        if (response.status === 200) {
          toast.success("Subscription resume successfully");
          window.location.reload();
        } else {
          toast.error("Failed to resume subscription");
        }
      } catch (error) {
        toast.error("Failed to resume subscription");
      }
    }

    if (opt === "cancel") {
      modalCtx.showModal({
        modalType: MODAL_TYPES.CANCEL_SUBSCRIPTION,
        modalData: {
          petId: petId,
        },
      });
    }

    if (opt === "delete") {
      handleDeletePet();
    }
    setIsLoading(false);
  };

  const handleOutSideClick = (e) => {
    if (e.target.closest(".more-options-dropdown .btn")) {
      return;
    }
    setOpen(false);
  };

  const handleEditPetClick = () => {
    localStorage.setItem("editPetId", petId);
    localStorage.setItem("editPetPlan", petPlan);
    localStorage.setItem("petAddress", petAddress);
    // window.location.reload();
  };

  const handleDeletePet = async () => {
    try {
      const response = await Axios.delete(`/pet-profiles/${petId}`);
      if (response.status === 200) {
        toast.success("Pet deleted successfully");
        window.location.reload();
      } else {
        toast.error("Failed to delete pet");
      }
    } catch (error) {}
  };

  return (
    <>
      <Modal
        visible={pauseModal}
        onCancel={() => setPauseModal(false)}
        footer={null}
      >
        <div className="mt-10">
          <div className="mt-3 text-center sm:mt-5">
            <div className="mt-2">
              <p className="text-xl font-bold italic text-gray-600">
                To pause the subscription, please contact customer support at
              </p>
            </div>
            {/* Underlined the email address and made it a mailto: link */}
            <div>
              <span className="text-base font-semibold leading-6 text-gray-900 underline">
                mailto:{" "}
              </span>
              <a
                href="mailto:customersupport@carsonandbear.com"
                className="text-base font-semibold leading-6 text-gray-900 underline"
              >
                customersupport@carsonandbear.com
              </a>
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-6">
          <button
            type="button"
            className="inline-flex w-full justify-center rounded-md bg-[#448EBE] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#7bbce8] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={() => setPauseModal(false)}
          >
            Close
          </button>
        </div>
      </Modal>

      {isLoading && <Spin />}
      {!isLoading && (
        <div className="more-options-dropdown relative z-40">
          <div className="trigger">
            <button className="btn btn-text" onClick={() => setOpen(!open)}>
              <ThreeDotIcon />
            </button>
          </div>
          {open && (
            <div className="w-48 lg:w-56 bg-amber-100 right-6 rounded-lg shadow-lg z-50 absolute">
              <ul className="list-unstyled">
                <li>
                  <Link to={"/pets/add"}>
                    <button
                      className="btn btn-text"
                      onClick={handleEditPetClick}
                    >
                      View / Edit pet
                    </button>
                  </Link>
                </li>

                <li>
                  {isPausedSubscription && activePetPresent ? (
                    <button
                      className="btn btn-text"
                      onClick={optionClicked("resume")}
                    >
                      Resume Subscription
                    </button>
                  ) : (
                    <button
                      className="btn btn-text"
                      onClick={optionClicked("pause")}
                    >
                      Pause Subscription
                    </button>
                  )}
                </li>
                <li>
                  <button
                    className="btn btn-text"
                    onClick={optionClicked("cancel")}
                  >
                    Cancel Subscription
                  </button>
                </li>
                <li>
                  <button
                    className="btn btn-text"
                    onClick={optionClicked("deceased")}
                  >
                    Mark as Deceased
                  </button>
                </li>
                {/* <li>
              <button
                className="btn btn-text"
                onClick={optionClicked("delete")}
              >
                Delete pet
              </button>
            </li> */}
              </ul>
            </div>
          )}
        </div>
      )}
    </>
  );
};
const MoreOptionsDropdownInactive = ({
  petId,
  petPlan,
  isPausedSubscription,
  petAddress,
  multiplePets,
  activePetPresent,
}) => {
  const modalCtx = useModals();
  const [pauseModal, setPauseModal] = useState(false);
  const [open, setOpen] = useState(false);

  const optionClicked = (opt) => async () => {
    setOpen(false);

    if (opt === "deceased") {
      modalCtx.showModal({
        modalType: MODAL_TYPES.PET_DECEASE,
        modalData: {
          petId: petId,
        },
      });
    }

    if (opt === "pause") {
      try {
        const response = await Axios.put(`/users/subscription/pause/${petId}`);
        if (response.status === 200) {
          toast.success("Subscription paused successfully");
          window.location.reload();
        } else {
          toast.error("Failed to pause subscription");
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          toast.error(error.message || "Failed to pause subscription");
        } else {
          toast.error(error.message);
        }
      }

      // setPauseModal(true);
    }

    if (opt === "resume") {
      try {
        const response = await Axios.put(`/users/subscription/resume/${petId}`);
        if (response.status === 200) {
          toast.success("Subscription resume successfully");
          window.location.reload();
        } else {
          toast.error("Failed to resume subscription");
        }
      } catch (error) {
        toast.error("Failed to resume subscription");
      }
    }

    if (opt === "cancel") {
      modalCtx.showModal({
        modalType: MODAL_TYPES.CANCEL_SUBSCRIPTION,
        modalData: {
          petId: petId,
        },
      });
    }

    if (opt === "delete") {
      handleDeletePet();
    }
  };

  const handleOutSideClick = (e) => {
    if (e.target.closest(".more-options-dropdown .btn")) {
      return;
    }
    setOpen(false);
  };

  const handleEditPetClick = () => {
    localStorage.setItem("editPetId", petId);
    localStorage.setItem("editPetPlan", petPlan);
    localStorage.setItem("petAddress", petAddress);
    // window.location.reload();
  };

  const handleDeletePet = async () => {
    try {
      const response = await Axios.delete(`/pet-profiles/${petId}`);
      if (response.status === 200) {
        toast.success("Pet deleted successfully");
        window.location.reload();
      } else {
        toast.error("Failed to delete pet");
      }
    } catch (error) {}
  };

  return (
    <>
      <Modal
        visible={pauseModal}
        onCancel={() => setPauseModal(false)}
        footer={null}
      >
        <div className="mt-10">
          <div className="mt-3 text-center sm:mt-5">
            <div className="mt-2">
              <p className="text-xl font-bold italic text-gray-600">
                To pause the subscription, please contact customer support at
              </p>
            </div>
            {/* Underlined the email address and made it a mailto: link */}
            <div>
              <span>mailto: </span>
              <a
                href="mailto:customersupport@carsonandbear.com"
                className="text-base font-semibold leading-6 text-gray-900 underline"
              >
                customersupport@carsonandbear.com
              </a>
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-6">
          <button
            type="button"
            className="inline-flex w-full justify-center rounded-md bg-[#448EBE] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#7bbce8] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={() => setPauseModal(false)}
          >
            Close
          </button>
        </div>
      </Modal>

      <div className="more-options-dropdown">
        <div className="trigger">
          <button className="btn btn-text" onClick={() => setOpen(!open)}>
            <ThreeDotIcon />
          </button>
        </div>
        {open && (
          <div className="w-36 lg:w-44 bg-amber-100 absolute right-10 rounded-lg shadow-lg">
            <ul className="list-unstyled">
              <li>
                <Link to={"/pets/add"}>
                  <button className="btn btn-text" onClick={handleEditPetClick}>
                    View / Edit pet
                  </button>
                </Link>
              </li>

              <li>
                <button
                  className="btn btn-text"
                  onClick={optionClicked("deceased")}
                >
                  Mark as Deceased
                </button>
              </li>
              {/* <li>
              <button
                className="btn btn-text"
                onClick={optionClicked("delete")}
              >
                Delete pet
              </button>
            </li> */}
            </ul>
          </div>
        )}
      </div>
    </>
  );
};
export default function SinglePetNew({
  id,
  activePetPresent,
  name,
  gender,
  age,
  weight,
  plan,
  profilePicURL,
  breed,
  isDeceased,
  isActive,
  isPausedSubscription,
  petAddress,
  multiplePets,
}) {
  const getAge = () => {
    // age is age in months
    if (age < 12) {
      return `${age} Months`;
    }
    return `${Math.floor(age / 12)} Years ${age % 12} Months`;
  };

  return (
    <>
      <ul role="list" className="flex flex-row z-40">
        <li
          // key={client.id}
          className="overflow-hidden rounded-xl border border-gray-200 w-full"
        >
          <div className="flex items-center gap-x-0  border-b border-gray-900/5 bg-gray-50 p-6">
            {profilePicURL ? (
              <img
                src={profilePicURL}
                alt=""
                className="h-20 w-20 mr-3 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10"
              />
            ) : (
              <PiDogFill
                size="100"
                round={true}
                className="h-12 w-12 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10"
              />
            )}
            <div className="text-lg font-medium leading-6 text-gray-900 ml-1">
              {name}
              {isActive && !isDeceased && !isPausedSubscription && (
                <Tag
                  color="green"
                  className="ml-4"
                  style={{ fontSize: "16px" }}
                >
                  Active
                </Tag>
              )}

              {!isActive && (
                <Tag color="red" className="ml-4" style={{ fontSize: "16px" }}>
                  Inactive
                </Tag>
              )}

              {isPausedSubscription && (
                <Tag color="Gray" className="ml-4" style={{ fontSize: "16px" }}>
                  Paused
                </Tag>
              )}
            </div>

            {/* {(activePetPresent && isActive) || */}
            {/* (isPausedSubscription && ( */}
            <div className="pet-action lg:ml-auto ml-0 z-50">
              <MoreOptionsDropdown
                petId={id}
                petPlan={plan}
                activePetPresent={activePetPresent}
                petAddress={petAddress}
                multiplePets={multiplePets}
                isPausedSubscription={isPausedSubscription}
              />
            </div>
            {/* // ))} */}
          </div>
          <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
            <div className="flex justify-between gap-x-4 py-3">
              <dt className="text-gray-800">{breed}</dt>
              <span className="p-1 inline-flex items-center justify-center text-xs font-bold leading-none bg-amber-600 text-amber-700 rounded-lg bg-opacity-25 border-2 border-amber-500">
                {plan} plan
              </span>
            </div>
            <div className="flex justify-between gap-x-4 py-3">
              <dt className="text-gray-500">
                {gender} • {weight} Lbs • {getAge()}
              </dt>
              <dd className="flex items-start gap-x-2">
                <div className="font-medium text-gray-900"></div>
              </dd>
            </div>
          </dl>
        </li>
      </ul>
    </>
  );
}
