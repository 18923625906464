import React, { useState, useEffect } from "react";
import { Modal as AntModal } from "antd";
import Form, { Field } from "rc-field-form";
import { FormGroup, Input } from "../../ui/FormElements/FormElements";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import axios from "axios";
import "./AddNewAddress.scss";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  createAddress,
  getAddressesById,
  updateAddress,
} from "../../services/api.service";
// import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import LoadingScreenTrans from "../../../pages/AddNewPet/LoadingScreenTrans";
import StepsAddress from "../../StepsAddress";

export default function AddNewAddress({ update = false }) {
  const [isLoadingScreen, setIsLoadingScreen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [markerPosition, setMarkerPosition] = useState(null);

  const { addressIds } = useParams();
  const [searchedAddress, setSearchedAddress] = useState({
    city: "",
    state: "",
    postalCode: "",
    latLng: {
      lat: null,
      lng: null,
    },
    fullAddress: "",
    country: "",
  });

  const [verificationStatus, setVerificationStatus] = useState(null);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isConfirmAddress, setIsConfirmAddress] = useState(false);
  const [newlyAddedAddressId, setNewlyAddedAddressId] = useState(null);

  const [isAddMailingAddressClicked, setIsAddMailingAddressClicked] =
    useState(false);

  const [isAddressPresent, setIsAddressPresent] = useState(false);

  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { address: addressId } = useParams();
  const [newMap, setNewMap] = useState(false);
  const [hasSubscription, setHasSubscription] = useState(true);
  const [shippingAddressId, setShippingAddressId] = useState(null);
  const [alrealdyHaveShipping, setAlreadyHaveShipping] = useState(false);
  const [textChange, setTextChange] = useState(false);

  const containerStyle = {
    width: "100%", // Change to full width
    height: "300px", // Adjust height as needed
  };

  const center = {
    lat: searchedAddress.latLng.lat || 40.7128,
    lng: searchedAddress.latLng.lng || -74.006,
  };
  const latString = center.lat.toString();
  const lngString = center.lng.toString();

  localStorage.setItem("latitude", latString);
  localStorage.setItem("longitude", lngString);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBItZMSTc-nNwwZw-5923atDsSVi3_K4CM",
  });

  const [map, setMap] = React.useState(null);
  useEffect(() => {
    const user = localStorage.getItem("user"); // Adjust key according to how you store user data
    if (!user) {
      navigate("/"); // Redirect to homepage if user is not logged in
    }
  }, [navigate]);
  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const { data: address, isLoading } = useQuery(
    ["address", addressId],
    () => getAddressesById(addressId),
    {
      enabled: !!addressId,
    }
  );
  useEffect(() => {
    // Assuming apiUrl is constructed using the addressId from URL params
    const apiUrl = `/users/addresses/${addressId}`;

    // Make the request using Axios
    axios
      .get(apiUrl)
      .then((response) => {
        const addressData = response.data.data; // Access the 'data' object in the response
        setSearchedAddress({
          city: addressData.city,
          state: addressData.state,
          postalCode: addressData.zipcode, // Assuming 'zipcode' is the correct field name
          latLng: {
            lat: parseFloat(addressData.latitude), // Parse latitude as float
            lng: parseFloat(addressData.longitude), // Parse longitude as float
          },
          fullAddress: addressData.address,
          country: addressData.country,
        });
      })
      .catch((error) => {});
  }, [addressId]);

  useEffect(() => {
    const fetchAddresses = async () => {
      try {
        const response = await axios.get("/users/addresses");
        const addresses = response.data.data;

        const hasAddresses = addresses && addresses.length > 0;

        setIsAddressPresent(hasAddresses);
      } catch (error) {}
    };

    fetchAddresses();
  }, []);
  const addAddress = useMutation(createAddress, {
    onSuccess: async (data) => {
      const { id } = data;
      setShippingAddressId(id);
      const planPath = localStorage.getItem("planpath");
      if (planPath) {
        navigate(planPath);
        toast.success("Address added successfully");
      } else {
        navigate("/addresses");
      }
    },
    onError: (error) => {},
  });
  const addAddressFirst = useMutation(createAddress, {
    onSuccess: async (data) => {
      const { id } = data;
      setNewlyAddedAddressId(id);
      setShippingAddressId(id);
      try {
        await axios.put(`/users/addresses/markAsBillingAddress/${id}`);

        queryClient.invalidateQueries("addresses");
        toast.success("Address added successfully");
        setIsConfirmAddress(true);
      } catch (error) {}
    },
    onError: (error) => {},
  });
  const addAddressMailing = useMutation(createAddress, {
    onSuccess: async (data) => {
      const { id } = data;
      setNewlyAddedAddressId(id);
      setShippingAddressId(id);
      const planPath = localStorage.getItem("planpath");

      // Check if alreadyHaveShipping state is true

      try {
        await axios.put(`/users/addresses/markDefault/${id}`);
        setAlreadyHaveShipping(true);
        queryClient.invalidateQueries("addresses");
        toast.success("Address added successfully");
        setIsAddMailingAddressClicked(false);
      } catch (error) {}

      if (planPath) {
        navigate(planPath);
      } else {
        navigate("/addresses");
      }
    },
    onError: (error) => {},
  });

  const handleSameAddressClick = async () => {
    try {
      await axios.put(`/users/addresses/markDefault/${newlyAddedAddressId}`);
      const planPath = localStorage.getItem("planpath");

      queryClient.invalidateQueries("addresses");
      toast.success("Address set as Billing & Mailing successfully");
      setIsConfirmAddress(false);
      if (planPath) {
        navigate(planPath);
      } else {
        navigate("/addresses");
      }
    } catch (error) {}
  };
  const updateAddressMutation = useMutation(
    ({ id, update }) => updateAddress(id, update),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("addresses");
        queryClient.invalidateQueries(["address", addressId]);
        toast.success("Address updated successfully");
        navigate("/addresses");
      },
    }
  );

  const handleSubmit = async (values, latString, lngString) => {
    const obj = {
      title: values.title || "",
      address: values.address || "",
      state: values.state || "",
      city: values.city || "",
      zipcode: values.zipcode || "",
      information: values.information || "",
      latitude: latString || "",
      longitude: lngString || "",
      isVerified: true,
      country: searchedAddress.country || "",
    };

    try {
      setIsLoadingScreen(true);
      const verificationResponse = await axios.get(
        "/users/addresses/validation/verification",
        {
          params: {
            streetAddress: values.address,
            city: values.city,
            state: values.state,
            zipcode: values.zipcode,
          },
        }
      );

      const { verificationStatus, correctedAddress } =
        verificationResponse.data.data;
      setIsLoadingScreen(false);
      setVerificationStatus(verificationStatus);

      if (verificationStatus === "UNKNOWN_ADDRESS") {
        setIsModalVisible(true);
        // handleProceed(obj);
      } else if (
        verificationStatus === "CORRECTED_ADDRESS" &&
        correctedAddress
      ) {
        const correctedObj = {
          title: correctedAddress.title || values.title,
          address: correctedAddress.streetAddress || values.streetAddress,
          state: correctedAddress.state || values.state,
          city: correctedAddress.city || values.city,
          zipcode: correctedAddress.zipcode || values.zipcode,
          information: correctedAddress.information || values.information,
          country: searchedAddress.country || "",
          latitude: latString || "",
          longitude: lngString || "",
          isVerified: true,
        };

        if (!correctedObj.information) {
          delete correctedObj.information;
        }

        // toast.success("Address corrected Automatically ");
        handleProceed(correctedObj);
      } else {
        if (!obj.information) {
          delete obj.information;
        }

        handleProceed(obj);
      }
    } catch (error) {
      setIsLoadingScreen(false);
      // Handle the failure case here
      // You can proceed with adding the address even if verification fails
      // Remove empty string properties from obj
      Object.keys(obj).forEach((key) => {
        if (obj[key] === "") {
          delete obj[key];
        }
      });

      handleProceed(obj);
    }
  };
  const handleProceed = (obj) => {
    // Ensure searchedAddress and its properties are defined

    const latLng = searchedAddress.latLng;
    const latitude = latLng.lat !== undefined ? latLng.lat.toString() : "";
    const longitude = latLng.lng !== undefined ? latLng.lng.toString() : "";

    const updatedObj = {
      ...obj,
      latitude: latitude !== "" ? latitude : obj.latitude || "",
      longitude: longitude !== "" ? longitude : obj.longitude || "",
      isVerified: obj.isVerified || false,
      country: searchedAddress.country,
    };

    if (!updatedObj.title) {
      delete updatedObj.title;
    }
    if (!updatedObj.information) {
      delete updatedObj.information;
    }
    if (isAddMailingAddressClicked) {
      addAddressMailing.mutate(updatedObj);
    }
    if (isAddressPresent) {
      addAddress.mutate(updatedObj);
    }
    if (!update && !isAddMailingAddressClicked && !isAddressPresent) {
      addAddressFirst.mutate(updatedObj);
    } else {
      updateAddressMutation.mutate({
        id: addressId,
        update: updatedObj,
      });
    }
  };

  const handleAddMailingAddressClick = () => {
    setIsAddMailingAddressClicked(true);
    setIsConfirmAddress(false);
    setTextChange(true);
    setSearchedAddress({
      city: "",
      state: "",
      postalCode: "",
      latLng: {
        lat: null,
        lng: null,
      },
      fullAddress: "",
      country: "",
    });
    form.resetFields();
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);

    const latLng = searchedAddress.latLng;
    const latitude =
      latLng && latLng.lat !== undefined ? latLng.lat.toString() : "";
    const longitude =
      latLng && latLng.lng !== undefined ? latLng.lng.toString() : "";

    if (isEditMode) {
      handleProceed({
        title: form.getFieldValue("title") || "",
        address: form.getFieldValue("address") || "",
        state: form.getFieldValue("state") || "",
        city: form.getFieldValue("city") || "",
        zipcode: form.getFieldValue("zipcode") || "",
        information: form.getFieldValue("information") || "",
        latitude,
        longitude,
        isVerified: true,
      });
    }
  };

  const handleSelect = async (selectedAddress) => {
    try {
      const results = await geocodeByAddress(selectedAddress);
      const latLng = await getLatLng(results[0]);

      const city =
        results[0].address_components.find((component) =>
          component.types.includes("locality")
        )?.long_name || "";
      const state =
        results[0].address_components.find((component) =>
          component.types.includes("administrative_area_level_1")
        )?.long_name || "";
      const postalCode =
        results[0].address_components.find((component) =>
          component.types.includes("postal_code")
        )?.long_name || "";
      const country =
        results[0].address_components.find((component) =>
          component.types.includes("country")
        )?.long_name || "";

      setSearchedAddress({
        city,
        state,
        postalCode,
        latLng,
        fullAddress: selectedAddress,
        country, // Add the country property
      });

      localStorage.setItem("latitude", latLng.lat);
      localStorage.setItem("longitude", latLng.lng);

      form.setFieldsValue({
        title: "",
        address: selectedAddress,
        state,
        city,
        zipcode: postalCode,
        information: "",
        latitude: latLng.lat,
        longitude: latLng.lng,
        country, // Add the country property
      });
      // setNewMap(true);
      window.scrollTo({
        top: document.body.scrollHeight / 3,
        behavior: "smooth", // Optional smooth scrolling behavior
      });
    } catch (error) {}
  };
  const handleMarkerDragEnd = (e) => {
    const newPosition = {
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    };
    setMarkerPosition(newPosition);

    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: newPosition }, (results, status) => {
      if (status === "OK") {
        if (results[0]) {
          const newAddress = results[0].formatted_address;

          const addressComponents = results[0].address_components;
          const newCity =
            addressComponents.find((component) =>
              component.types.includes("locality")
            )?.long_name || "";
          const newState =
            addressComponents.find((component) =>
              component.types.includes("administrative_area_level_1")
            )?.long_name || "";
          const newPostalCode =
            addressComponents.find((component) =>
              component.types.includes("postal_code")
            )?.long_name || "";

          form.setFieldsValue({
            address: newAddress,
            city: newCity,
            state: newState,
            zipcode: newPostalCode,
          });

          setSearchedAddress((prevState) => ({
            ...prevState,
            fullAddress: newAddress,
            city: newCity,
            state: newState,
            postalCode: newPostalCode,
            latLng: newPosition,
          }));
        } else {
        }
      } else {
      }
    });
  };

  useEffect(() => {
    const fetchSubscriptionData = async () => {
      try {
        const subscriptionResponse = await axios.get("/users/subscription");
        const subscriptionData = subscriptionResponse.data;

        if (
          subscriptionResponse.status === 400 &&
          subscriptionData.messageCode === "CUSTOMER_HAS_NO_SUBSCRIPTION"
        ) {
          setHasSubscription(false);
        } else {
          setHasSubscription(true);
        }
      } catch (error) {
        // Handle error and set hasSubscription to false
        //         setHasSubscription(false);
      }
    };

    fetchSubscriptionData();
  }, []);

  return (
    <div className="">
      {isLoadingScreen ? (
        <LoadingScreenTrans />
      ) : (
        <div className="w-full ">
          <div className="col mt-5 mb-4">
            {!hasSubscription && (
              <div className="px-20">
                <StepsAddress />
              </div>
            )}

            <div className="col-md-12 text-center mt-20">
              {!textChange && !hasSubscription && (
                <h2 className="font-bold text-3xl">Add Billing Address</h2>
              )}
              {isAddressPresent ||
                (textChange && (
                  <h2 className="font-bold text-3xl">Add Address</h2>
                ))}

              {hasSubscription && (
                <h2 className="font-bold text-3xl">Add Address</h2>
              )}
            </div>

            <div className="w-10/12 md:w-8/12 lg:w-8/12 h-fit border-2 border-gray-700 rounded-lg mx-auto mt-10">
              <div className="w-full px-5 mt-5">
                {" "}
                <p className="text-md ">
                  <span className="text-xl font-bold ">PLEASE NOTE: </span>
                  If setting up multiple pets that live at different addresses,
                  please complete your sign-up process and pay. Next, go to the{" "}
                  <span className="text-lg text-sky-500">
                    Pet Details section and add or change shipping addresses per
                    pet.
                  </span>
                </p>
                {!isAddressPresent && (
                  <p className="text-xl">
                    Please only add your billing address during sign-up.
                  </p>
                )}
                {isAddressPresent && (
                  <p className="text-xl">
                    Please only add your billing address during sign-up.
                  </p>
                )}
              </div>
              <div className="px-5 mt-5">
                <PlacesAutocomplete
                  value={searchedAddress.fullAddress}
                  onChange={(value) => {
                    setSearchedAddress({
                      ...searchedAddress,
                      fullAddress: value,
                    });
                    setNewMap(true);
                  }}
                  onSelect={handleSelect}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div className="flex flex-col">
                      <label
                        htmlFor="searchAddress"
                        className="font-semibold mb-2"
                      >
                        Search Address
                      </label>
                      <input
                        style={{ height: "3rem" }}
                        className="w-full border-none outline-none rounded-full bg-amber-600/5 px-4 py-2 mr-4 mb-4 md:mr-0 md:mb-0"
                        {...getInputProps({
                          placeholder: "Search for an address...",
                        })}
                      />

                      <div
                        className="autocomplete-dropdown-container"
                        style={{
                          backgroundColor: "rgba(290, 255, 255, 0.9)",
                          padding: "10px",
                        }}
                      >
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion) => {
                          const className = suggestion.active
                            ? "suggestion-item--active"
                            : "suggestion-item";
                          return (
                            <div
                              style={{
                                marginBottom: "20px",
                                cursor: "pointer",
                              }}
                              {...getSuggestionItemProps(suggestion, {
                                className,
                              })}
                            >
                              <span>{suggestion.description}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
              </div>
              {!newMap && (
                <div className="col-md-11 ml-10">
                  <div className="mb-4">
                    <GoogleMap
                      mapContainerStyle={containerStyle}
                      center={center}
                      zoom={8}
                      onLoad={onLoad}
                      onUnmount={onUnmount}
                    >
                      {searchedAddress && (
                        <Marker
                          position={markerPosition || center}
                          title={form.getFieldValue("address")}
                          draggable={true}
                          onDragEnd={handleMarkerDragEnd}
                        />
                      )}
                      <></>
                    </GoogleMap>
                  </div>
                </div>
              )}

              <div className="col pt-10 pl-5 md:pl-10 lg:pl-20">
                <div className="col-md-11">
                  {newMap && (
                    <div className="mb-4">
                      <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={center}
                        zoom={8}
                        onLoad={onLoad}
                        onUnmount={onUnmount}
                      >
                        {searchedAddress && (
                          <Marker
                            position={markerPosition || center}
                            title={form.getFieldValue("address")}
                            draggable={true}
                            onDragEnd={handleMarkerDragEnd}
                          />
                        )}
                        <></>
                      </GoogleMap>
                    </div>
                  )}

                  <Form
                    className="create-form"
                    onFinish={handleSubmit}
                    form={form}
                    initialValues={{
                      title: address?.title,
                      address: address?.address,
                      state: address?.state,
                      city: address?.city,
                      zipcode: address?.zipcode,
                      information: address?.information,
                    }}
                  >
                    {(values, form) => (
                      <div>
                        <div className="col">
                          <div className="w-full">
                            <FormGroup>
                              <label htmlFor="firstName">
                                Title{" "}
                                <span className="text-black  text-xs  font-bold">
                                  {" "}
                                  (Optional)
                                </span>
                              </label>
                              <Field
                                name="title"
                                // rules={[
                                //   {
                                //     validator: (_, value) => {
                                //       if (!value) {
                                //         toast.error("Title cannot be empty");
                                //         return Promise.reject(
                                //           "Please input title"
                                //         );
                                //       }
                                //       return Promise.resolve();
                                //     },
                                //   },
                                // ]}
                              >
                                <Input
                                  className="w-full"
                                  error={form.getFieldError("title")[0]}
                                />
                              </Field>
                            </FormGroup>
                          </div>
                          <div className="col-md-12">
                            <FormGroup>
                              <label htmlFor="address">Address</label>
                              <span className="text-red-600  text-xs italic font-bold">
                                {" "}
                                (*Required )
                              </span>
                              <Field
                                name="address"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input address",
                                  },
                                ]}
                              >
                                <Input
                                  className="w-full"
                                  error={form.getFieldError("address")[0]}
                                />
                              </Field>
                            </FormGroup>
                          </div>

                          <div className="col-md-12">
                            <FormGroup>
                              <label htmlFor="state">State</label>
                              <span className="text-red-600  text-xs italic font-bold">
                                {" "}
                                (*Required )
                              </span>
                              <Field
                                name="state"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input state",
                                  },
                                ]}
                              >
                                <Input
                                  className="w-full"
                                  error={form.getFieldError("state")[0]}
                                />
                              </Field>
                            </FormGroup>
                          </div>

                          <div className="col-md-12">
                            <FormGroup>
                              <label htmlFor="city">City</label>
                              <span className="text-red-600  text-xs italic font-bold">
                                {" "}
                                (*Required )
                              </span>
                              <Field
                                name="city"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input city",
                                  },
                                ]}
                              >
                                <Input
                                  className="w-full"
                                  error={form.getFieldError("city")[0]}
                                />
                              </Field>
                            </FormGroup>
                          </div>

                          <div className="col-md-12">
                            <FormGroup>
                              <label htmlFor="zipcode">Zip Code</label>
                              <span className="text-red-600  text-xs italic font-bold">
                                {" "}
                                (*Required )
                              </span>
                              <Field
                                name="zipcode"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input zipcode",
                                  },
                                ]}
                              >
                                <Input
                                  className="w-full"
                                  error={form.getFieldError("zipcode")[0]}
                                />
                              </Field>
                            </FormGroup>
                          </div>

                          <div className="col-md-12">
                            <FormGroup>
                              <label htmlFor="information">
                                Additional Information{" "}
                                <span className="text-black  text-xs  font-bold">
                                  {" "}
                                  (Optional)
                                </span>
                              </label>
                              <Field name="information">
                                <Input
                                  className="w-full"
                                  error={form.getFieldError("information")[0]}
                                />
                              </Field>
                            </FormGroup>
                          </div>
                        </div>
                      </div>
                    )}
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="w-12/12 lg:w-8/12 h-fit flex mt-5 justify-between items-end mx-auto">
        {/* Your content here */}

        {/* Buttons */}
        <div className="ml-auto">
          <button
            className="bg-[#E68F00] hover:bg-[#ffb031] text-white font-bold py-2 px-4 rounded-md mr-2"
            onClick={() => {
              if (isEditMode) {
                handleProceed(form.getFieldsValue());
              } else {
                form.submit();
              }
            }}
          >
            {update ? "Update Address" : "Add Address"}
          </button>
          <button className="bg-amber-400/10 hover:bg-amber-400/5 text-[#E68F00] font-bold py-2 px-4 rounded-md border-2 border-[#E68F00]">
            <Link to="/addresses">Cancel</Link>
          </button>
        </div>
      </div>

      <AntModal
        visible={isConfirmAddress}
        footer={null}
        onCancel={() => setIsConfirmAddress(false)}
      >
        <p className="w-5/6 mt-3 ">
          This address is set as
          <span className="font-bold text-amber-600"> Billing address</span>,
          for{" "}
          <span className="font-bold text-[#448EBE]"> Shipping address</span>,
          you can choose any of the following options.
        </p>
        <div className="w-full flex flex-col space-y-3 lg:flex-row md:space-y-0 lg:space-y-0 mt-10 md:flex-row md:items-center">
          <button
            key="edit"
            onClick={handleSameAddressClick}
            className="edit-button border-0 px-5 w-full md:w-auto h-auto bg-amber-600 rounded-lg text-white font-semibold p-2  md:mb-0 md:mr-2"
          >
            Billing & Shipping address are the same
          </button>

          <button
            key="continue"
            onClick={handleAddMailingAddressClick}
            className="continue-button border-0 px-5 w-full md:w-auto h-auto bg-[#448EBE] rounded-lg text-white font-semibold p-2"
          >
            Add Shipping Address
          </button>
        </div>
      </AntModal>
      <AntModal
        title="Unknown Address"
        visible={isModalVisible}
        onCancel={handleModalCancel}
        footer={[
          <button
            key="edit"
            className="edit-button border-0 px-5 w-36 h-10 bg-amber-600 rounded-full text-white"
            onClick={() => {
              setIsModalVisible(false);
              setIsEditMode(true);
            }}
          >
            Edit
          </button>,
          <button
            key="continue"
            className="continue-button border-0 px-5 w-36 h-10 bg-[#448EBE] rounded-full ml-2 text-white"
            onClick={() => {
              setIsModalVisible(false);
              handleProceed(form.getFieldsValue());
            }}
          >
            Continue
          </button>,
        ]}
      >
        <p>Your address is not verified. Do you want to edit or proceed?</p>
      </AntModal>
    </div>
  );
}
