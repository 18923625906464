import React, { useState } from "react";
import { Form, Input, Button, message } from "antd";
import { EmailAddressIcon } from "../../Assets";
import { loginApi } from "../../services/api.service";
import { setAccessToken } from "../../services/localstorage";
import { useAuth } from "../../context/authContext";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { toast } from "react-toastify";
import { AiTwotoneMail } from "react-icons/ai";
import { RiLockPasswordLine } from "react-icons/ri";

export default function LoginAccount({ onClose }) {
  const [form] = Form.useForm();
  const ctx = useAuth();
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async (values) => {
    try {
      const res = await loginApi({
        email: values.email.toLowerCase(),
        password: values.password,
        deviceType: "web",
        deviceToken: "abc",
      });

      const { data } = res;
      const token = data["api-token"];
      setAccessToken(token);
      ctx.updateUser(data);
      onClose();

      message.success("Logged in successfully");
      toast.success("Logged in successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      window.location.reload();
    } catch (err) {
      toast.error("Invalid Email or Password", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const showForgetModal = () => {
    ctx.showModal("forget");
  };

  return (
    <>
      <h4 className="mb-3">Continue with email</h4>
      <Form form={form} onFinish={handleLogin}>
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: "Please input your email",
            },
            {
              type: "email",
              message: "Please enter a valid email address",
            },
          ]}
        >
          <Input
            placeholder="Email"
            prefix={<AiTwotoneMail className="text-3xl text-amber-600" />}
            type="email"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your password",
            },
            // {
            //   min: 8,
            //   max: 35,
            //   message: "Password must be between 8 and 30 characters",
            // },
          ]}
        >
          <Input.Password
            placeholder="Password"
            prefix={<RiLockPasswordLine className="text-3xl text-amber-600" />}
            iconRender={(visible) => (visible ? <FaEye /> : <FaEyeSlash />)}
          />
        </Form.Item>
        <div className="text-end my-2">
          <button
            href="#"
            className="btn btn-text px-0  py-0 d-inline-flex"
            onClick={showForgetModal}
          >
            Forgot Password?
          </button>
        </div>

        <div className="flex flex-col ">
          <button
            htmlType="submit"
            className="bg-amber-500 mb-3 py-3 rounded-lg hover:scale-95 transition-transform duration-300 ease-in-out"
          >
            Log In
          </button>

          <div className="mt-4 text-center">
            Don’t have an account?{" "}
            <span
              type="button"
              className=" px-0 fw-bold text-decoration-none text-lime-500"
              onClick={() => ctx.showModal("signup")}
            >
              Sign Up
            </span>
          </div>
        </div>
      </Form>
    </>
  );
}
