import React from "react";
import "./MealPlanner.scss";
import { FootIcon } from "../../Assets";
import phone from "../../../utils/phone.png";
import phoneGroup from "../../../utils/phone-group.png";
export default function MealPlanner() {
  return (
    <div className="p-10 lg:p-20 md:p-10 bg-white">
      <div className="flex bg-orange-400/60 h-[30rem] w-full rounded-3xl ">
        <div className="flex justify-start items-start hidden md:block lg:block">
          <img src={phoneGroup} className="h-full w-full" />
        </div>
        <div className="flex justify-start items-start block md:hidden  lg:hidden">
          <img src={phone} className="h-full w-full" />
        </div>

        <div className="hidden lg:block md:hidden  lg:pr-8 lg:pt-4 md:pr-8 md:pt-4  z-50 mx-auto my-auto space-y-6">
          <div className="lg:max-w-lg ml-0 md:ml-10">
            <p className="mt-10  tracking-tight text-black text-5xl ">
              Download mobile app
            </p>
            <p className="text-black mx-auto mt-4 text-xl">
              Harness the full potential of the Carson & Bear platform,
              on-the-go, with our free mobile apps for Apple & Android
            </p>
            <div className="flex mt-4">
              <a
                href="https://play.google.com/store/apps/details?id=com.tailscore"
                target=" _blank"
                rel="noreferrer"
              >
                <img src="/images/android-download.png" className="img1" />
              </a>
              <a
                href="https://apps.apple.com/us/app/tailscore/id6450352918"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="/images/ios-download.png"
                  alt="Ios Download"
                  className="img1"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="lg:hidden mt-10">
        <p className="mt-10  tracking-tight text-black text-6xl font-manrope">
          Download mobile app
        </p>
        <p className="text-black mx-auto mt-4">
          Harness the full potential of the Carson & Bear platform, on-the-go,
          with our free mobile apps for Apple & Android
        </p>
        <div className="flex mt-4">
          <a
            href="https://play.google.com/store/apps/details?id=com.tailscore"
            target=" _blank"
            rel="noreferrer"
          >
            <img src="/images/android-download.png" className="img1" />
          </a>
          <a
            href="https://apps.apple.com/us/app/tailscore/id6450352918"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="/images/ios-download.png"
              alt="Ios Download"
              className="img1"
            />
          </a>
        </div>
      </div>
    </div>
  );
}
