import React from "react";
import video from "../../utils/video.mp4";
import { LogoBig } from "../Assets";

const IntroPage = () => {
  return (
    <div className="relative h-screen">
      <video
        className="absolute top-0 left-0 w-full h-full object-cover"
        autoPlay
        loop
        muted
      >
        <source src={video} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-b from-black to-transparent opacity-70"></div>
      <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
        <LogoBig />
      </div>
    </div>
  );
};

export default IntroPage;
