import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import LoaderNewOne from "./LoaderNewOne";
import { FaCheckCircle } from "react-icons/fa";

const EmailVerification = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("Verifying your email...");

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        const response = await axios.post("/users/verify-email-via-link", {
          emailVerificationToken: token,
        });
        setMessage("Your email has been verified successfully!");
      } catch (error) {
        setMessage("Email verification failed. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    verifyEmail();
  }, [token]);

  const handleRedirect = () => {
    navigate("/");
  };

  return (
    <div className="h-screen w-full flex items-center justify-center">
      {loading ? (
        <div className="flex flex-col gap-3 items-center">
          <LoaderNewOne />
          {message}
        </div>
      ) : (
        <div className="flex flex-col gap-3 items-center">
          <FaCheckCircle className="text-6xl text-amber-500" />
          {message}
          <button
            className="mt-4 px-4 py-2 bg-amber-500 text-white rounded-md"
            onClick={handleRedirect}
          >
            Go to Home
          </button>
        </div>
      )}
    </div>
  );
};

export default EmailVerification;
