import React, { useState } from "react";
import Modal from "../../core/Modal/Modal";
import { updateAccount } from "../../services/api.service";
import { toast } from "react-toastify";
import { MODAL_TYPES, useModals } from "../../context/modalContext";
import { useAuth } from "../../context/authContext";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { PiEyeClosedDuotone } from "react-icons/pi";
import { clearAccessToken } from "../../services/localstorage";
import { LockIcon } from "../../Assets";
import "./ChangePasswordModel.scss";
import { useNavigate } from "react-router";

export default function ChangePasswordModal({ data }) {
  const ctx = useAuth();
  const navigate = useNavigate();
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const modalCtx = useModals();
  const { updateUser } = useAuth();

  const handleSubmit = () => {
    setErrors({}); // Reset errors

    // Validate form fields
    if (!currentPassword) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        currentPassword: "Current Password is required",
      }));
      return;
    }

    if (!newPassword) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        newPassword: "New Password is required",
      }));
      return;
    }

    if (!confirmPassword) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        confirmPassword: "Confirm Password is required",
      }));
      return;
    }

    if (newPassword !== confirmPassword) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        confirmPassword: "Passwords do not match",
      }));
      return;
    }

    // If all validations pass, proceed to update password
    updateAccount({
      oldPassword: currentPassword,
      password: newPassword,
    })
      .then((res) => {
        const user = res.data;
        updateUser(user);
        closeModal();
        toast.success("Password updated successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        ctx.updateUser(null);
        localStorage.clear();
        clearAccessToken();
        navigate("/?isOpenLogin=true");
      })
      .catch((error) => {
        closeModal();
        toast.error("Failed to update password", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const closeModal = () => {
    modalCtx.hideModal(MODAL_TYPES.CHANGE_PASSWORD);
  };

  return (
    <Modal open={true} title={"Change Password"} onClose={closeModal}>
      <div className="p-4">
        <div className="row pb-5">
          <div className="col-md-12 text-center">
            <div className="icon-container-password">
              <div className="password-icon">
                <LockIcon />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="mb-4 relative">
              <label htmlFor="current_password" className="block">
                Enter Current Password
              </label>
              <input
                id="current_password"
                type={showCurrentPassword ? "text" : "password"}
                className="form-control pr-12"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
              />
              <button
                className="absolute inset-y-0 right-0 flex items-center px-3 text-gray-500 focus:outline-none"
                onClick={() => setShowCurrentPassword(!showCurrentPassword)}
              >
                {showCurrentPassword ? (
                  <FaRegEye className="text-2xl mt-4" />
                ) : (
                  <PiEyeClosedDuotone className="text-2xl mt-4" />
                )}
              </button>
              {errors.currentPassword && (
                <div className="text-red-500">{errors.currentPassword}</div>
              )}
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-4 relative">
              <label htmlFor="new_password" className="block">
                Enter New Password
              </label>
              <input
                id="new_password"
                type={showNewPassword ? "text" : "password"}
                className="form-control pr-12"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <button
                className="absolute inset-y-0 right-0 flex items-center px-3 text-gray-500 focus:outline-none"
                onClick={() => setShowNewPassword(!showNewPassword)}
              >
                {showNewPassword ? (
                  <FaRegEye className="text-2xl mt-4" />
                ) : (
                  <PiEyeClosedDuotone className="text-2xl mt-4" />
                )}
              </button>
              {errors.newPassword && (
                <div className="text-red-500">{errors.newPassword}</div>
              )}
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-4 relative">
              <label htmlFor="confirm_password" className="block">
                Confirm New Password
              </label>
              <input
                id="confirm_password"
                type={showConfirmPassword ? "text" : "password"}
                className="form-control pr-12"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <button
                className="absolute inset-y-0 right-0 flex items-center px-3 text-gray-500 focus:outline-none"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              >
                {showConfirmPassword ? (
                  <FaRegEye className="text-2xl mt-4" />
                ) : (
                  <PiEyeClosedDuotone className="text-2xl mt-4" />
                )}
              </button>
              {errors.confirmPassword && (
                <div className="text-red-500">{errors.confirmPassword}</div>
              )}
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-end">
          <button
            className="btn btn-theme-light mx-4"
            type="button"
            onClick={closeModal}
          >
            Cancel
          </button>
          <button
            className="btn btn-theme"
            type="submit"
            onClick={handleSubmit}
          >
            Update
          </button>
        </div>
      </div>
    </Modal>
  );
}
