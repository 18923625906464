import React, { useState, useEffect, useRef } from "react";
import { RadioGroup } from "@headlessui/react";
import { Modal, Button } from "antd";
import axios from "axios";
import { tiersOne, tiersTwo, tiersThree, plansAllFeature } from "./plansNew";
import { FaCheck } from "react-icons/fa";
import { MdOutlineCancel, MdCancel } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import bgImg from "../../utils/Group (1).png";
import StepsAll from "../../components/StepsAll";
import smallDog from "../../utils/small.png";
import mediumDog from "../../utils/medium.png";
import largeDog from "../../utils/large.png";
import { LoadingOutlined } from "@ant-design/icons";
import { Flex, Spin } from "antd";
import infoicon from "../../utils/info-icon.svg";

const frequencies = [
  { value: "monthly", label: "Monthly" },
  { value: "annually", label: "Annually" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function PricingNew() {
  const [frequency, setFrequency] = useState(frequencies[0]);
  const [plan, setPlan] = useState("small");
  const [modalPlan, setmodalPlan] = useState("small");

  const [group, setGroup] = useState("pack");
  const [petCount, setPetCount] = useState(0);
  const [selectedPlanIndex, setSelectedPlanIndex] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hasSubscription, setHasSubscription] = useState(true);
  const [priceData, setPriceData] = useState(null);
  const navigate = useNavigate();
  const [visibleInfoBubbleIndex, setVisibleInfoBubbleIndex] = useState(null);
  const bubbleRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);

    return () => {
      window.scrollTo(0, 0);
    };
  }, []);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    const fetchSubscriptionData = async () => {
      try {
        const subscriptionResponse = await axios.get("/users/subscription");
        const subscriptionData = subscriptionResponse.data;

        if (
          subscriptionResponse.status === 400 &&
          subscriptionData.messageCode === "CUSTOMER_HAS_NO_SUBSCRIPTION"
        ) {
          setHasSubscription(false);
        } else {
          setHasSubscription(true);
        }
      } catch (error) {
        // Handle error and set hasSubscription to false
        setHasSubscription(false);
      }
    };

    fetchSubscriptionData();
  }, []);
  useEffect(() => {
    axios
      .get("/pet-profiles/user-pets")
      .then((response) => {
        const numberOfPets = response.data.data.length;
        setPetCount(numberOfPets);

        // Determine API endpoint based on subscription type
        const priceApiEndpoint =
          localStorage.getItem("subscriptionType") === "v1"
            ? "/users/subscriptions/prices"
            : "/users/subscriptions/prices-v2";

        return axios.get(priceApiEndpoint);
      })
      .then((response) => {
        if (response) {
          if (localStorage.getItem("subscriptionType") === "v1") {
            const pricingData = response.data.data.paymentPricing;

            // Determine which pricing tier to use based on pet count
            let priceData;
            if (petCount === 0) {
              priceData = {
                small: pricingData.small.default.price,
                medium: pricingData.medium.default.price,
                large: pricingData.large.default.price,
              };
            } else if (petCount === 1) {
              priceData = {
                small: pricingData.small.dollar5Discount.price,
                medium: pricingData.medium.dollar5Discount.price,
                large: pricingData.large.dollar5Discount.price,
              };
            } else {
              priceData = {
                small: pricingData.small.dollar10Discount.price,
                medium: pricingData.medium.dollar10Discount.price,
                large: pricingData.large.dollar10Discount.price,
              };
            }
            setPriceData(priceData);
          } else {
            // Handle v2 pricing logic
            const plans = response.data.data.plans;
            const nextPlans = response.data.data.nextPlans;

            if (Object.keys(nextPlans).length > 0) {
              setPriceData(nextPlans);
            } else {
              const fallbackPlans = {
                small: plans.small.primary.price,
                medium: plans.medium.primary.price,
                large: plans.large.primary.price,
              };
              setPriceData(fallbackPlans);
            }
          }
        }
      })
      .catch((error) => {});
  }, [petCount]);

  useEffect(() => {
    axios
      .get("/users/account")
      .then((response) => {
        const userData = response.data.data;
        if (userData.invitedByVet) {
          localStorage.setItem("invitedByVet", userData.invitedByVet);
        }
      })
      .catch((error) => {});
  }, []);

  const handlePackSelect = (pack, index) => {
    setPlan(pack);
    setmodalPlan(pack);
    setSelectedPlanIndex(index);
    showModal();
  };
  let capitalPlan = plan.charAt(0).toUpperCase() + plan.slice(1);
  const getSelectedPlans = () => {
    if (petCount === 0) {
      return tiersOne;
    } else if (petCount === 1) {
      return tiersTwo;
    } else {
      return tiersThree;
    }
  };

  const continueHandler = () => {
    const selectedPlans = getSelectedPlans();
    const newPath = "/pets/add";
    const queryParam = `?plan=${plan}`;

    // Save the path to local storage
    localStorage.setItem("planpath", newPath + queryParam);

    // Navigate to the new path
    navigate({
      pathname: newPath,
      search: queryParam,
      state: { adjustedPlans: selectedPlans },
    });
  };

  const selectedPlans = getSelectedPlans();

  const toggleInfoBubble = (index) => {
    setVisibleInfoBubbleIndex(visibleInfoBubbleIndex === index ? null : index);
  };

  return (
    <>
      <Modal
        title={`Continue with ${plan} plan `}
        visible={isModalOpen}
        onCancel={handleCancel}
        footer={[
          <div className="flex w-full">
            <Button
              key="back"
              onClick={handleCancel}
              className="rounded-md bg-[#448EBE] text-white hover:bg-[#73a5c6] w-1/2 h-12 "
            >
              Change Plan
            </Button>
            ,
            <Button
              key="submit"
              onClick={continueHandler}
              className="rounded-md bg-[#D97706] text-white hover:bg-[#ff9a26] w-1/2 h-12"
            >
              Continue
            </Button>
            ,
          </div>,
        ]}
        className="rounded-lg"
      >
        <p className="text-lg ">
          Do you want to continue with the {plan} plan?
        </p>

        <div className="">
          <div className="mt-3 flow-root sm:mt-3">
            <ul role="list">
              {plansAllFeature.map((plan, index) => (
                <li key={index} className="py-2">
                  <h3 className="text-lg font-semibold text-[#ff9a26]">
                    {" "}
                    {capitalPlan} plan{" "}
                  </h3>
                  <ul role="list" className="ml-4">
                    {plan.mainFeatures.map((mainFeature, featureIndex) => (
                      <li key={featureIndex} className="flex gap-x-3 py-1">
                        <FaCheck
                          className="h-6 w-5 flex-none text-lime-500"
                          aria-hidden="true"
                        />
                        <span className="hover:text-[#448EBE]">
                          {mainFeature}
                        </span>
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Modal>
      {!hasSubscription && (
        <div className="px-20 pt-14">
          <StepsAll />
        </div>
      )}

      <div className="isolate overflow-hidden bg-white relative">
        <div className="flow-root bg-white pb-16 pt-10 sm:pt-16 lg:pb-0 z-50">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="relative z-10">
              <h2 className="mx-auto max-w-4xl text-center text-5xl font-bold tracking-tight text-black">
                Choose your dog's plan
              </h2>
              {localStorage.getItem("subscriptionType") === "v1" ? (
                <p className="mx-auto mt-4 max-w-2xl text-center text-lg leading-8 text-black/60">
                  It is simple to save and sign up multiple pets. We will
                  automatically add a $5/month{" "}
                  <span className="font-bold">discount</span> for a second pet
                  and $10/month for three or more pets. For multiple pets,
                  please sign your pups up one at a time.
                </p>
              ) : (
                <p className="mx-auto mt-4 max-w-2xl text-center text-lg leading-8 text-black/60">
                  The updated subscription model offers two categories tailored
                  to dog sizes. The 'Primary Dog' category is for a single dog
                  per owner, with pricing set at $65 for Small, $100 for Medium,
                  and $135 for Large dogs. The 'Add-on Dogs' category
                  accommodates multiple pets, with prices at $24 for Small, $41
                  for Medium, and $58 for Large dogs. If only one pet is
                  enrolled, it will automatically be placed in the Primary
                  category.
                </p>
              )}
            </div>
            {hasSubscription ? (
              <div className="relative mx-auto mt-10 grid max-w-md grid-cols-1 gap-y-8 lg:mx-0 lg:-mb-14 lg:max-w-none lg:grid-cols-3">
                <div
                  className="hidden lg:absolute lg:inset-x-px lg:bottom-0 lg:top-4 lg:block lg:rounded-t-2xl"
                  aria-hidden="true"
                />

                {selectedPlans.map((tier, index) => (
                  <div
                    key={tier.label}
                    className={classNames(
                      "group bg-gray-100  relative rounded-2xl shadow-md space-x-10  lg:mr-4 md:mr-4 mr-0 hover:bg-[#448EBE] hover:text-white transition-transform hover:scale-95 duration-500 ease-in-out z-50",
                      // Apply green borders to selected plan
                      index === selectedPlanIndex && "border-3 border-green-500"
                    )}
                  >
                    <div className="p-8 lg:pt-12 xl:p-10 xl:pt-14">
                      <h3
                        id={tier.id}
                        className="text-2xl font-semibold leading-6"
                      >
                        {tier.label.charAt(0).toUpperCase() +
                          tier.label.slice(1)}{" "}
                        <span className="text-sm">{tier.weight}</span>
                      </h3>
                      <div className="flex flex-col gap-6 sm:flex-col sm:items-end sm:justify-between md:flex-col lg:flex-col lg:items-stretch">
                        <div className="mt-2 flex items-center gap-x-4">
                          <p className="text-4xl font-bold tracking-tight flex">
                            <span className="mr-2">$</span>
                            {(priceData &&
                              priceData[tier.label.toLowerCase()] / 100) || (
                              <Spin
                                indicator={<LoadingOutlined spin />}
                                size="large"
                              />
                            )}
                          </p>
                          <div className="text-sm leading-5">
                            <p>{`Billed ${frequency.value}`}</p>
                          </div>
                          {tier.id === "tier-starter" && (
                            <img
                              src={smallDog}
                              alt="Small Dog"
                              className="mt-4 w-1/2 lg:h-44 md:h-44 h-52 max-w-sm mx-auto"
                            />
                          )}
                          {tier.id === "tier-scale" && (
                            <img
                              src={mediumDog}
                              alt="Medium Dog"
                              className="mt-4 w-1/2 lg:h-44 md:h-44 h-52 max-w-sm mx-auto"
                            />
                          )}
                          {tier.id === "tier-growth" && (
                            <img
                              src={largeDog}
                              alt="Large Dog"
                              className="mt-4 w-1/2 lg:h-44 md:h-44 h-52 max-w-sm mx-auto"
                            />
                          )}
                        </div>

                        <div className="flex-grow flex justify-center items-end">
                          <button
                            href={tier.href}
                            aria-describedby={tier.id}
                            onClick={() => handlePackSelect(tier.label, index)}
                            className="rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 bg-amber-500  hover:bg-gray-50 hover:text-black w-full"
                          >
                            <span>Purchase</span>
                          </button>
                        </div>
                      </div>
                      <div className="mt-8 flow-root sm:mt-10">
                        <ul role="list">
                          {tier.mainFeatures.map((mainFeature) => (
                            <li key={mainFeature} className="flex gap-x-3 py-2">
                              <FaCheck
                                className="h-6 w-5 flex-none"
                                aria-hidden="true"
                              />
                              <span className="hover:text-white">
                                {mainFeature}
                              </span>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <img
                        src={infoicon}
                        alt="Info"
                        className="inline h-8 w-8 absolute top-52 cursor-pointer"
                        onClick={() => toggleInfoBubble(index)}
                      />
                      {visibleInfoBubbleIndex === index && (
                        <div
                          ref={bubbleRef}
                          className="absolute bg-white border rounded p-2 top-60 left-12 w-10/12 mx-auto"
                        >
                          <MdCancel
                            className="ml-auto text-black cursor-pointer text-lg"
                            onClick={() => toggleInfoBubble(index)}
                          />
                          <p className="text-black">
                            Our subscriptions are customized to fit your dog’s
                            needs perfectly! For your first pup (the First Dog
                            plan), pricing tops out at $65 for Small, $100 for
                            Medium, and $135 for Large, based on your dog’s
                            weight. Got a full pack? No worries! Our Pack Pals
                            plan lets each additional dog join the party at a
                            major discount—just $24 for Small, $41 for Medium,
                            and $58 for Large dogs.
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="relative mx-auto mt-10 grid max-w-md grid-cols-1 gap-y-8 lg:mx-0 lg:-mb-14 lg:max-w-none lg:grid-cols-3">
                <div
                  className="hidden lg:absolute lg:inset-x-px lg:bottom-0 lg:top-4 lg:block lg:rounded-t-2xl"
                  aria-hidden="true"
                />

                {selectedPlans.map((tier, index) => (
                  <div
                    key={tier.label}
                    className={classNames(
                      "group bg-gray-100  relative rounded-2xl shadow-md space-x-10  lg:mr-4 md:mr-4 mr-0 hover:bg-[#448EBE] hover:text-white transition-transform hover:scale-95 duration-500 ease-in-out z-50",
                      // Apply green borders to selected plan
                      index === selectedPlanIndex && "border-3 border-green-500"
                    )}
                  >
                    <div className="p-8 lg:pt-12 xl:p-10 xl:pt-14">
                      <h3
                        id={tier.id}
                        className="text-2xl font-semibold leading-6"
                      >
                        {tier.label.charAt(0).toUpperCase() +
                          tier.label.slice(1)}{" "}
                        <span className="text-sm">{tier.weight}</span>
                      </h3>
                      <div className="flex flex-col gap-6 sm:flex-col sm:items-end sm:justify-between md:flex-col lg:flex-col lg:items-stretch">
                        <div className="mt-2 flex items-center gap-x-4">
                          <p className="text-4xl font-bold tracking-tight flex">
                            <span className="mr-2">$</span>
                            {(priceData &&
                              priceData[tier.label.toLowerCase()] / 100) || (
                              <Spin
                                indicator={<LoadingOutlined spin />}
                                size="large"
                              />
                            )}
                          </p>
                          <div className="text-sm leading-5">
                            <p>{`Billed ${frequency.value}`}</p>
                          </div>
                          {tier.id === "tier-starter" && (
                            <img
                              src={smallDog}
                              alt="Small Dog"
                              className="mt-4 w-1/2 lg:h-44 md:h-44 h-52 max-w-sm mx-auto"
                            />
                          )}
                          {tier.id === "tier-scale" && (
                            <img
                              src={mediumDog}
                              alt="Medium Dog"
                              className="mt-4 w-1/2 lg:h-44 md:h-44 h-52 max-w-sm mx-auto"
                            />
                          )}
                          {tier.id === "tier-growth" && (
                            <img
                              src={largeDog}
                              alt="Large Dog"
                              className="mt-4 w-1/2 lg:h-44 md:h-44 h-52 max-w-sm mx-auto"
                            />
                          )}
                        </div>

                        <div className="flex-grow flex justify-center items-end">
                          <button
                            href={tier.href}
                            aria-describedby={tier.id}
                            onClick={() => handlePackSelect(tier.label, index)}
                            className="rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 bg-amber-500  hover:bg-gray-50 hover:text-black w-full"
                          >
                            <span>Purchase</span>
                          </button>
                        </div>
                      </div>
                      <div className="mt-8 flow-root sm:mt-10">
                        <ul role="list">
                          {tier.mainFeatures.map((mainFeature) => (
                            <li key={mainFeature} className="flex gap-x-3 py-2">
                              <FaCheck
                                className="h-6 w-5 flex-none"
                                aria-hidden="true"
                              />
                              <span className="hover:text-white">
                                {mainFeature}
                              </span>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <img
                        src={infoicon}
                        alt="Info"
                        className="inline h-8 w-8 absolute top-52 cursor-pointer"
                        onClick={() => toggleInfoBubble(index)}
                      />
                      {visibleInfoBubbleIndex === index && (
                        <div
                          ref={bubbleRef}
                          className="absolute bg-white border rounded p-2 top-60 left-12 w-10/12 mx-auto"
                        >
                          <MdCancel
                            className="ml-auto text-black cursor-pointer text-lg"
                            onClick={() => toggleInfoBubble(index)}
                          />
                          <p className="text-black">
                            Our subscriptions are customized to fit your dog’s
                            needs perfectly! For your first pup (the First Dog
                            plan), pricing tops out at $65 for Small, $100 for
                            Medium, and $135 for Large, based on your dog’s
                            weight. Got a full pack? No worries! Our Pack Pals
                            plan lets each additional dog join the party at a
                            major discount—just $24 for Small, $41 for Medium,
                            and $58 for Large dogs.
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="p-40 flex ">
          <img src={bgImg} className="absolute left-0 bottom-0 z-10" />
          {/* <button
            type="submit"
            className="bg-[#D97706] py-3 px-5 rounded-lg text-white hover:scale-95 w-auto cursor-pointer z-50 border-b-4 border-[#9c5606] mx-auto"
            onClick={continueHandler}
          >
            Continue
          </button> */}
        </div>
      </div>
    </>
  );
}
