import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import Lottie from "react-lottie";
import sadDogAnimation from "../../utils/saddog.json";
import { FaLongArrowAltRight } from "react-icons/fa";
const DontHaveSubscription = () => {
  const navigate = useNavigate();

  const handlePetsPage = () => {
    navigate("/");
    window.location.reload();
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "visible";
    };
  }, []);

  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: sadDogAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className="relative taxFailContainer grid grid-cols-1  justify-center items-center min-h-screen">
      {/* Render React Lottie component */}

      <div className="text-center mt-32">
        <p className="text-base font-semibold text-amber-600">Oops..</p>
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
          Payment card not found
        </h1>
        <p className="mt-6 text-base leading-7 text-gray-600">
          You don't have any active Subscription.
        </p>
        <div className="mt-10 flex items-center justify-center gap-x-6 ">
          <span
            onClick={handlePetsPage}
            className="flex items-center justify-center gap-3  cursor-pointer rounded-md bg-amber-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-amber-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-amber-600"
          >
            Home
            <FaLongArrowAltRight className="text-white " />
          </span>
        </div>
      </div>
      <div className=" absolute top-0 w-full mx-auto">
        <Lottie options={lottieOptions} height={400} width={400} />
      </div>
    </div>
  );
};

export default DontHaveSubscription;
