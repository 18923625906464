import React from "react";
import { MODAL_TYPES, useModals } from "../../context/modalContext";
import Modal from "../../core/Modal/Modal";
import { FormGroup, Input } from "../../ui/FormElements/FormElements";
import Form, { Field, List } from "rc-field-form";
import {
  DeleteIcon,
  EditIcon,
  EmailAddressIcon,
  PasswordIcon,
  MyAddressEditIcon,
  AddIcon,
  LockIcon,
} from "../../Assets";
import "./AddAddressModal.scss";
import SingleAddress from "../../ui/FormElements/SingleAddress/SingleAddress";

export default function AddAddressModal() {
  const modalCtx = useModals();

  const closeModal = () => {
    modalCtx.hideModal(MODAL_TYPES.MY_ADDRESSES);
  };

  return (
    <Modal open={true} title={"My Addresses"} onClose={closeModal}>
      <div className="p-4">
        <Form className="auth-form">
          <div>
            <div>
              <SingleAddress
                title={"Home"}
                address={"21-201 Vicent Smith Quarters, Algiers 102498"}
              />
            </div>
            <div className="d-flex justify-content-end mt-5">
              <a href="/">
                <AddIcon />
                <span className="mx-3">Add New Address</span>
              </a>
            </div>
            <div className="d-flex justify-content-end mt-5">
              <button className="btn btn-theme-light mx-4">Cancel</button>
              <button className="btn btn-theme">Continue</button>
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
}
