import { getAccountApi, getSubscription } from "../services/api.service";
import {
  getAccessToken,
  getUserFromLocalstorage,
  saveUserToLocalstorage,
} from "../services/localstorage";
import { createContext, useState, useContext, useEffect } from "react";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(undefined);
  const [subscription, setSubscription] = useState(undefined);
  const [userLoaded, setUserLoaded] = useState(false);
  const [modals, setModals] = useState({
    login: false,
    signup: false,
    forget: false,
    reset: false,
  });

  useEffect(() => {
    const token = getAccessToken();
    if (token) {
      const user = getUserFromLocalstorage();
      setUser(user);
      getAccountApi().then((response) => {
        if (response.error) {
          setUser(null);
        }
        updateUser(response.data);

        getSubscription()
          .then((response) => {
            setSubscription(response.data);
          })
          .catch((err) => {});
      });
    } else {
      setUser(null);
    }
    setUserLoaded(true);
  }, []);

  const updateUser = (user) => {
    setUser(user);
    saveUserToLocalstorage(user);
  };

  const showModal = (modalType) => {
    setModals({
      login: false,
      signup: false,
      forget: false,
      reset: false,
      [modalType]: true,
    });
  };

  const hideModal = (modalType) => {
    setModals({
      ...modals,
      [modalType]: false,
    });
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        updateUser,
        userLoaded,
        modals,
        subscription,
        showModal,
        hideModal,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
