import React from "react";

const LoaderNewOne = () => {
  return (
    <div className="flex-col gap-4 w-full flex items-center justify-center">
      <div className="w-20 h-20 border-8 text-white text-4xl animate-spin border-gray-300 flex items-center justify-center border-t-white rounded-full"></div>
    </div>
  );
};

export default LoaderNewOne;
