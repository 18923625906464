import React, { useEffect, useState } from "react";
import Page from "../../components/core/Page/Page";
import { MODAL_TYPES, useModals } from "../../components/context/modalContext";
import { useSearchParams } from "react-router-dom";
import { confirmSubscription } from "../../components/services/api.service";
import { toast } from "react-toastify";
import Lottie from "react-lottie";
import dogLottie from "../../utils/loading.json";
import successLottie from "../../utils/success.json";

export default function CheckoutSuccessTwo() {
  const [searchParams] = useSearchParams();
  const { showModal } = useModals();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const paymentIntent = searchParams.get("payment_intent");
  const paymentIntentSecret = searchParams.get("client_secret");

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: dogLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const defaultOptionsTwo = {
    loop: true,
    autoplay: true,
    animationData: successLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    if (!paymentIntent || !loading || !showModal) {
      return;
    }

    const coupon = localStorage.getItem("finalPromocode");

    const subscriptionPayload = {
      setupIntentId: paymentIntent,
    };

    if (coupon) {
      subscriptionPayload.coupon = coupon;
    }

    const confirmSubscriptionRequest = async () => {
      try {
        const res = await confirmSubscription(subscriptionPayload);

        localStorage.removeItem("finalPromocode");
        localStorage.removeItem("planpath");
        showModal({
          modalType: MODAL_TYPES.SUBSCRIPTION_CONFIRMED,
          modalData: {},
        });
        setLoading(false);
      } catch (err) {
        setError(err.message || "Something went wrong");
        setLoading(false);
        setTimeout(() => {
          window.location.href = "/select-card";
        }, 2000);
      }
    };

    confirmSubscriptionRequest();
  }, [paymentIntent, showModal, loading]);

  if (error) {
    return (
      <Page>
        <div className="flex h-full items-center justify-center">
          <div className="text-center mt-20">
            <Lottie options={defaultOptionsTwo} height={250} width={250} />
            <h3 className="mt-10 text-left">Payment card added </h3>
          </div>
        </div>
      </Page>
    );
  }

  return (
    <Page>
      <div className="flex h-full items-center justify-center">
        <div className="text-center mt-40">
          <Lottie options={defaultOptions} height={250} width={250} />
          <h3 className="mt-10 text-left">Creating your subscription...</h3>
        </div>
      </div>
    </Page>
  );
}
